import axios from "axios";
import {API} from "../../../config/api";

export default class ReportShipmentStatusVM {
    buName: string = "";

    constructor(buName: string = "Lotus") {
        this.buName = buName
    }

    async loadShipmentStatus(start: string, end: string, isTms: boolean) {
        try {
            let request = {
                startDate: start, // 2024-12-25
                endDate: end, // 2024-12-26
                buName: this.buName, // ตอนนี้มีแค่ Lotus ที่เป็น Loginext
                isRequestTmsOnly: isTms ? 1 : 0 // 1 = ดูแค่ที่ยิง TMS, 0 = เอาทั้งหมด
            }

            return await axios.post(
                new API().getCusAPI() + "api/report/download-shipment-status",
                request,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    responseType: "arraybuffer",
                }
            );
        } catch (e) {
            return e;
        }
    }
}