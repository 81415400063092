export class Formatter {
    static validateNumber(value) {
        return typeof value === "number"
    }
}

export function formatNumber(num) {
    return num % 1 === 0
        ? new Intl.NumberFormat('en-US').format(num)
        : new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(num);
}

export function getCharacterASCIICode(i) {
    return String.fromCharCode(97 + i);
}