import React, {Component, useState, useContext, useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {
    DataGrid,
    GridToolbar,
    useGridApiContext,
    GridCellEditStopReasons,
    GridEditInputCell,
    GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport
} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckIcon from '@mui/icons-material/Check';

import {darken, lighten, styled} from '@mui/material/styles';
import {AuthContext} from '../App.js';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ApiOrderManagement from "../services/api/ApiOrderManagement.js";
import FileDownload from "js-file-download";
import Loading from "./Loading";
import {AllOverview} from "../sections/overview/all-overview";
import {useSearchParams} from "react-router-dom";
import ApiLog from "../services/api/ApiLog";
import dayjs, {Dayjs} from 'dayjs';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormControl from '@mui/material/FormControl';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {API} from "../config/api";
import {base64ToString, stringToBase64} from "../util/Encode";
import Link from '@mui/material/Link';
import CustomNoRowsOverlay from "../components/EmptyRowsComponent";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import SplitTruckDialog from "../dialog/SplitTruckCustomDialog";
import MassAllocateDialog from "../dialog/MassAllocateDialog";
import ErrorMessageTMSDialog from "../dialog/ErrorMessageTMSDialog";
import Badge from "@mui/material/Badge";
import ManageColumnsDialog from "../dialog/ManageColumnsDialog";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";


const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .red': {
        backgroundColor: '#f6d3d3',
        '&:hover': {
            backgroundColor: '#f6c0c0',
        },
        '&.Mui-selected': {
            backgroundColor: '#EDF4FB',
            '&:hover': {
                backgroundColor: '#e0e9f5',
            },
        },
    },
    '& .yellow': {
        backgroundColor: '#f3efcc',
        '&:hover': {
            backgroundColor: '#f5f0b7',
        },
        '&.Mui-selected': {
            backgroundColor: '#EDF4FB',
            '&:hover': {
                backgroundColor: '#e0e9f5',
            },
        },
    },
    '& .grey': {
        backgroundColor: '#efefef',
        '&:hover': {
            backgroundColor: '#efefef',
        },
        '&.Mui-selected': {
            backgroundColor: '#efefef',
            '&:hover': {
                backgroundColor: '#efefef',
            },
        },
    },
    /*    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
        },
    },*/
}));

export default function ListOrder() {

    const {
        open,
        masterBu,
        setIsLoading,
        isProfiles,
        setIsProfiles,
        isProgress,
        setIsProgress,
        profiles,
        testMode,
    } = useContext(AuthContext);
    const [drawerOpenBtn, setDrawerOpenBtn] = useState(false);
    const [drawer, setDrawer] = useState('block');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useSearchParams();
    const [masterDataTruckDropDown, setMasterDataTruckDropDown] = useState({});
    const [isCheckCPF, setIsCheckCPF] = useState(false);

    const [filter, setFilter] = useState(null);
    const [formatDateText, setFormatDateText] = useState(true);

    function validateDate(inputDate) {
        const dateTimeRegex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;

        if (dateTimeRegex.test(inputDate)) {
            setFormatDateText(true)
            console.log("Valid date and time format");
            return true;
        } else {
            console.log("Invalid date and time format");
            //alert('Format วันที่ผิด');
            setFormatDateText(false)
            return false;
        }
    }

    const preProcessEditCellProps = async (params) => {
        const errorMessage = await validateDate(params.props.value.toString());
        return {...params.props, error: errorMessage};
    };

    function NameEditInputCell(props) {
        const {error} = props;

        return (
            <GridEditInputCell {...props} />
        );
    }

    function renderEditName(params) {
        return <NameEditInputCell {...params} />;
    }

    function SelectEditInputCell(props) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({id, field, value: event.target.value});
            apiRef.current.stopCellEditMode({id, field});
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{height: 1}}
                native
                autoWidth
            >
                {listDelayReason ?
                    listDelayReason.map((e) => {
                        return <option>{e.name}</option>
                    }) : null}
            </Select>
        );
    }

    function ExpandableCell({value, count}) {
        const [expanded, setExpanded] = React.useState(false);

        useEffect(() => {
            console.log("debug use effect")
        }, [])

        return (
            <div>
                {expanded ? value : value?.slice(0, (count ?? 50))}&nbsp;
                {(value?.length ?? 0) > (count ?? 50) && (
                    <Link
                        type="button"
                        component="button"
                        sx={{fontSize: 'inherit'}}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? 'less' : 'more'}
                    </Link>
                )}
            </div>
        );
    }

    function SelectEditInputCellDispatch(props) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({id, field, value: event.target.value});
            apiRef.current.stopCellEditMode({id, field});
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{height: 1}}
                native
                autoWidth
            >
                {listDelayReason ?
                    listDelayReason.map((e) => {
                        return <option>{e.name}</option>
                    }) : null}
            </Select>
        );
    }

    function SelectEditInputCellDot(props) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({id, field, value: event.target.value});
            apiRef.current.stopCellEditMode({id, field});
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{height: 1}}
                native
                autoWidth
            >
                {listDelayReason ?
                    listDelayReason.map((e) => {
                        return <option>{e.name}</option>
                    }) : null}
            </Select>
        );
    }

    function SelectEditInputCellOrderType(props) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({id, field, value: event.target.value});
            apiRef.current.stopCellEditMode({id, field});
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{height: 1}}
                native
                autoWidth
            >
                {option.optionOrderType ?
                    option.optionOrderType?.map((e) => {
                        return <option>{e.name}</option>
                    }) : null}
            </Select>
        );
    }

    function SelectEditInputCellTruckType(props) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        useEffect(() => {
            console.log("Test data => ", props)
        }, [])

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({id, field, value: event.target.value});
            apiRef.current.stopCellEditMode({id, field});
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{height: 1}}
                native
                autoWidth
            >
                <option value={value}>{value}</option>

                {
                    listTruckType?.filter((f) => f.name !== value)?.map((e) => {
                        return <option value={e.name}>{e.name}</option>
                    })
                }
            </Select>
        );
    }

    const renderSelectEditInputCell = (params) => {
        return <SelectEditInputCell {...params} />;
    };

    const renderSelectEditInputCellDispatch = (params) => {
        return <SelectEditInputCellDispatch {...params} />;
    };

    const renderSelectEditInputCellDot = (params) => {
        return <SelectEditInputCellDot {...params} />;
    };

    const renderSelectEditInputCellOrderType = (params) => {
        return <SelectEditInputCellOrderType {...params} />;
    };

    const renderSelectEditInputCellTruckType = (params) => {
        return <SelectEditInputCellTruckType {...params} />;
    };

    const [testColumn, setTestColumn] = useState([]);
    const [truckList, setTruckList] = useState([
        "22 ล้อหัวลาก",
        "18 ล้อหัวลาก",
        "10 ล้อ ตู้ซิ่ง",
        "4 ล้อ คอกซิ่ง",
        "4 ล้อ ตู้ซิ่ง"
    ]);

    let columns = [
        {
            field: 'no',
            headerName: 'ลำดับ',
            width: 100,
            editable: false,
            renderCell: (params) => params.no ?? params.rowIndex
        },
        {
            field: 'editOrder',
            headerClassName: 'super-app-theme--header',
            headerName: 'จัดการออเดอร์',
            // flex: 2,
            minWidth: 230,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {

                return (
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="left"
                        alignItems="center"
                        style={{paddingTop: '10px', paddingBottom: '10px'}}
                    >

                        {
                            (params.row?.splitedtrucknum ?? 0) === 0 && !params.row?.trickingNo?.includes("SPLIT") ?
                                <>
                                    <Tooltip title={'งานพิเศษ'} TransitionComponent={Zoom} placement="top" arrow>
                                        <IconButton
                                            disabled={isProgress}
                                            sx={{p: '5px', m: '0', color: params.row.colorExtraOrder}}
                                            onClick={async () => {
                                                let extraOrder = null
                                                if (params.row.makroSubDeliveryType) {
                                                    if (params.row.makroSubDeliveryType.includes('BuddyMart')) {
                                                        extraOrder = 'BuddyMart';

                                                    } else if (params.row.makroSubDeliveryType.includes('กาชาด')) {
                                                        extraOrder = 'กาชาด';

                                                    } else if (params.row.makroSubDeliveryType.includes('ราชประชา')) {
                                                        extraOrder = 'ราชประชา';
                                                    }
                                                }
                                                console.log({
                                                    destination: {
                                                        id: params.row.destinationSiteId,
                                                        time: params.row.destinationExpectedDeliveryDate
                                                    },
                                                    origin: {id: params.row.originSiteId, time: params.row.originPlanPickupDate},
                                                })
                                                if (params.row.isExtraOrderReady === true) {
                                                    setIsProgress(true)
                                                    // get extar order
                                                    let getExtraOrders = await ApiOrderManagement.getExtraOrder({orderId: params.row.id})
                                                    if (getExtraOrders.status === 200) {
                                                        console.log('getExtraOrders')
                                                        console.log(getExtraOrders.data)
                                                        let data = getExtraOrders.data
                                                        let routes = [getExtraOrders.data.detail]
                                                        console.log('detail')
                                                        console.log(getExtraOrders.data.detail)
                                                        // typeof getExtraOrders.data.routes
                                                        // routes = routes.pop()
                                                        console.log('routes')
                                                        console.log(routes)
                                                        let i = 0
                                                        let result = routes[0].map((e) => {
                                                            i = i + 1;
                                                            return {
                                                                id: i,
                                                                shipmentDetailId: e.id,
                                                                site: e.destinationSiteId !== undefined ? {
                                                                    id: e.destinationSiteId,
                                                                    name: e.destinationSiteName
                                                                } : null,
                                                                pickupTime: e.originPickUpDate !== undefined ? dayjs(e.originPickUpDate.date) : null,
                                                                dropTime: e.destinationExpectedDeliveryDate !== undefined ? dayjs(e.destinationExpectedDeliveryDate.date) : null,
                                                            }
                                                        })
                                                        console.log('result')
                                                        console.log(result)
                                                        setDialogSpecial(
                                                            dialogSpecial => ({
                                                                ...dialogSpecial,
                                                                ...{
                                                                    dialog: true,
                                                                    mode: 'Linehual',
                                                                    shipmentId: params.row.id,
                                                                    subType: extraOrder,
                                                                    remark: data.remark,
                                                                    result: result,
                                                                    orderStatus: params.row.status,
                                                                    lastOrderOfLinehaul: {
                                                                        destination: {
                                                                            id: params.row.destinationSiteId,
                                                                            time: params.row.destinationExpectedDeliveryDate
                                                                        },
                                                                        origin: {
                                                                            id: params.row.originSiteId,
                                                                            time: params.row.originPlanPickupDate
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        )
                                                        setDialogSpecial((prevState) => {
                                                            const updatedResult = [...prevState.result];
                                                            updatedResult.pop(); // Remove the last item

                                                            return {
                                                                ...prevState,
                                                                result: updatedResult,
                                                            };
                                                        });
                                                    } else {
                                                        alert('กรุณาลองใหม่อีกครั้ง')
                                                    }
                                                    //
                                                    setIsProgress(false)
                                                } else {
                                                    setDialogSpecial(
                                                        dialogSpecial => ({
                                                            ...dialogSpecial,
                                                            ...{
                                                                dialog: true,
                                                                mode: 'Linehual',
                                                                data: [],
                                                                shipmentId: params.row.id,
                                                                subType: extraOrder,
                                                                remark: params.row.remark,
                                                                orderStatus: params.row.status,
                                                                lastOrderOfLinehaul: {
                                                                    destination: {
                                                                        id: params.row.destinationSiteId,
                                                                        time: params.row.destinationExpectedDeliveryDate
                                                                    },
                                                                    origin: {
                                                                        id: params.row.originSiteId,
                                                                        time: params.row.originPlanPickupDate
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    )
                                                }
                                            }}
                                        >
                                            <StoreMallDirectoryOutlinedIcon/>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Backhual'} TransitionComponent={Zoom} placement="top" arrow>
                                        <IconButton
                                            disabled={isProgress}
                                            sx={{p: '5px', m: '0', color: params.row.colorBackhual}}
                                            onClick={async () => {
                                                console.log(params.row)
                                                closeDialogSpecial()
                                                // if (params.row.makroSubDeliveryType){
                                                setIsProgress(true)
                                                let resGetExtra = await ApiOrderManagement.getExtraOrderBackhaul({orderId: params.row.id});
                                                if (resGetExtra.status === 200) {
                                                    setIsProgress(false)
                                                    console.log('resGetExtra')
                                                    console.log(resGetExtra.data)
                                                    if (resGetExtra.data.data !== null) {
                                                        let origin = {
                                                            id: resGetExtra.data.data.detail[0].originSiteId !== undefined ? resGetExtra.data.data.detail[0].originSiteId : null,
                                                            site: resGetExtra.data.data.detail[0].originSiteId !== undefined ? {
                                                                id: resGetExtra.data.data.detail[0].originSiteId,
                                                                name: resGetExtra.data.data.detail[0].originSiteName
                                                            } : null,
                                                            time: resGetExtra.data.data.detail[0].originPickUpDate ? dayjs(resGetExtra.data.data.detail[0].originPickUpDate.date) : null,
                                                            titleSite: 'ต้นทาง',
                                                            titleTime: 'เวลารับ',
                                                        }
                                                        let desBH = await resGetExtra.data.data.detail.map((e) => {
                                                            return {
                                                                id: resGetExtra.data.data.detail[0].destinationSiteId !== undefined ? resGetExtra.data.data.detail[0].destinationSiteId : null,
                                                                site: resGetExtra.data.data.detail[0].destinationSiteId !== undefined ? {
                                                                    id: resGetExtra.data.data.detail[0].destinationSiteId,
                                                                    name: resGetExtra.data.data.detail[0].destinationSiteName
                                                                } : null,
                                                                time: resGetExtra.data.data.detail[0].destinationExpectedDeliveryDate !== undefined ? dayjs(resGetExtra.data.data.detail[0].destinationExpectedDeliveryDate.date) : null,
                                                                titleSite: 'ปลายทาง',
                                                                titleTime: 'เวลาส่ง',
                                                            }
                                                        })
                                                        let backhual = [origin, desBH[0]]
                                                        console.log(origin, desBH, backhual)

                                                        setDialogSpecial(
                                                            dialogSpecial => ({
                                                                ...dialogSpecial,
                                                                ...{
                                                                    dialog: true,
                                                                    mode: 'Backhual',
                                                                    shipmentId: params.row.id,
                                                                    shipmentBackhualId: resGetExtra.data.data.id !== undefined ? resGetExtra.data.data.id : null,
                                                                    // remark: resGetExtra.data.data.remark,
                                                                    remark: params.row.remark,
                                                                    image: resGetExtra.data.image !== undefined ? resGetExtra.data.data.image : [],
                                                                    tmsOrderNo: resGetExtra.data.data.tmsOrderNo,
                                                                    backhual: backhual,
                                                                }
                                                            })
                                                        )
                                                    } else {
                                                        setDialogSpecial(
                                                            dialogSpecial => ({
                                                                ...dialogSpecial,
                                                                ...{
                                                                    dialog: true,
                                                                    mode: 'Backhual',
                                                                    shipmentId: params.row.id,
                                                                    shipmentBackhualId: null,
                                                                    remark: params.row.remark,
                                                                    data: [],
                                                                }
                                                            })
                                                        )
                                                    }
                                                } else {
                                                    setIsProgress(false)
                                                }
                                                setIsProgress(false)
                                                /*} else {
                                                        setDialogSpecial(
                                                            dialogSpecial => ({
                                                                ...dialogSpecial,
                                                                ...{
                                                                    dialog: true,
                                                                    mode: 'Backhual',
                                                                    shipmentId: params.row.id,
                                                                    shipmentBackhualId: null,
                                                                    remark: params.row.remark,
                                                                    data: [],
                                                                }})
                                                        )
                                                    }*/

                                            }}
                                        >
                                            <LocalShippingIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </> : <div style={{ width: '68px', height: '20px' }} />

                        }

                        <Tooltip title={'รายละเอียด'} TransitionComponent={Zoom} placement="top" arrow>
                            <a href={"/shipment/orderManagement/" + params.row.id + "?trickingNo=" + params.row.trickingNo}
                               target="_blank">
                                <IconButton
                                    disabled={isProgress}
                                    sx={{p: '5px', m: '0', color: '#4380E1'}}
                                >
                                    <DvrOutlinedIcon/>
                                </IconButton>
                            </a>
                        </Tooltip>

                        {
                            params.row.truckType && (params.row?.splitedtrucknum ?? 0) > 0 && !params.row?.trickingNo?.includes("SPLIT") ?
                                <Tooltip title={'Split truck'} TransitionComponent={Zoom} placement="top" arrow>
                                    <IconButton
                                        disabled={isProgress || (params.row?.splitedtrucknum ?? 0) === 0}
                                        sx={{p: '5px', m: '0', color: params.row?.isSplitApprove === true ? "#80b918" : '#f48c06'}}
                                        onClick={async () => {
                                            const orderData = params.row

                                            if (orderData) {
                                                setIsLoading(true)
                                                let res = await ApiOrderManagement.getSplitTruck({
                                                    loadno: params.row.trickingNo
                                                })
                                                setIsLoading(false)

                                                if (res.status === 200) {
                                                    SplitTruckDialog(
                                                        "ใช้รถหลายคัน " + orderData.trickingNo ?? "-",
                                                        res.data ?? [],
                                                        truckList,
                                                        orderData,
                                                        () => {
                                                            updateSplitTruck(orderData.id, true)
                                                        },
                                                        () => {
                                                            updateSplitTruck(orderData.id, false)
                                                        },
                                                        (res.data?.filter(f => f.tmsOrderNo === null)?.length ?? 0) > 0
                                                    )
                                                } else {
                                                    console.log("===== Debug split truck error: ", res)
                                                }
                                            }
                                        }}
                                    >
                                        <Badge
                                            badgeContent={params.row?.splitedtrucknum ?? 0}
                                            color={params.row?.isSplitApprove === true ? "success" : "error"}
                                        >
                                            <CallSplitIcon/>
                                        </Badge>
                                    </IconButton>
                                </Tooltip> : null
                        }

                        {
                            params.row?.trickingNo?.includes("SPLIT") ?
                                <Tooltip title={"Split truck: " + params.row?.trickingNo} TransitionComponent={Zoom} placement="top" arrow>
                                    <IconButton
                                        disabled={isProgress}
                                        color="error"
                                        sx={{p: '5px', m: '0', color: '#4cc9f0'}}
                                    >
                                        <Badge badgeContent={getSplitBadge(params.row?.trickingNo)} color="info">
                                            <CallSplitIcon/>
                                        </Badge>
                                    </IconButton>
                                </Tooltip> : null
                        }

                        {
                            // params.row.status !== 'Send to TMS'
                            (params.row?.splitedtrucknum ?? 0) === 0 && !params.row?.trickingNo?.includes("SPLIT") ?
                                <Tooltip title={'ยกเลิกออเดอร์'} TransitionComponent={Zoom} placement="top" arrow>
                                    <IconButton
                                        disabled={isProgress}
                                        sx={{p: '5px', m: '0', color: '#dc2f02'}}
                                        onClick={async () => {
                                            Swal.fire({
                                                // title: 'ยืนยันทะเบียนรถทั้งหมด'+{}+'รายการนี้หรือไม่ ?',
                                                text: 'คุณต้องการจะ "ยกเลิก" รายการออเดอร์นี้ใช่ หรือไม่ ?',
                                                icon: 'question',
                                                showCancelButton: true,
                                                cancelButtonText: 'ยกเลิก',
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'ยืนยัน'
                                            }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                    await setIsLoading(true)
                                                    await cancelOrder(params.row.id, params.row.status, JSON.parse(localStorage.getItem("profile")).userId);
                                                    setTrigger('Y')
                                                    await setIsLoading(false)

                                                } else if (result.dismiss === Swal.DismissReason.cancel) {
                                                    /*Swal.fire({
                                                            title: 'ยืนยัน vendor ไม่สำเร็จ',
                                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                        })*/
                                                }
                                            })
                                        }}
                                    >
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </Tooltip> : null
                        }

                        {
                            params.row.tmsError && (params.row?.splitedtrucknum ?? 0) === 0 ?
                                <Tooltip title={params.row.tmsError} TransitionComponent={Zoom} placement="top" arrow>
                                    <IconButton
                                        disabled={isProgress}
                                        sx={{p: '5px', m: '0', color: '#4380E1'}}
                                        onClick={() => {
                                            ErrorMessageTMSDialog(
                                                "Error Message",
                                                params.row.tmsError,
                                                masterData.storeSub?.filter((f) => {
                                                    return (
                                                        f.buSiteId === params.row.originSiteId
                                                    );
                                                }),
                                                params.row,
                                                async (data) => {
                                                    await fetchData('N', search).catch((e) => {
                                                        console.log(e)
                                                        setIsLoading(false)
                                                        setIsProgress(false)
                                                    })
                                                },
                                                true
                                            )
                                        }}
                                    >
                                        <PriorityHighIcon/>
                                    </IconButton>
                                </Tooltip> : null
                        }
                    </Grid>
                )

            }
        },
        {
            field: 'actualVendorName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ชื่อคู่ค้า',
            minWidth: 300,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (paramss) => {
                /*if (paramss.row.vendorLoading === undefined){
                    return (<></>)
                }*/
                if (paramss.row.status !== 'Allocated' && paramss.row.status !== 'Reject' && paramss.row.status !== 'Waiting') {
                    return (<>{paramss.row.actualVendorName}</>)
                }
                return (
                    <Grid container item>
                        <Autocomplete
                            disabled={isProgress}
                            fullWidth
                            open={paramss.row.vendorOpen}
                            onOpen={async () => {
                                paramss.row.vendorOpen = true;
                                setRows([...rows])
                            }}
                            onClose={() => {
                                paramss.row.vendorOpen = false;
                                setRows([...rows])
                            }}
                            onChange={async (event, value) => {
                                if (value) {
                                    console.log('value')
                                    console.log(value)
                                    paramss.row.confirmVendorCode = value.id;
                                    paramss.row.confirmVendorName = value.name;
                                    paramss.row.vendorSelectedValue = value;

                                    setRows([...rows])
                                    if (value.id !== paramss.row.oldVendorCode && paramss.row.oldVendorCode) {
                                        paramss.row.truckSelectedValue = null;
                                        paramss.row.confirmVehicleNumberPlate = null;
                                        paramss.row.planedDriverName = null;

                                        setDialog(
                                            dialog => ({
                                                ...dialog,
                                                ...{
                                                    dialog: true,
                                                    mode: 'reason',
                                                    rowsId: paramss.row.id,
                                                    reasonId: paramss.row.reasonId,
                                                }
                                            })
                                        )
                                    } else { // ทะเบียนตรงกับที่แพลน
                                        paramss.row.reasonId = null;
                                        paramss.row.truckSelectedValue = paramss.row.oldVehicleNumberPlate ? {
                                            id: 1,
                                            name: paramss.row.oldVehicleNumberPlate
                                        } : null;
                                        paramss.row.planedDriverName = paramss.row.oldPlanedDriverName ? paramss.row.oldPlanedDriverName : null;
                                        paramss.row.confirmVehicleNumberPlate = paramss.row.oldVehicleNumberPlate ? paramss.row.oldVehicleNumberPlate : null;
                                    }
                                } else {
                                    paramss.row.vendorSelectedValue = null;
                                    paramss.row.confirmVendorCode = null;
                                    paramss.row.confirmVendorName = null;

                                    paramss.row.truckSelectedValue = null;
                                    paramss.row.confirmVehicleNumberPlate = null;

                                    paramss.row.planedDriverName = null;
                                }
                                setRows([...rows])
                            }}
                            getOptionLabel={(option) => option.name}
                            options={
                                masterData.storeSub?.filter((option) => {
                                    return (
                                        option.buSiteId === paramss.row.originSiteId
                                        // && option.storeSiteId === paramss.row.destinationSiteId
                                    );
                                }).map((option) => {
                                    return {
                                        name: option.vendorName,
                                        id: option.vendorCode,
                                    };
                                }) ?? []
                            }
                            loading={paramss.row.vendorLoading}
                            value={paramss.row.vendorSelectedValue}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size={'small'}
                                    sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}, height: '56px',}}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}

                        />
                    </Grid>
                )
            }
        },
        {
            field: 'confirmVehicleNumberPlate',
            headerClassName: 'super-app-theme--header',
            headerName: 'License plate',
            flex: 1,
            minWidth: 240,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (paramss) => {
                if (paramss.row.truckLoading === undefined) {
                    return (<></>)
                }
                if (paramss.row.status === 'Approved') {
                    return (<>{paramss.row.confirmVehicleNumberPlate} {paramss.row.actualTruckProvince}</>)
                }
                if (paramss.row.status !== 'Allocated' && paramss.row.status !== 'Reject' && paramss.row.status !== 'Waiting') {
                    if (paramss.row.warningVehicle) {
                        return (
                            <Tooltip title={paramss.row.warningVehicle} TransitionComponent={Zoom} placement="top" arrow>
                                <Typography sx={{
                                    color: '#d7be18',
                                    cursor: 'pointer'
                                }}>{paramss.row.actualVehicleNumberPlate}</Typography>
                            </Tooltip>
                        )
                    } else return paramss.row.actualVehicleNumberPlate;
                }
                return (
                    <Grid container item>
                        <Autocomplete
                            size={'small'}
                            style={{justifyContent: 'center'}}
                            disabled={isProgress ? isProgress : !paramss.row.confirmVendorCode}
                            fullWidth
                            value={paramss.row.truckSelectedValue}
                            open={paramss.row.truckOpen}
                            sx={{p: 0}}
                            onOpen={async () => {
                                paramss.row.truckOpen = true;
                                console.log("===== Debug truck data => ", masterDataTruckDropDown)

                                // ====== New code load master 1 time
                                if ((masterDataTruckDropDown[paramss.row.vendorCode]?.length ?? 0) === 0) {

                                    setIsProgress(true)
                                    paramss.row.truckLoading = true;

                                    let resGetTruck = await ApiOrderManagement.getVendorPlaning({VendorCode: [paramss.row.confirmVendorCode]});
                                    if (resGetTruck) {
                                        paramss.row.masterData = resGetTruck.data[0];
                                        const transformedData = resGetTruck.data.reduce((acc, vendor) => {
                                            const {VendorCode, Details} = vendor;

                                            acc[VendorCode] = Details.map(truckDetailS => ({
                                                id: truckDetailS.TruckId,
                                                name: truckDetailS.TruckNo,
                                                priority: truckDetailS.Priority,
                                                provincialSign: truckDetailS.ProvincialSign,
                                                fleetUsageId: truckDetailS.FleetUsageId,
                                                fleetUsageName: truckDetailS.FleetUsageName,
                                                truckTypeId: truckDetailS.TruckTypeId,
                                                truckTypeName: truckDetailS.TruckTypeName,
                                                siteName: truckDetailS.SiteName,
                                                color: truckDetailS.color,
                                                title: truckDetailS.title,
                                                makroTitle: truckDetailS.makroTitle,
                                                makroTime: truckDetailS.makroTime,
                                                driver: null
                                            }));

                                            return acc;
                                        }, {});
                                        setMasterDataTruckDropDown(
                                            masterDataTruckDropDown => ({
                                                ...masterDataTruckDropDown,
                                                ...transformedData,
                                            })
                                        )
                                        setIsProgress(false)
                                        paramss.row.truckOpen = true;
                                        paramss.row.truckLoading = false;
                                        setRows([...rows])
                                    }

                                    paramss.row.truckLoading = false;
                                    setRows([...rows])
                                    setIsProgress(false)
                                }
                            }}
                            onClose={() => {
                                paramss.row.truckOpen = false;
                                setRows([...rows])
                            }}
                            onChange={async (event, value) => {
                                if (value) {
                                    paramss.row.truckSelectedValue = value;
                                    paramss.row.confirmVehicleNumberPlate = value.name;
                                    /* if (value.name !== paramss.row.oldVehicleNumberPlate){ // ทะเบียนใหม่ที่ไม่เหมือนกับที่แพลนมา
                                        paramss.row.planedDriverName = null;
                                    } else {
                                        paramss.row.planedDriverName = paramss.row.oldVehicleNumberPlate ;
                                    }*/
                                } else {
                                    paramss.row.truckSelectedValue = null;
                                    paramss.row.confirmVehicleNumberPlate = null;
                                    paramss.row.planedDriverName = null;
                                }
                            }}
                            getOptionLabel={
                                (option) => option.name + " " + option.provincialSign
                            }
                            options={
                                masterDataTruckDropDown[paramss.row.confirmVendorCode]
                                    ?
                                    masterDataTruckDropDown[paramss.row.confirmVendorCode].map((option, index) => {
                                        return option
                                    })
                                    :
                                    []
                            }
                            loading={paramss.row.truckLoading}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        <Grid>
                                            <span style={{color: option.color, margin: '0px'}}>
                                                {option.name} {option.provincialSign}
                                            </span>
                                        </Grid>
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size={'small'}
                                    sx={{'legend': {display: 'none'}, '& fieldset': {top: 0}, height: '56px',}}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}

                        />
                    </Grid>
                )
            }
        },
        {
            field: 'customerName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ชื่อลูกค้า',
            minWidth: 220,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'originSiteName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ต้นทาง',
            minWidth: 350,
            flex: 3,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'destinationSiteName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ปลายทาง',
            minWidth: 230,
            flex: 3,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'orderType',
            headerClassName: 'super-app-theme--header',
            headerName: 'ประเภท',
            minWidth: 90,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderEditCell: renderSelectEditInputCellOrderType,
            renderCell: (params) => {
                let orderType = null;
                if (params.row.orderType) {
                    orderType = params.row.orderType;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {orderType !== null ?
                            orderType
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'buTruckType',
            headerClassName: 'super-app-theme--header',
            headerName: 'ประเภทรถลูกค้า',
            minWidth: 130,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderEditCell: renderSelectEditInputCellTruckType,
            renderCell: (params) => {
                let buTruckType = null;
                if (params.row.buTruckType) {
                    buTruckType = params.row.buTruckType;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {buTruckType !== null ?
                            buTruckType
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'customerLineCode',
            headerClassName: 'super-app-theme--header',
            headerName: 'สาย',
            minWidth: 130,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'assignDriverToAnny',
            headerClassName: 'super-app-theme--header',
            headerName: 'Anny Driver',
            minWidth: 120,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.assignDriverToAnny) {
                    return (
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CheckIcon/>
                        </Grid>
                    )
                } else {
                    return (
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            onClick={async () => {
                                setIsProgress(true);
                                let res = await ApiOrderManagement.findDriverIdAnnyByBuTrackingNo({trackingNo: params.row.trickingNo});
                                if (res) {
                                    setIsProgress(false);
                                    if (res.status === 200) {
                                        setIsProgress(false);
                                        alert(res.data)
                                    }
                                }
                                setIsProgress(false);
                            }}
                        >
                            <Tooltip title={'ดูข้อมูลคนขับ'} TransitionComponent={Zoom} placement="top" arrow>
                                <CloseIcon/>
                            </Tooltip>
                        </Grid>
                    )
                }
            }
        },
        {
            field: 'drops',
            headerClassName: 'super-app-theme--header',
            headerName: 'จำนวนจุดส่ง',
            minWidth: 130,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography>{params.row.countTrackingTrackStatus}/{params.row.drops}</Typography>
                    </Grid>
                )

            }
        },
        {
            field: 'isFullset',
            headerClassName: 'super-app-theme--header',
            headerName: 'หัวหาง?',
            // flex: 2,
            minWidth: 120,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Checkbox
                            disabled={isProgress}
                            checked={params.row.isFullSet}
                            onChange={async (e) => {
                                setIsProgress(true)
                                // console.log('e.target.checked')
                                // console.log(e.target.checked)
                                let setFullset = await ApiOrderManagement.updateOrderHeader({
                                    id: params.row.id,
                                    isFullSet: e.target.checked
                                })
                                if (setFullset.status === 200) {
                                    params.row.isFullSet = e.target.checked
                                    setRows([...rows])
                                    let testSelectionModels = rowSelectionModel.map((el) => {
                                        if (params.row.id !== el) {
                                            return el;
                                        } else {
                                            return null;
                                        }
                                    })
                                    testSelectionModels = testSelectionModels.filter(Boolean);
                                    console.log('testSelectionModels')
                                    console.log(testSelectionModels)
                                    setRowSelectionModel(testSelectionModels)
                                    let testDataListConfirms = dataListConfirm.map((e) => {
                                        if (params.row.id !== e.id) {
                                            return e;
                                        } else {
                                            return null;
                                        }
                                    })
                                    testDataListConfirms = testDataListConfirms.filter(Boolean);
                                    console.log('testDataListConfirms')
                                    console.log(testDataListConfirms)
                                    setDataListConfirm(testDataListConfirms)

                                    fetchStatus(search)
                                        .then((data) => {
                                            setStatusNum(data)
                                        })

                                    await fetchData('N', search).catch((e) => {
                                        console.log(e)
                                        setIsLoading(false)
                                        setIsProgress(false)
                                    })
                                }
                                setIsProgress(false)
                            }}
                        />
                    </Grid>
                )

            }
        },
        {
            field: 'remark',
            headerClassName: 'super-app-theme--header',
            headerName: 'หมายเหตุ',
            minWidth: 320,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            // renderCell: (params) => <ExpandableCell {...params} />,
        },
        {
            field: 'status',
            headerClassName: 'super-app-theme--header',
            headerName: 'สถานะ',
            minWidth: 120,
            flex: 1,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'trickingNo',
            headerClassName: 'super-app-theme--header',
            headerName: 'หมายเลขการติดตาม',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'tripNo',
            headerClassName: 'super-app-theme--header',
            headerName: 'Trip No.',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
        },
        {
            field: 'tmsOrderNo',
            headerClassName: 'super-app-theme--header',
            headerName: 'หมายเลข TMS',
            // flex: 2,
            minWidth: 240,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (!params.row.tmsOrderNo) {
                    return (<></>)
                }
                const stringTmsNo = params.row.tmsOrderNo;
                const remove5StringFirst = stringTmsNo.substring(5);
                const integerTmsNo = parseInt(remove5StringFirst);
                return (
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <a href={new API().getTMSAPI() + 'activity-tracking/detail/job/' + integerTmsNo} target="_blank"
                           rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                            {/*<Typography color={'black'}>{params.row.tmsOrderNo}</Typography>*/}
                            {params.row.tmsOrderNo}
                        </a>
                    </Grid>
                )

            }
        },
        {
            field: 'fileResultFileName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ชื่อไฟล์',
            minWidth: 280,
            flex: 2,
            sortable: true,
            align: 'left',
            headerAlign: 'center',
            editable: true,
        },
        {
            field: 'truckLocation', headerClassName: 'super-app-theme--header',
            headerName: 'ตำแหน่งรถล่าสุด',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let truckLocation = null;
                if (params.row.truckLocation) {
                    truckLocation = params.row.truckLocation;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        <a target="_blank" href={params.row.googleMap ? params.row.googleMap : ""}>
                            {truckLocation !== null ?
                                truckLocation
                                :
                                null
                            }
                        </a>
                    </Grid>
                )
            }
        },
        {
            field: 'tmsJobStatus', headerClassName: 'super-app-theme--header',
            headerName: 'TMS Status',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {
                            params.row.tmsJobStatus ?? ""
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'gpsOrderStatus',
            headerClassName: 'super-app-theme--header',
            headerName: 'สถานะ Order (TMS)',
            minWidth: 120,
            flex: 1,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let gpsOrderStatus = null;
                if (params.row.gpsOrderStatus) {
                    gpsOrderStatus = params.row.gpsOrderStatus;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {gpsOrderStatus !== null ?
                            gpsOrderStatus
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'changedVehicleNumberPlateReason',
            headerClassName: 'super-app-theme--header',
            headerName: 'เหตุผลการเปลี่ยนทะเบียน',
            minWidth: 320,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actualVehicleNumberPlate',
            headerClassName: 'super-app-theme--header',
            headerName: 'ทะเบียน',
            minWidth: 180,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.warningVehicle) {
                    return (
                        <Tooltip title={params.row.warningVehicle} TransitionComponent={Zoom} placement="top" arrow>
                            <Typography sx={{
                                color: '#d7be18',
                                cursor: 'pointer'
                            }}>{params.row.actualVehicleNumberPlate}</Typography>
                        </Tooltip>
                    )
                } else return params.row.actualVehicleNumberPlate;
            }
        },
        {
            field: 'actualTruckProvince',
            headerClassName: 'super-app-theme--header',
            headerName: 'จังหวัด',
            minWidth: 180,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'truckType',
            headerClassName: 'super-app-theme--header',
            headerName: 'ประเภทรถ',
            minWidth: 180,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            // renderEditCell: renderSelectEditInputCellTruckType,
            renderCell: (params) => {
                let truckType = null;
                if (params.row.truckType) {
                    truckType = params.row.truckType;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {truckType !== null ?
                            truckType
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'trailerNo',
            headerClassName: 'super-app-theme--header',
            headerName: 'ทะเบียนหาง',
            minWidth: 100,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'driverTotal',
            headerClassName: 'super-app-theme--header',
            headerName: 'สายใกล้/สายไกล',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'totalDuration',
            headerClassName: 'super-app-theme--header',
            headerName: 'ระยะเวลา',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'totalDistance',
            headerClassName: 'super-app-theme--header',
            headerName: 'ระยะทาง',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actualDriverName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ชื่อคนขับ',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.warningDriver) {
                    return (
                        <Tooltip title={params.row.warningDriver} TransitionComponent={Zoom} placement="top" arrow>
                            <Typography
                                sx={{color: '#d7be18', cursor: 'pointer'}}>{params.row.actualDriverName}</Typography>
                        </Tooltip>
                    )
                } else return params.row.actualDriverName;
            }
        },
        {
            field: 'driverTel',
            headerClassName: 'super-app-theme--header',
            headerName: 'โทร',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actualDriverName2',
            headerClassName: 'super-app-theme--header',
            headerName: 'ชื่อคนขับ 2',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'date',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันที่อัพโหลด',
            minWidth: 160,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Tooltip title={'ดาวน์โหลดไฟล์'} TransitionComponent={Zoom} placement="top" arrow>
                        <Typography
                            sx={{cursor: 'pointer'}}
                            onClick={async () => {
                                setIsLoading(true)
                                let res = await ApiOrderManagement.downloadFileImport({
                                    id: params.row.fileResultId,
                                    bu: params.row.customerName
                                });
                                if (res.status !== 200) {
                                    setIsLoading(false)
                                    Swal.fire({
                                        title: 'ดาวน์โหลดไม่สำเร็จ',
                                        text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                        icon: 'error',
                                        showConfirmButton: false,
                                    })
                                } else {
                                    FileDownload(res.data, params.row.fileResultFileName);
                                }

                                setIsLoading(false)
                            }}
                        >
                            {params.row.date}
                        </Typography>
                    </Tooltip>
                )
            }
        },
        {
            field: 'operationApprove',
            headerClassName: 'super-app-theme--header',
            headerName: 'เวลา Allnow ส่งงาน',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'originExpectedPickupDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลาจ่ายงาน',
            minWidth: 120,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'originPlanPickupDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถจะเข้ารับเอกสาร',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let lines = null;
                if (params.row.originPlanPickupDate) {
                    lines = params.row.originPlanPickupDate.split(' ');
                }
                return (
                    <div>
                        {lines !== null ?
                            lines.map((line, index) => (
                                <div key={index}>{line}</div>
                            ))
                            :
                            null
                        }
                    </div>
                )
            }
        },
        {
            field: 'temparatureRemark',
            headerClassName: 'super-app-theme--header',
            headerName: 'อุณหภูมิลูกค้า',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'truckTemp',
            headerClassName: 'super-app-theme--header',
            headerName: 'อุณหภูมิ (GPS)',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'r4',
            headerClassName: 'super-app-theme--header',
            headerName: 'ร4',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'productRemark',
            headerClassName: 'super-app-theme--header',
            headerName: 'ประเภทสินค้าลูกค้า',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'jobTypeRemark',
            headerClassName: 'super-app-theme--header',
            headerName: 'ประเภทงานลูกค้า',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'halal',
            headerClassName: 'super-app-theme--header',
            headerName: 'Halal',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'subContractorRemark',
            headerClassName: 'super-app-theme--header',
            headerName: 'ความคิดเห็นของซัพ',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'roundNo',
            headerClassName: 'super-app-theme--header',
            headerName: 'รอบการส่งสินค้า',
            minWidth: 130,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'totalPackage',
            headerClassName: 'super-app-theme--header',
            headerName: 'ยอดสั่ง',
            minWidth: 130,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'createdBy',
            headerClassName: 'super-app-theme--header',
            headerName: 'สร้างโดย',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'diffTime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลาที่เหลือ',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'theLastDispatchMinus2H',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลานัดรับเอกสาร',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'theLastDispatchMinus4H',
            headerClassName: 'super-app-theme--header',
            headerName: '(Plan) วันเวลานัดแจ้งหัวDHL',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'vendorConfirm',
            headerClassName: 'super-app-theme--header',
            headerName: '(Actual) เวลาซับยืนยัน',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let colorSubConfirmDelay = null;
                let vendorConfirm = null;
                if (params.row.colorSubConfirmDelay) {
                    colorSubConfirmDelay = params.row.colorSubConfirmDelay;
                }
                if (params.row.vendorConfirm) {
                    vendorConfirm = params.row.vendorConfirm;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: colorSubConfirmDelay}}>
                        {vendorConfirm !== null ?
                            vendorConfirm
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'subConfirmDelayReason',
            headerClassName: 'super-app-theme--header',
            headerName: 'เหตุผลที่ช้า',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderEditCell: renderSelectEditInputCell,
        },
        {
            field: 'trackingTruckRemark', headerClassName: 'super-app-theme--header',
            headerName: 'Remark',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'actualArrivalOriginDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถถึงคลัง (Actual)',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualArrivalOriginDatetime = null;
                if (params.row.actualArrivalOriginDatetime) {
                    actualArrivalOriginDatetime = params.row.actualArrivalOriginDatetime;
                }

                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {actualArrivalOriginDatetime !== null ?
                            actualArrivalOriginDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'gpsActualArrivalOriginDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถถึงคลัง (GPS)',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let gpsActualArrivalOriginDatetime = null;
                if (params.row.gpsActualArrivalOriginDatetime) {
                    gpsActualArrivalOriginDatetime = params.row.gpsActualArrivalOriginDatetime;
                }

                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {gpsActualArrivalOriginDatetime !== null ?
                            gpsActualArrivalOriginDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'actualPickupDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารับเอกสาร',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualPickupDatetime = null;
                if (params.row.actualPickupDatetime) {
                    actualPickupDatetime = params.row.actualPickupDatetime;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {actualPickupDatetime !== null ?
                            actualPickupDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'lastDispatchDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Last Dispatch (Plan)',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let lastDispatchDate = null;
                if (params.row.lastDispatchDate) {
                    lastDispatchDate = params.row.lastDispatchDate;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {lastDispatchDate !== null ?
                            lastDispatchDate
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'actualDepartedOriginDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถออกจากคลัง (Actual)',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let colorDepartOriginDelay = null;
                if (params.row.colorDepartOriginDelay) {
                    colorDepartOriginDelay = params.row.colorDepartOriginDelay;
                }
                let actualDepartedOriginDatetime = null;
                if (params.row.actualDepartedOriginDatetime) {
                    actualDepartedOriginDatetime = params.row.actualDepartedOriginDatetime;
                }
                let departOriginStampText = null;
                let theFrontWeight = 'regular';
                if (params.row.departOriginStampText) {
                    departOriginStampText = params.row.departOriginStampText;
                    if (departOriginStampText !== 'system') {
                        theFrontWeight = 1000;
                    }
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: colorDepartOriginDelay, fontWeight: theFrontWeight}}>
                        {actualDepartedOriginDatetime !== null ?
                            actualDepartedOriginDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'gpsActualDepartedOriginDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถออกจากคลัง (GPS)',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualDepartedOriginDatetime = null;
                if (params.row.gpsActualDepartedOriginDatetime) {
                    actualDepartedOriginDatetime = params.row.gpsActualDepartedOriginDatetime;
                }

                return (
                    <Grid sx={{pt: 0, m: '0'}}>
                        {actualDepartedOriginDatetime !== null ?
                            actualDepartedOriginDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'dispatchDelayReason',
            headerClassName: 'super-app-theme--header',
            headerName: 'เหตุผลที่ช้า',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderEditCell: renderSelectEditInputCellDispatch,
        },
        {
            field: 'trackingTruckRemarkDispatch', headerClassName: 'super-app-theme--header',
            headerName: 'RemarkDispatch',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'destinationExpectedDeliveryDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลานัดส่งสาขา (Plan)',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let destinationExpectedDeliveryDate = null;
                if (params.row.destinationExpectedDeliveryDate) {
                    destinationExpectedDeliveryDate = params.row.destinationExpectedDeliveryDate;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {destinationExpectedDeliveryDate !== null ?
                            destinationExpectedDeliveryDate
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'actualArrivalDestDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถถึงสาขา (Actual)',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let colorArriveDestinationDelay = null;
                if (params.row.colorArriveDestinationDelay) {
                    colorArriveDestinationDelay = params.row.colorArriveDestinationDelay;
                }
                let actualArrivalDestDatetime = null;
                if (params.row.actualArrivalDestDatetime) {
                    actualArrivalDestDatetime = params.row.actualArrivalDestDatetime;
                }
                let arriveDestinationStampText = null;
                let theFrontWeight = 'regular';
                if (params.row.arriveDestinationStampText) {
                    arriveDestinationStampText = params.row.arriveDestinationStampText;
                    if (arriveDestinationStampText !== 'system') {
                        theFrontWeight = 1000;
                    }
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: colorArriveDestinationDelay, fontWeight: theFrontWeight}}>
                        {actualArrivalDestDatetime !== null ?
                            actualArrivalDestDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'gpsActualArrivalDestDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถถึงสาขา (GPS)',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let gpsActualArrivalDestDatetime = null;
                if (params.row.gpsActualArrivalDestDatetime) {
                    gpsActualArrivalDestDatetime = params.row.gpsActualArrivalDestDatetime;
                }
                // let arriveDestinationStampText = null;
                // let theFrontWeight = 'regular';
                // if (params.row.arriveDestinationStampText){
                //     arriveDestinationStampText = params.row.arriveDestinationStampText;
                //     if(arriveDestinationStampText !== 'system'){
                //         theFrontWeight = 1000;
                //     }
                // }
                return (
                    <Grid sx={{pt: 0, m: '0'}}>
                        {gpsActualArrivalDestDatetime !== null ?
                            gpsActualArrivalDestDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'dotDelayReason',
            headerClassName: 'super-app-theme--header',
            headerName: 'เหตุผลที่ช้า',
            minWidth: 150,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderEditCell: renderSelectEditInputCellDot,
        },
        {
            field: 'trackingTruckRemarkDelivered', headerClassName: 'super-app-theme--header',
            headerName: 'RemarkDelivered',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'actualDepartedDestDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถออกสาขา',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualDepartedDestDatetime = null;
                if (params.row.actualDepartedDestDatetime) {
                    actualDepartedDestDatetime = params.row.actualDepartedDestDatetime;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {actualDepartedDestDatetime !== null ?
                            actualDepartedDestDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'gpsActualDepartedDestDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถออกสาขา (GPS)',
            minWidth: 200,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualDepartedDestDatetime = null;
                if (params.row.actualDepartedDestDatetime) {
                    actualDepartedDestDatetime = params.row.gpsActualDepartedDestDatetime;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {actualDepartedDestDatetime !== null ?
                            actualDepartedDestDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'arrivedBackhaul', headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลารถถึง Backhaul',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'planReturnOriginDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'เวลานัดกลับคลัง (plan)',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let planReturnOriginDatetime = null;
                if (params.row.planReturnOriginDatetime) {
                    planReturnOriginDatetime = params.row.planReturnOriginDatetime;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#000'}}>
                        {planReturnOriginDatetime !== null ?
                            planReturnOriginDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'actualReturnOriginDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลากลับถึงคลังต้นทาง',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualReturnOriginDatetime = null;
                if (params.row.actualReturnOriginDatetime) {
                    actualReturnOriginDatetime = params.row.actualReturnOriginDatetime;
                }
                let colorActualReturnOriginDelay = null;
                if (params.row.colorActualReturnOriginDelay) {
                    colorActualReturnOriginDelay = params.row.colorActualReturnOriginDelay;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: colorActualReturnOriginDelay}}>
                        {actualReturnOriginDatetime !== null ?
                            actualReturnOriginDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'gpsActualReturnOriginDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลากลับถึงคลังต้นทาง (GPS)',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualReturnOriginDatetime = null;
                if (params.row.actualReturnOriginDatetime) {
                    actualReturnOriginDatetime = params.row.gpsActualReturnOriginDatetime;
                }

                return (
                    <Grid sx={{pt: 0, m: '0'}}>
                        {actualReturnOriginDatetime !== null ?
                            actualReturnOriginDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'actualReturnDocDatetime',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันเวลาคืนเอกสาร',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                let actualReturnDocDatetime = null;
                if (params.row.actualReturnDocDatetime) {
                    actualReturnDocDatetime = params.row.actualReturnDocDatetime;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: '#6F9EE8'}}>
                        {actualReturnDocDatetime !== null ?
                            actualReturnDocDatetime
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'etaJourney', headerClassName: 'super-app-theme--header',
            headerName: 'ETA Journey',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'ETA',
            headerClassName: 'super-app-theme--header',
            headerName: 'ETA',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let ETA = null;
                if (params.row.ETA) {
                    ETA = params.row.ETA;
                }

                let actualArrivalDestDatetime = null;
                if (params.row.actualArrivalDestDatetime) {
                    actualArrivalDestDatetime = params.row.actualArrivalDestDatetime;
                }

                let colorETA = null;
                if (params.row.colorETA) {
                    colorETA = params.row.colorETA;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: colorETA}}>
                        {ETA !== null ?
                            ETA
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'mpUpdateDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'อัพเดทเมื่อเวลา',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let mpUpdateDate = null;
                if (params.row.mpUpdateDate) {
                    mpUpdateDate = params.row.mpUpdateDate;
                }

                let colorMP = null;
                if (params.row.colorMP) {
                    colorMP = params.row.colorMP;
                }
                return (
                    <Grid sx={{pt: 0, m: '0', color: colorMP}}>
                        {mpUpdateDate !== null ?
                            mpUpdateDate
                            :
                            null
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'range_from_destination', headerClassName: 'super-app-theme--header',
            headerName: 'ระยะทางถึงสาขา',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'truck_status', headerClassName: 'super-app-theme--header',
            headerName: 'สถานะรถ',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'trackingTruck', headerClassName: 'super-app-theme--header',
            headerName: 'ติดตามโดยทะเบียน',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            editable: true
        }
    ];

    function removeDuplicates(array) {
        return Array.from(new Set(array));
    }

    const [filterBuField, setFilterBuField] = useState([]);

    const [init, setInit] = useState('Y');
    const [trigger, setTrigger] = useState('N');
    const [year, setYear] = useState(() => {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'Asia/Bangkok',
        };
        const thaiLocale = 'en-GB';
        const currentDate = new Date().toLocaleDateString(thaiLocale, options);
        const dateParts = currentDate.split('/');
        const year = parseInt(dateParts[2], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Month is zero-based
        const day = parseInt(dateParts[0], 10);
        return new Date(year, month, day);
    });

    // const optionsCheckStatus = [
    //     {id: 1, name: 'Approved'},
    //     {id: 2, name: 'Arrived DC'},
    //     {id: 3, name: 'Dispatch'},
    //     {id: 4, name: 'Delivered'},
    //     {id: 5, name: 'Departed Store'},
    //     {id: 6, name: 'Return Origin'},
    // ]

    // const optionsSubGroupStatus = [
    //     {id: 1, name: 'Normal'},
    //     {id: 2, name: 'NearDeal'},
    //     {id: 3, name: 'OverDeal'},
    //     {id: 4, name: 'OnTime'},
    //     {id: 5, name: 'Delay'},
    // ]

    const optionsCheckStatus = [
        "Approved",
        "Arrived DC",
        "Dispatch",
        "Delivered",
        "Departed Store",
        "Return Origin"
    ]

    const optionsSubGroupStatus = [
        "Normal",
        "NearDeal",
        "OverDeal",
        "OnTime",
        "Delay"
    ]

    const [option, setOption] = useState({
        optionStatus:
            profiles.bu.includes('ALL NOW COMPLEX')
                ?
                [
                    {id: 1, name: 'Waiting'},
                    {id: 2, name: 'Allocated'},
                    {id: 14, name: 'Reject'},
                    {id: 3, name: 'Approved'},
                    {id: 15, name: 'Splited'},
                    {id: 4, name: 'Loading'},
                    {id: 5, name: 'Send to TMS'},
                    {id: 19, name: 'Send to LOC'},
                    {id: 6, name: 'Done'},
                    {id: 7, name: 'Fail'},
                    {id: 8, name: 'Arrived DC'},
                    {id: 9, name: 'Dispatch'},
                    {id: 11, name: 'Delivered'},
                    {id: 10, name: 'Departed Store'},
                    {id: 12, name: 'Return Origin'},
                    {id: 13, name: 'Cancel'},
                    {id: 16, name: 'DepartToBackhaul'},
                    {id: 17, name: 'ArrivedAtBackhaul'},
                    {id: 18, name: 'DepartFromBackhaul'}
                ]
                :
                [
                    {id: 1, name: 'Waiting'},
                    {id: 2, name: 'Allocated'},
                    {id: 14, name: 'Reject'},
                    {id: 3, name: 'Approved'},
                    {id: 15, name: 'Splited'},
                    {id: 4, name: 'Send to TMS'},
                    {id: 19, name: 'Send to LOC'},
                    {id: 5, name: 'Done'},
                    {id: 6, name: 'Fail'},
                    {id: 7, name: 'Arrived DC'},
                    {id: 8, name: 'Dispatch'},
                    {id: 9, name: 'Delivered'},
                    {id: 10, name: 'Departed Store'},
                    {id: 11, name: 'Return Origin'},
                    {id: 12, name: 'Cancel'},
                    {id: 16, name: 'DepartToBackhaul'},
                    {id: 17, name: 'ArrivedAtBackhaul'},
                    {id: 18, name: 'DepartFromBackhaul'}
                ]
        ,
        optionTrackingStatus: [
            {id: 1, name: 'OnTime'},
            {id: 2, name: 'Delay'}
        ],
        optionOrderType: [
            {id: 1, name: 'Dry'},
            {id: 2, name: 'Fresh'},
            {id: 3, name: 'Frozen'},
            {id: 4, name: 'Chill'},
            {id: 5, name: 'Air Condition'},
        ],
        optionSite: [],
        optionSub: [],
    })

    const [subSelect, setSubSelect] = useState({
        sub: [],
        subSelectedValue: []
    })

    const [statusOrder, setStatusOrder] = useState(null)

    const [search, setSearch] = useState({
        submitDate: dayjs(Date.now()),
        submitDateTo: dayjs(Date.now()),
        // destinationExpectedDeliveryDate: null,
        trackingNo: '',
        customerName: profiles.roles[0] !== 'ROLE_SUPER_ADMIN' ? profiles.bu[0] : '',
        // trackingStatus: searchParams.get("trackingStatus") ? [searchParams.get("trackingStatus")] : ["OnTime", "Delay"],
        status: searchParams.get("status") ? [searchParams.get("status")] : (profiles.bu.includes('ALL NOW COMPLEX') ?
            // ["Waiting", "Allocated", "Reject", "Approved", "Splited", "Loading", "Send to TMS", "Done", "Fail", "Arrived DC", "Dispatch", "Delivered", "Departed Store", "DepartToBackhaul", "Return Origin", "Cancel"] :
            // ["Waiting", "Allocated", "Reject", "Approved", "Splited", "Send to TMS", "Done", "Fail", "Arrived DC", "Dispatch", "Delivered", "Departed Store", "DepartToBackhaul", "Return Origin", "Cancel"]),
            // ###### Disable Backhaul
            ["Waiting", "Allocated", "Reject", "Approved", "Splited", "Loading", "Send to TMS", "Send to LOC", "Done", "Fail", "Arrived DC", "Dispatch", "Delivered", "Departed Store", "DepartToBackhaul", "ArrivedAtBackhaul", "DepartFromBackhaul", "Return Origin", "Cancel"] :
            ["Waiting", "Allocated", "Reject", "Approved", "Splited", "Send to TMS", "Send to LOC", "Done", "Fail", "Arrived DC", "Dispatch", "Delivered", "Departed Store", "DepartToBackhaul", "ArrivedAtBackhaul", "DepartFromBackhaul", "Return Origin", "Cancel"]),
        destination: [],
        origin: [],
        orderType: [],
        fileName: null,
        actualVendorCode: null,
        originSelectedValue: [],
        destinationSelectedValue: [],
        planPreCoolDate: null, // Lotus precool
        planPreCoolDateTo: null, // Lotus precool
        originExpectedPickupDate: null,  //วันเวลารถจะเข้ารัวันเวลาจ่ายงาน
        originPlanPickupDate: null,  //วันเวลารถจะเข้ารับเอกสาร
        originPlanPickupDateTo: null,  //วันเวลารถจะเข้ารับเอกสาร
        destinationExpectedDeliveryDate: null,  //วันเวลานัดส่งสาขา
        destinationExpectedDeliveryDateTo: null,  //วันเวลานัดส่งสาขา
        orderDate: null,
        orderDateTo: null,
        // status:"ทั้งหมด",
        operationApprove: null,
        vendorConfirm: null,
        actualVehicleNumberPlate: '',
        trailerNo: '',
        actualArrivalOriginDatetime: null,
        actualPickupDatetime: null,
        actualDepartedOriginDatetime: null,
        actualArrivalDestDatetime: null,
        actualDepartedDestDatetime: null,
        actualReturnDocDatetime: null,
        actualReturnOriginDatetime: null,
        lastDispatchDate: null,
        theLastDispatchMinus2H: null,
        theLastDispatchMinus4H: null,
        ETA: null,
        range_from_destination: null,
        truck_status: null,
        colorETA: null,
        colorMP: null,
        mpUpdateDate: null,
        makroPlan: 0,
        truckType: [],
        checkStatus: null,
        subGroupStatus: null
    });

    const [masterBh, setMasterBh] = useState([]);
    const [dataListConfirm, setDataListConfirm] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [masterData, setMasterData] = useState({
        storeSub: null,
        optionReason: [],
        vendor: [],
        masterBu: []
    });

    const [listDelayReason, setListDelayReason] = useState([]);
    const [listTruckType, setListTruckType] = useState([]);

    const [dialog, setDialog] = useState({
        dialog: false,
        mode: null,
        optionReason: [],
        rowsId: null,
        reasonId: null,
        text: '',
    });

    const [dialogSpecial, setDialogSpecial] = useState({
        dialog: false,
        mode: null,
        shipmentId: null,
        shipmentBackhualId: null,
        subType: null,
        remark: '',
        image: [],
        count: 2,
        orderStatus: null,
        tmsOrderNo: null,
        result: [
            {
                id: 1,
                shipmentDetailId: null,
                site: null,
                pickupTime: null,
                dropTime: null,
            },
            {
                id: 2,
                shipmentDetailId: null,
                site: null,
                pickupTime: null,
                dropTime: null,
            }
        ],
        lastOrderOfLinehaul: null,

        backhual: [
            {
                id: 1,
                site: null,
                time: null,
                titleSite: 'ต้นทาง',
                titleTime: 'เวลารับ',
            },
            {
                id: 2,
                site: null,
                time: null,
                titleSite: 'ปลายทาง',
                titleTime: 'เวลาส่ง',
            }
        ],
    });

    const [statusNum, setStatusNum] = useState({
        Waiting: '-',
        Allocated: '-',
        Approved: '-',
        Loading: '-',
        Done: '-',
        Fail: '-',
        Dispatch: '-',
        Delivered: '-',
        ReturnOrigin: '-',
        total: '-',
        ArrivedDC: '-',
        DepartedStore: "-"
    });

    const mapType = {
        "Waiting": "Waiting",
        "Allocated": "Allocated",
        "Approved": "Approved",
        "Splited": "Splited",
        "Loading": "Loading",
        "Done": "Done",
        "Fail": "Fail",
        "ArrivedDC": "Arrived DC",
        "Dispatch": "Dispatch",
        "DepartedStore": "Departed Store",
        "Delivered": "Delivered",
        "ReturnOrigin": "Return Origin",
    }

    function genFilterUrl() {
        let newSearch = search
        search.waitingStatus = undefined
        search.completeStatus = undefined

        // Save local storage
        const jsonStr = JSON.stringify(newSearch);
        const base64 = stringToBase64(jsonStr)
        localStorage.setItem("list_order_filter", base64);
        setFilter(base64)

        const currentURL = new URL(window.location.href);
        const originUrl = currentURL.origin + currentURL.pathname

        console.log("==== TEST DATA => ", window.location)
        window.history.pushState({}, '', (originUrl + "?order_filter=" + encodeURIComponent(base64)));

        // console.log("===== Test debug filter order list => ", filterValue)
        // console.log("===== Test debug filter order list decode => ", JSON.parse(decodeValue))
    }

    async function fetchStatus(request) {
        try {
            let res = await ApiOrderManagement.getReport({
                bu: request.customerName === null || request.customerName === "" || (request.customerName?.length ?? 0) <= 0 ? (profiles.bu === null ? null : profiles.bu) : [request.customerName],
                date: request.submitDate ? new Date(request.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                originExpectedPickupDate: request.originExpectedPickupDate ? new Date(request.originExpectedPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                originPlanPickupDate: request.originPlanPickupDate ? new Date(request.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                destinationExpectedDeliveryDate: request.destinationExpectedDeliveryDate ? new Date(request.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                dateTo: request.submitDateTo ? new Date(request.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                originPlanPickupDateTo: request.originPlanPickupDateTo ? new Date(request.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                destinationExpectedDeliveryDateTo: request.destinationExpectedDeliveryDateTo ? new Date(request.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                orderDate: request.orderDate ? new Date(request.orderDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                orderDateTo: request.orderDateTo ? new Date(request.orderDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                init: init,
                trackingNo: request.trackingNo,
                origin: request.origin,
                destination: request.destination,
                actualVendorCode: subSelect.sub,
                status: request.status,
                // trackingStatus: request.trackingStatus,
                waitingStatus: request.waitingStatus,
                completeStatus: request.completeStatus,
                checkStatus: request.checkStatus,
                actualVehicleNumberPlate: request.actualVehicleNumberPlate,
                trailerNo: request.trailerNo,
                orderType: request.orderType,
                fileName: request.fileName,
                planPreCoolDate: request.planPreCoolDate,
                planPreCoolDateTo: request.planPreCoolDateTo
            });

            if (res.data) {
                return res.data
            }
        } catch (e) {
            console.log('Error => ', e)
            return null
        }
    }

    const fetchData = async (init, request) => {
        if (init === 'Y') {
            setIsLoading(true)
        }

        setIsProgress(true)

        let res = await ApiOrderManagement.search({
            date: request.submitDate ? new Date(request.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            dateTo: request.submitDateTo ? new Date(request.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            originExpectedPickupDate: request.originExpectedPickupDate ? new Date(request.originExpectedPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            originPlanPickupDate: request.originPlanPickupDate ? new Date(request.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            originPlanPickupDateTo: request.originPlanPickupDateTo ? new Date(request.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            destinationExpectedDeliveryDate: request.destinationExpectedDeliveryDate ? new Date(request.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            destinationExpectedDeliveryDateTo: request.destinationExpectedDeliveryDateTo ? new Date(request.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            orderDate: request.orderDate ? new Date(request.orderDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            orderDateTo: request.orderDateTo ? new Date(request.orderDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            trackingNo: request.trackingNo,
            customerName: profiles.roles[0] === 'ROLE_BU' ? profiles.data.customerName : request.customerName,
            status: request.status,
            portal: 'cmp',
            // bu: JSON.parse(localStorage.getItem("profile")).bu,
            page: page,
            limit: rowsPerPage,
            origin: request.origin,
            destination: request.destination,
            // planedVendorCode: search.sub.length > 0 ? search.sub : null,
            actualVendorCode: subSelect.sub,
            operationApprove: request.operationApprove ? new Date(request.operationApprove).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            vendorConfirm: request.vendorConfirm ? new Date(request.vendorConfirm).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            actualVehicleNumberPlate: request.actualVehicleNumberPlate,
            trailerNo: request.trailerNo,
            orderType: request.orderType,
            init: init,
            makroPlan: request.makroPlan,
            truckType: request.truckType,
            // trackingStatus: request.trackingStatus,
            // waitingStatus: request.waitingStatus,
            // completeStatus: request.completeStatus,
            checkStatus: request.checkStatus,
            subGroupStatus: request.subGroupStatus,
            currentSearchStatus: request.currentSearchStatus,
            fileName: request.fileName,
            planPreCoolDate: request.planPreCoolDate,
            planPreCoolDateTo: request.planPreCoolDateTo,
        });
        let vendorCodeSet = [];
        let uniqueEntries = [];
        let uniqueKeys = new Set();

        console.log("===== Debug res => ", init, res)

        if (res.status === 200) {
            if (res.data.listData.length > 0) {
                if (res.data?.countData) {
                    console.log("===== Debug countData => ", res.data?.countData)

                    setStatusOrder(prevState => ({
                        ...prevState, ...res.data?.countData
                    }))
                }
            } else {
                setStatusOrder(null)
            }
            if (init === 'Y') {
                if (res.data.listDelayReason) {
                    setListDelayReason(res.data.listDelayReason)
                }
                if (res.data.listTruckType) {
                    setListTruckType(res.data.listTruckType)
                }
                if (res.data.buSite) {
                    let testListBuSite = res.data.buSite;
                    // const namesArray = testListBuSite.map(store => store.name);
                    setOption(prevState => ({
                        ...prevState, ...{
                            optionSite: testListBuSite,
                        }
                    }))
                }
                let testListSub = res.data.listSub;
                if (res.data.listSub) {
                    const uniqueIds = {};
                    const uniqueArray = testListSub.filter((item) => {
                        if (!uniqueIds[item.id]) {
                            uniqueIds[item.id] = true;
                            return true;
                        }
                        return false;
                    });
                    let testListAllSubId = [];
                    let listAllSubId = uniqueArray.map((el) => {
                        if (el.name) {
                            testListAllSubId.push(el.id)
                            return el.id;
                        } else {
                            return null;
                        }
                    })
                    setOption(prevState => ({
                        ...prevState, ...{
                            optionSub: uniqueArray,
                        }
                    }))
                    setSubSelect(search => ({
                        ...search, ...{
                            subSelectedValue: uniqueArray,
                        }
                    }))
                }
            }

            const currentSearchStatus = res.data.currentSearchStatus

            if (currentSearchStatus && search.checkStatus && search.subGroupStatus) {
                setSearch(prevState => ({
                    ...prevState, ...{
                        status: currentSearchStatus,
                    }
                }))
            }

            res = [...res.data.listData].sort((a, b) => {

                if (a.status === 'Allocated' && b.status !== 'Allocated') {
                    return -1; // 'Allocated' objects appear before non-'Allocated' objects
                } else if (a.status !== 'Allocated' && b.status === 'Allocated') {
                    return 1; // Non-'Allocated' objects appear after 'Allocated' objects
                } else if (a.status === 'Approved' && b.status !== 'Approved') {
                    return -1; // 'Approved' objects appear before non-'Approved' objects
                } else if (a.status !== 'Approved' && b.status === 'Approved') {
                    return 1; // Non-'Approved' objects appear after 'Approved' objects
                } else if (a.score < b.score) {
                    return -1;
                } else if (a.score > b.score) {
                    return 1;
                } else {
                    // If neither 'Allocated' nor 'Approved', sort by "originPlanPickupDateTime"
                    return a.trickingNo?.localeCompare(b.trickingNo ?? "");
                }

            });

            res = res.map(({
                               lastDispatchDate,
                               isExtraOrderReady,
                               isBackhaulReady,
                               score,
                               diffTimeText,
                               color,
                               diffTime,
                               id,
                               fileResultId,
                               originSiteId,
                               originSiteName,
                               destinationSiteName,
                               destinationSiteId,
                               fileResultFileName,
                               status,
                               orderType,
                               date,
                               trickingNo,
                               planedVehicleNumberPlate,
                               planedDriverName,
                               planedVendorCode,
                               planedVendorName,
                               actualVendorCode,
                               actualVendorName,
                               originExpectedArrivalDate,
                               originExpectedPickupDate,
                               destinationExpectedDeliveryDate,
                               customerName,
                               trailerNo,
                               originPlanPickupDate,
                               remark,
                               drops,
                               importDate,
                               createdBy,
                               tmsOrderNo,
                               actualVehicleNumberPlate,
                               actualDriverName,
                               actualDriverName2,
                               tmsError,
                               isFullSet,
                               makroSubDeliveryType,
                               isReadyForApprove,
                               backhual,
                               operationApprove,
                               vendorConfirm,
                               actualArrivalOriginDatetime,
                               actualPickupDatetime,
                               actualDepartedOriginDatetime,
                               actualArrivalDestDatetime,
                               actualDepartedDestDatetime,
                               actualReturnDocDatetime,
                               actualReturnOriginDatetime,
                               theLastDispatchMinus2H,
                               theLastDispatchMinus4H,
                               colorSubConfirmDelay,
                               subConfirmDelayReason,
                               trackingTruckRemark,
                               colorDepartOriginDelay,
                               colorArriveDestinationDelay,
                               dispatchDelayReason,
                               dotDelayReason,
                               trackingTruckRemarkDispatch,
                               trackingTruckRemarkDelivered,
                               driverTel,
                               departOriginStampText,
                               arriveDestinationStampText,
                               roundNo,
                               totalPackage,
                               groupPackage,
                               countTrackingTrackStatus,
                               customerLineCode,
                               changedVehicleNumberPlateReason,
                               ETA,
                               range_from_destination,
                               truck_status,
                               colorETA,
                               colorMP,
                               mpUpdateDate,
                               colorActualReturnOriginDelay,
                               planReturnOriginDatetime,
                               assignDriverToAnny,
                               tripNo,
                               warningVehicle,
                               warningDriver,
                               trackingTruck,
                               truckType,
                               gpsActualArrivalOriginDatetime,
                               gpsActualDepartedOriginDatetime,
                               gpsActualArrivalDestDatetime,
                               gpsActualDepartedDestDatetime,
                               gpsActualReturnOriginDatetime,
                               gpsOrderStatus,
                               truckLocation,
                               tmsJobStatus,
                               temparatureRemark,
                               r4,
                               jobTypeRemark,
                               productRemark,
                               halal,
                               subContractorRemark,
                               truckTemp,
                               buTruckType,
                               no,
                               driverTotal,
                               etaJourney,
                               arrivedBackhaul,
                               googleMap,
                               splitedtrucknum,
                               isSplitApprove,
                               totalDuration,
                               totalDistance,
                               actualTruckProvince,
                               buId
                           }, index
            ) => {

                let colorBackhual = 'rgb(161,161,161)'
                let colorExtraOrder = 'rgb(161,161,161)'
                let extraOrder = null;

                if (makroSubDeliveryType) {
                    if (makroSubDeliveryType.includes('BH') && isBackhaulReady === false) {
                        colorBackhual = '#D32F2F'
                    } else if (makroSubDeliveryType.includes('BH') && isBackhaulReady === true) {
                        // check ack sub ต่อ
                        colorBackhual = '#69C361'
                    }

                    if (makroSubDeliveryType.includes('BuddyMart')) {
                        extraOrder = 'BuddyMart';

                    } else if (makroSubDeliveryType.includes('กาชาด')) {
                        extraOrder = 'กาชาด';

                    } else if (makroSubDeliveryType.includes('ราชประชา')) {
                        extraOrder = 'ราชประชา';
                    }

                    if (extraOrder) {
                        if (isExtraOrderReady === false) {
                            colorExtraOrder = '#D32F2F';
                        } else if (isExtraOrderReady === true) {
                            colorExtraOrder = '#69C361'
                        }
                    }
                }

                return {
                    buId: buId,
                    lastDispatchDate: lastDispatchDate,
                    extraOrder: extraOrder,
                    isExtraOrderReady: isExtraOrderReady,
                    isBackhaulReady: isBackhaulReady,
                    score: score,
                    diffTimeText: diffTimeText,
                    color: color,
                    diffTime: diffTime,
                    id: id,
                    fileResultId: fileResultId,
                    originSiteId: originSiteId,
                    originSiteName: originSiteName,
                    destinationSiteId: destinationSiteId,
                    destinationSiteName: destinationSiteName,
                    fileResultFileName: fileResultFileName,
                    status: status,
                    orderType: orderType,
                    date: date,
                    trickingNo: trickingNo,
                    originExpectedArrivalDate: originExpectedArrivalDate,
                    originExpectedPickupDate: originExpectedPickupDate,
                    destinationExpectedDeliveryDate: destinationExpectedDeliveryDate,
                    customerName: customerName,
                    trailerNo: trailerNo,
                    planedDriverName: planedDriverName,
                    oldVehicleNumberPlate: planedVehicleNumberPlate,
                    oldPlanedDriverName: planedDriverName,
                    oldVendorCode: planedVendorCode,
                    oldVendorName: planedVendorName,
                    confirmVendorCode: planedVendorCode,
                    confirmVendorName: planedVendorName,
                    confirmVehicleNumberPlate: planedVehicleNumberPlate,
                    truckOptions: [],
                    truckOpen: false,
                    truckLoading: false,
                    // Remove for loc คุยกับพี่ก๊อฟ
                    // truckSelectedValue: planedVehicleNumberPlate !== null ? {
                    //     id: 1,
                    //     name: planedVehicleNumberPlate
                    // } : null,
                    vendorOpen: false,
                    vendorLoading: false,
                    vendorSelectedValue: planedVendorName ? {id: 1, name: planedVendorName} : null,
                    reasonId: null,
                    originPlanPickupDate: originPlanPickupDate,
                    actualVendorName: actualVendorName,
                    remark: remark,
                    drops: drops,
                    importDate: importDate,
                    createdBy: createdBy,
                    tmsOrderNo: tmsOrderNo,
                    actualVehicleNumberPlate: actualVehicleNumberPlate,
                    actualTruckProvince: actualTruckProvince,
                    actualDriverName: actualDriverName,
                    actualDriverName2: actualDriverName2,
                    tmsError: tmsError,
                    isFullSet: isFullSet,
                    makroSubDeliveryType: makroSubDeliveryType,
                    isReadyForApprove: isReadyForApprove,
                    backhual: backhual,
                    colorBackhual: colorBackhual,
                    colorExtraOrder: colorExtraOrder,
                    operationApprove: operationApprove,
                    vendorConfirm: vendorConfirm,
                    actualArrivalOriginDatetime: actualArrivalOriginDatetime,
                    actualPickupDatetime: actualPickupDatetime,
                    actualDepartedOriginDatetime: actualDepartedOriginDatetime,
                    actualArrivalDestDatetime: actualArrivalDestDatetime,
                    actualDepartedDestDatetime: actualDepartedDestDatetime,
                    actualReturnDocDatetime: actualReturnDocDatetime,
                    actualReturnOriginDatetime: actualReturnOriginDatetime,
                    theLastDispatchMinus2H: theLastDispatchMinus2H,
                    theLastDispatchMinus4H: theLastDispatchMinus4H,
                    colorSubConfirmDelay: colorSubConfirmDelay,
                    subConfirmDelayReason: subConfirmDelayReason,
                    trackingTruckRemark: trackingTruckRemark,
                    colorDepartOriginDelay: colorDepartOriginDelay,
                    colorArriveDestinationDelay: colorArriveDestinationDelay,
                    dispatchDelayReason: dispatchDelayReason,
                    dotDelayReason: dotDelayReason,
                    trackingTruckRemarkDispatch: trackingTruckRemarkDispatch,
                    trackingTruckRemarkDelivered: trackingTruckRemarkDelivered,
                    driverTel: driverTel,
                    departOriginStampText: departOriginStampText,
                    arriveDestinationStampText: arriveDestinationStampText,
                    ETA: ETA,
                    range_from_destination: range_from_destination,
                    truck_status: truck_status,
                    colorETA: colorETA,
                    colorMP: colorMP,
                    mpUpdateDate: mpUpdateDate,
                    roundNo: roundNo,
                    totalPackage: totalPackage,
                    groupPackage: groupPackage,
                    countTrackingTrackStatus: countTrackingTrackStatus,
                    customerLineCode: customerLineCode,
                    changedVehicleNumberPlateReason: changedVehicleNumberPlateReason,
                    colorActualReturnOriginDelay: colorActualReturnOriginDelay,
                    planReturnOriginDatetime: planReturnOriginDatetime,
                    assignDriverToAnny: assignDriverToAnny,
                    tripNo: tripNo,
                    warningVehicle: warningVehicle,
                    warningDriver: warningDriver,
                    trackingTruck: trackingTruck,
                    truckType: truckType,
                    gpsActualArrivalOriginDatetime: gpsActualArrivalOriginDatetime,
                    gpsActualDepartedOriginDatetime: gpsActualDepartedOriginDatetime,
                    gpsActualArrivalDestDatetime: gpsActualArrivalDestDatetime,
                    gpsActualDepartedDestDatetime: gpsActualDepartedDestDatetime,
                    gpsActualReturnOriginDatetime: gpsActualReturnOriginDatetime,
                    gpsOrderStatus: gpsOrderStatus,
                    truckLocation: truckLocation,
                    tmsJobStatus: tmsJobStatus,
                    temparatureRemark: temparatureRemark,
                    r4: r4,
                    jobTypeRemark: jobTypeRemark,
                    productRemark: productRemark,
                    halal: halal,
                    subContractorRemark: subContractorRemark,
                    truckTemp: truckTemp,
                    actualVendorCode: actualVendorCode,
                    buTruckType: buTruckType,
                    no: no,
                    driverTotal: driverTotal,
                    etaJourney: etaJourney,
                    arrivedBackhaul: arrivedBackhaul,
                    googleMap: googleMap,
                    splitedtrucknum: splitedtrucknum,
                    isSplitApprove: isSplitApprove,
                    totalDuration: totalDuration,
                    totalDistance: totalDistance
                }
            });

            // console.log("Debug rows data =>", res);
            setRows(res)
        }

        if (init === 'Y') {
            let resMasterVendor = await ApiOrderManagement.masterBu();
            if (resMasterVendor) {
                setMasterData(
                    masterData => ({
                        ...masterData,
                        ...{
                            masterBu: resMasterVendor.data,
                        }
                    })
                )
            }

            let resStoreBuSite = await ApiOrderManagement.getStoreBu();
            if (resStoreBuSite) {
                setMasterData(
                    masterData => ({
                        ...masterData,
                        ...{
                            storeSub: resStoreBuSite.data,
                        }
                    })
                )
            }

            let resReasons = await ApiOrderManagement.getReasonCMP({catagories: 'ALLOCATED_VENDOR_CANCEL'});
            if (resReasons) {
                resReasons = resReasons.data.map(({id, description, catagories, vendorCode, code, status}) => {
                    return {
                        id: id,
                        description: description,
                        catagories: catagories,
                        vendorCode: vendorCode,
                        code: code,
                        status: status,
                        label: code + '-' + description
                    }
                });
                setMasterData(
                    masterData => ({
                        ...masterData,
                        ...{
                            optionReason: resReasons,
                        }
                    })
                )
            }
        }

        setDataListConfirm([])
        setRowSelectionModel([])
        setIsLoading(false)
        setIsProgress(false)
    };

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            // console.log(newRow)
            const response = await ApiOrderManagement.editTimeStamp({
                id: newRow.id,
                actualArrivalOriginDatetime: newRow.actualArrivalOriginDatetime,
                actualPickupDatetime: newRow.actualPickupDatetime,
                actualDepartedOriginDatetime: newRow.actualDepartedOriginDatetime,
                actualArrivalDestDatetime: newRow.actualArrivalDestDatetime,
                actualDepartedDestDatetime: newRow.actualDepartedDestDatetime,
                actualReturnDocDatetime: newRow.actulReturnDocDatetime,
                actualReturnOriginDatetime: newRow.actualReturnOriginDatetime,
                lastDispatchDate: newRow.lastDispatchDate,
                subConfirmDelayReason: newRow.subConfirmDelayReason,
                trackingTruckRemark: newRow.trackingTruckRemark,
                dispatchDelayReason: newRow.dispatchDelayReason,
                dotDelayReason: newRow.dotDelayReason,
                trackingTruckRemarkDispatch: newRow.trackingTruckRemarkDispatch,
                trackingTruckRemarkDelivered: newRow.trackingTruckRemarkDelivered,
                tripNo: newRow.tripNo,
                updateBy: JSON.parse(localStorage.getItem("profile")).userId,
                trackingTruck: newRow.trackingTruck,
                orderType: newRow.orderType,
                truckType: newRow.truckType,
                buTruckType: newRow.buTruckType
            });

            console.log(response)

            if (response.status == 200) {
                if (newRow.subConfirmDelayReason === 'ลบข้อมูล') {
                    newRow.subConfirmDelayReason = '';
                }

                if (newRow.dispatchDelayReason === 'ลบข้อมูล') {
                    newRow.dispatchDelayReason = '';
                }

                if (newRow.dotDelayReason === 'ลบข้อมูล') {
                    newRow.dotDelayReason = '';
                }

                fetchStatus(search)
                    .then((data) => {
                        setStatusNum(data)
                    })

                await fetchData('N', search).catch((e) => {
                    console.log(e);
                    setIsLoading(false);
                    setIsProgress(false);
                });

                return newRow;
            } else {
                Swal.fire({
                    title: 'แก้ไขไม่สำเร็จ',
                    text: response.response?.data?.errorMessage,
                    icon: 'error',
                    showConfirmButton: false,
                })

                fetchStatus(search)
                    .then((data) => {
                        setStatusNum(data)
                    })

                await fetchData('N', search).catch((e) => {
                    console.log(e);
                    setIsLoading(false);
                    setIsProgress(false);
                });

                setDataKey(prevKey => prevKey + 1);

                return false;
            }
        },
        [fetchData],
    );

    const DrawerBtn = () => {
        setDrawerOpenBtn(!drawerOpenBtn)
        if (drawerOpenBtn === false) {
            setDrawer('block')
        }
        if (drawerOpenBtn === true) {
            setDrawer('none')
        }
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
        const dataSearch = {
            date: null,
            fileName: '',
            status: null,
            page: page,
            limit: rowsPerPage,
        }
    };

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        const dataSearch = {
            date: null,
            fileName: '',
            status: null,
            page: page,
            limit: rowsPerPage,
        }
    };

    const closeDialog = () => {
        setDialog(
            dialog => ({
                ...dialog,
                ...{
                    dialog: false,
                    mode: null,
                    text: '',
                }
            })
        )
    }

    const closeDialogSpecial = () => {
        console.log('dialogSpecial')
        console.log(dialogSpecial)
        setDialogSpecial(
            dialogSpecial => ({
                ...dialogSpecial,
                ...{
                    dialog: false,
                    mode: null,
                    shipmentId: null,
                    subType: null,
                    remark: '',
                    image: [],
                    count: 2,
                    orderStatus: null,
                    tmsOrderNo: null,
                    result: [
                        {
                            id: 1,
                            shipmentDetailId: null,
                            site: null,
                            pickupTime: null,
                            dropTime: null,
                        },
                        {
                            id: 2,
                            shipmentDetailId: null,
                            site: null,
                            pickupTime: null,
                            dropTime: null,
                        }
                    ],
                    lastOrderOfLinehaul: null,
                    backhual: [
                        {
                            id: 1,
                            site: null,
                            time: null,
                            titleSite: 'ต้นทาง',
                            titleTime: 'เวลารับ',
                        },
                        {
                            id: 2,
                            site: null,
                            time: null,
                            titleSite: 'ปลายทาง',
                            titleTime: 'เวลาส่ง',
                        }
                    ],
                }
            })
        )
    }

    function removeResultById(idToRemove) {
        setDialogSpecial((prevState) => {
            const updatedResult = prevState.result.filter((item) => item.id !== idToRemove);
            return {
                ...prevState,
                result: updatedResult,
            };
        });
    }

    const confirmOrder = async (data) => {
        setIsProgress(true)
        dataListConfirm.forEach(order => {
            if ((order.oldVendorCode !== null) && (order.oldVendorCode !== order.confirmVendorCode) && (order.confirmVendorCode !== null)) {
                order.reasonId = null;
            }
            if (order.oldVendorCode && order.oldVendorCode !== order.confirmVendorCode) {
                order.confirmVehicleNumberPlate = null;
            }
        });
        const ordersWithNullPieceNumber = dataListConfirm.filter(order => order.confirmVendorCode === null);
        if (ordersWithNullPieceNumber.length > 0) {
            alert("กรุณาเลือก ชื่อคู่ค้า");
        } else {
            let dataLog = dataListConfirm.map((e) => {
                if (e.confirmVendorCode && (e.oldVendorCode !== e.confirmVendorCode)) {
                    return {
                        shipmentOrderId: e.id,
                        status: '2',
                        subconstractorCode: e.confirmVendorCode,
                        subconstractorName: e.confirmVendorName,
                        vehicleNumberPlate: e.confirmVehicleNumberPlate,
                        driverName: e.planedDriverName,
                        createdBy: JSON.parse(localStorage.getItem("profile")).userId,
                    }
                }
            })
            console.log('dataLog')
            dataLog = dataLog.filter(Boolean);
            console.log(dataLog)
            if (dataLog.length > 0) {
                console.log('API LOG')
                let resLog = await ApiLog.log({
                    requests: dataLog !== null ? dataLog : [],
                });
            } else {
                console.log('not API LOG')
            }

            setTrigger('N')
            setInit('N')
            // let resConfirmOrder = await ApiOrderManagement.confirmVendor(dataListConfirm);
            let resConfirmOrder = await ApiOrderManagement.confirmVendor({
                listData: dataListConfirm,
                updateBy: JSON.parse(localStorage.getItem("profile")).userId
            });
            if (resConfirmOrder.status === 200) {
                console.log(resConfirmOrder)
                Swal.fire({
                    title: 'ยืนยัน vendor สำเร็จ',
                    icon: 'success',
                    showConfirmButton: false,
                }).then(() => {
                    fetchStatus(search)
                        .then((data) => {
                            setStatusNum(data)
                        })

                    fetchData('N', search).catch((e) => {
                        console.log(e)
                        setIsLoading(false)
                        setIsProgress(false)
                    })
                    setTrigger('Y')
                })
            }

            const response = resConfirmOrder?.response
            if (response.status == 400) {
                let messageSwal = ""
                if (response.data?.error == "cant_not_approve") {
                    messageSwal = "ไม่สามารถทำการยื่นยันได้เนื่องจากรายการที่เลือกอนุมัติไปแล้ว"
                }
                Swal.fire({
                    title: 'แจ้งเตือน',
                    text: messageSwal,
                    icon: "error",
                    showConfirmButton: false,
                });
            }
            
        }
        setIsProgress(false)
    };

    async function cancelOrder(soId, soStatus, userId) {
        setIsProgress(true);
        let res = await ApiOrderManagement.cancelOrderByOps({soId: soId, soStatus: soStatus, userId: userId});
        if (res.status === 200) {
        } else {
        }

        fetchStatus(search)
            .then((data) => {
                setStatusNum(data)
            })

        fetchData('N', search).catch((e) => {
            console.log(e)
            setIsLoading(false)
            setIsProgress(false)
        })
        setIsProgress(false);
    }

    async function updateExtraOrder(action) {
        setIsProgress(true)
        let resExtraOrder = null;
        if (action === 'edit') {
            let route = dialogSpecial.result.map((el) => {
                console.log('el')
                console.log(el)
                return {
                    origin: {
                        id: dialogSpecial.lastOrderOfLinehaul.origin.id,
                        time: new Date(el.pickupTime).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'})
                    },
                    destination: {
                        id: el.site.id,
                        time: new Date(el.dropTime).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'})
                    }
                }
            })
            route.push(dialogSpecial.lastOrderOfLinehaul)
            console.log('route')
            console.log(route)
            let dataUpsert = {
                orderId: dialogSpecial.shipmentId,
                makroSubDeliveryType: dialogSpecial.subType,
                remark: dialogSpecial.remark,
                createBy: JSON.parse(localStorage.getItem("profile")).userId,
                route: route,
                action: 'edit',
            }
            console.log('dataUpsert')
            console.log(dataUpsert)
            resExtraOrder = await ApiOrderManagement.setExtraOrder(dataUpsert)
        } else if (action === 'cancel') {
            let dataUpsert = {
                orderId: dialogSpecial.shipmentId,
                remark: dialogSpecial.remark,
                createBy: JSON.parse(localStorage.getItem("profile")).userId,
                route: [dialogSpecial.lastOrderOfLinehaul],
                action: 'cancel',
            }
            console.log('dataCancel')
            console.log(dataUpsert)
            resExtraOrder = await ApiOrderManagement.setExtraOrder(dataUpsert)
        }
        // setIsProgress(false)
        if (resExtraOrder) {
            if (resExtraOrder.status === 200) {
                closeDialogSpecial()
                Swal.fire({
                    title: 'อัพเดตสำเร็จ',
                    icon: 'success',
                    showConfirmButton: false,
                }).then(async () => {
                    fetchStatus(search)
                        .then((data) => {
                            setStatusNum(data)
                        })

                    await fetchData('N', search).catch((e) => {
                        console.log(e)
                        setIsLoading(false)
                        setIsProgress(false)
                    })
                })
            } else {
                setDialogSpecial(
                    dialogSpecial => ({
                        ...dialogSpecial,
                        ...{
                            dialog: false
                        }
                    })
                )
                Swal.fire({
                    title: 'อัพเดตไม่สำเร็จ',
                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                    icon: 'error',
                    showConfirmButton: false,
                }).then(async () => {
                    setDialogSpecial(
                        dialogSpecial => ({
                            ...dialogSpecial,
                            ...{
                                dialog: true
                            }
                        })
                    )
                });
            }
        }
    }

    function checkOriginHaveSiteMakro() {
        if (search.originSelectedValue.length > 0) {
            const validIds = [1, 12, 2333];

            for (const item of search.originSelectedValue) {
                if (!validIds.includes(item.id)) {
                    return false;
                }
            }

            return true;
        } else {
            return false
        }
    }

    function checkIfInvalidOriginSiteMakroIsExist() {
        if (search.originSelectedValue.length > 0) {
            const validIds = [1, 12, 2333];

            for (const item of search.originSelectedValue) {
                if (!validIds.includes(item.id)) {
                    return false;
                }
            }

            return true;
        } else {
            return false
        }
    }

    const buttonDownloadReport = (bu, isCheckOrginSite) => {

        return (
            <>
                {
                    isCheckOrginSite ?
                        <>
                            <Button
                                disabled={isProgress}
                                variant="contained"
                                disableElevation
                                onClick={async () => {
                                    setIsLoading(true);
                                    setIsProgress(true);
                                    let res = await ApiOrderManagement.downloadMakroPlan({
                                        date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        dateTo: search.submitDateTo ? new Date(search.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        originExpectedPickupDate: search.originExpectedPickupDate ? new Date(search.originExpectedPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        originPlanPickupDate: search.originPlanPickupDate ? new Date(search.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        originPlanPickupDateTo: search.originPlanPickupDateTo ? new Date(search.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        destinationExpectedDeliveryDate: search.destinationExpectedDeliveryDate ? new Date(search.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        destinationExpectedDeliveryDateTo: search.destinationExpectedDeliveryDateTo ? new Date(search.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        trackingNo: search.trackingNo,
                                        fileName: search.fileName,
                                        customerName: profiles.roles[0] === 'ROLE_BU' ? profiles.data.customerName : search.customerName,
                                        status: search.status,
                                        portal: 'cmp',
                                        bu: JSON.parse(localStorage.getItem("profile")).bu,
                                        page: page,
                                        limit: rowsPerPage,
                                        origin: search.origin.length > 0 ? search.origin : null,
                                        destination: search.destination.length > 0 ? search.destination : null,
                                        // planedVendorCode: search.sub.length > 0 ? search.sub : null,
                                        actualVendorCode: subSelect.sub.length > 0 ? subSelect.sub : null,
                                        operationApprove: search.operationApprove ? new Date(search.operationApprove).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        vendorConfirm: search.vendorConfirm ? new Date(search.vendorConfirm).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                        actualVehicleNumberPlate: search.actualVehicleNumberPlate,
                                        trailerNo: search.trailerNo,
                                        // trackingStatus: search.trackingStatus,
                                        orderType: search.orderType,
                                        init: null,
                                        deliveryType: bu === 'Makro' ? 'Linehual' : 'Distribution'
                                    });

                                    if (res.status == 200) {
                                        FileDownload(res.data, new Date().toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) + '.xlxs');
                                    }

                                    setIsLoading(false);
                                    setIsProgress(false);
                                }}
                                sx={{
                                    mt: '5px',
                                    mr: '5px',
                                    backgroundColor: '#6F9EE8',
                                    color: '#ffffff',
                                    // width:'100px',
                                    ':hover': {
                                        backgroundColor: '#4380E1',
                                        color: 'white',
                                    },
                                }}
                            >
                                ดาวน์โหลด Mappoint Plan
                            </Button>
                            <Button
                                sx={{
                                    mt: '5px',
                                    mr: '5px',
                                }}
                                disabled={isProgress}
                                variant="outlined"
                                disableElevation
                                onClick={async () => {
                                    setIsProgress(true);
                                    if (bu !== 'CPALL เชียงใหม่' && bu !== 'CPALL นครราชสีมา') {
                                        try {
                                            let res = await ApiOrderManagement.downloadOrderReport(
                                                {
                                                    date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    dateTo: search.submitDateTo ? new Date(search.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    originPlanPickupDate: search.originPlanPickupDate ? new Date(search.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    originPlanPickupDateTo: search.originPlanPickupDateTo ? new Date(search.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    destinationExpectedDeliveryDate: search.destinationExpectedDeliveryDate ? new Date(search.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    destinationExpectedDeliveryDateTo: search.destinationExpectedDeliveryDateTo ? new Date(search.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    trackingNo: search.trackingNo,
                                                    customerName: search.customerName,
                                                    status: search.status,
                                                    origin: search.origin.length > 0 ? search.origin : [],
                                                    destination: search.destination.length > 0 ? search.destination : [],
                                                    planedVendorCode: subSelect.sub.length > 0 ? subSelect.sub : [],
                                                    deliveryType: bu === 'Makro' ? 'Linehual' : 'Distribution'
                                                }
                                            )
                                            if (res.status !== 200) {
                                                setIsLoading(false);
                                                setIsProgress(false);
                                                Swal.fire({
                                                    title: 'ดาวน์โหลดไม่สำเร็จ',
                                                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                });
                                            } else {
                                                console.log('res.data')
                                                console.log(res.data)
                                                FileDownload(res.data, 'export_order.xlsx');
                                            }
                                        } catch (error) {
                                            setIsLoading(false);
                                            setIsProgress(false);
                                            console.error('Error downloading file:', error);
                                        }
                                    } else {
                                        if (search.submitDate) {
                                            let ress = await ApiOrderManagement.downloadFile001(
                                                {
                                                    date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    dateTo: search.submitDateTo ? new Date(search.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    originPlanPickupDate: search.originPlanPickupDate ? new Date(search.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    originPlanPickupDateTo: search.originPlanPickupDateTo ? new Date(search.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    destinationExpectedDeliveryDate: search.destinationExpectedDeliveryDate ? new Date(search.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    destinationExpectedDeliveryDateTo: search.destinationExpectedDeliveryDateTo ? new Date(search.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                    orderType: search.orderType,
                                                    buName: filteredCpAllBuName
                                                }
                                            )
                                            if (ress.status !== 200) {
                                                setIsLoading(false);
                                                setIsProgress(false);
                                                Swal.fire({
                                                    title: 'ดาวน์โหลดไม่สำเร็จ',
                                                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                });
                                            } else {
                                                FileDownload(ress.data, '001.xlsx');
                                            }
                                        } else {
                                            Swal.fire({
                                                title: 'ดาวน์โหลดไม่สำเร็จ',
                                                text: 'กรุณาระบุวันที่อัพโหลด',
                                                icon: 'error',
                                                showConfirmButton: false,
                                            });
                                        }
                                    }


                                    setIsLoading(false);
                                    setIsProgress(false);
                                }}
                            >
                                ดาวน์โหลดเอกสาร LH
                            </Button>

                            {
                                bu === 'Makro' && (
                                    <Button
                                        disabled={isProgress}
                                        variant="outlined"
                                        disableElevation
                                        sx={{
                                            mt: '5px',
                                            mr: '5px',
                                        }}
                                        onClick={async () => {
                                            setIsProgress(true);
                                            try {
                                                let res = await ApiOrderManagement.downloadOrderReport(
                                                    {
                                                        date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                        dateTo: search.submitDateTo ? new Date(search.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                        originPlanPickupDate: search.originPlanPickupDate ? new Date(search.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                        originPlanPickupDateTo: search.originPlanPickupDateTo ? new Date(search.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                        destinationExpectedDeliveryDate: search.destinationExpectedDeliveryDate ? new Date(search.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                        destinationExpectedDeliveryDateTo: search.destinationExpectedDeliveryDateTo ? new Date(search.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                        trackingNo: search.trackingNo,
                                                        customerName: search.customerName,
                                                        status: search.status,
                                                        origin: search.origin.length > 0 ? search.origin : [],
                                                        destination: search.destination.length > 0 ? search.destination : [],
                                                        planedVendorCode: subSelect.sub.length > 0 ? subSelect.sub : [],
                                                        deliveryType: 'Backhaul',
                                                    }
                                                )
                                                if (res.status !== 200) {
                                                    setIsLoading(false);
                                                    setIsProgress(false);
                                                    Swal.fire({
                                                        title: 'ดาวน์โหลดไม่สำเร็จ',
                                                        text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                        icon: 'error',
                                                        showConfirmButton: false,
                                                    });
                                                } else {
                                                    console.log('res.data')
                                                    console.log(res.data)
                                                    FileDownload(res.data, 'export_order.xlsx');
                                                }
                                            } catch (error) {
                                                setIsLoading(false);
                                                setIsProgress(false);
                                                console.error('Error downloading file:', error);
                                            }

                                            setIsLoading(false);
                                            setIsProgress(false);
                                        }}
                                    >
                                        ดาวน์โหลดเอกสาร BH
                                    </Button>

                                )

                            }
                        </> : undefined
                }

                {
                    bu?.includes("Makro") ?
                        <Button
                            sx={{
                                mt: '5px',
                                mr: '5px'
                            }}
                            disabled={isProgress}
                            variant="outlined"
                            disableElevation
                            onClick={async () => {
                                setIsProgress(true);

                                if (search.submitDate) {
                                    let ress = await ApiOrderManagement.downloadFile002(
                                        {
                                            date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                            dateTo: search.submitDateTo ? new Date(search.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                            originPlanPickupDate: search.originPlanPickupDate ? new Date(search.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                            originPlanPickupDateTo: search.originPlanPickupDateTo ? new Date(search.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                            destinationExpectedDeliveryDate: search.destinationExpectedDeliveryDate ? new Date(search.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                            destinationExpectedDeliveryDateTo: search.destinationExpectedDeliveryDateTo ? new Date(search.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                            orderType: search.orderType,
                                            buName: [bu],
                                            orderStatus: search.status
                                        },
                                        bu?.includes("Makro Dry")
                                    )
                                    if (ress.status !== 200) {
                                        setIsLoading(false);
                                        setIsProgress(false);
                                        Swal.fire({
                                            title: 'ดาวน์โหลดไม่สำเร็จ',
                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                            icon: 'error',
                                            showConfirmButton: false,
                                        });
                                    } else {
                                        FileDownload(ress.data, '002.xlsx');
                                    }
                                } else {
                                    Swal.fire({
                                        title: 'ดาวน์โหลดไม่สำเร็จ',
                                        text: 'กรุณาระบุวันที่อัพโหลด',
                                        icon: 'error',
                                        showConfirmButton: false,
                                    });
                                }

                                setIsLoading(false);
                                setIsProgress(false);
                            }}
                        >
                            { !bu?.includes("Makro Dry") ? "Export for Makro F/FZ" : "Export for Makro Dry" }
                        </Button> : undefined

                }

            </>
        )
    }

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (search.customerName) {
            const importAllocation = {
                file: file,
                buName: search.customerName,
                updateBy: JSON.parse(localStorage.getItem("profile")).userId
            }
            /*console.log(importAllocation);
            const formData = new FormData();
            formData.append("file", file);*/
            setIsLoading(true);
            setIsProgress(true);
            try {
                const res = await ApiOrderManagement.importAllocateCpall(importAllocation);
                if (res.data) {
                    FileDownload(res.data, 'respone.xlsx');
                    Swal.fire({
                        title: 'Running Success',
                        icon: 'success',
                        showConfirmButton: false,
                    }).then(() => {
                        fetchStatus(search)
                            .then((data) => {
                                setStatusNum(data)
                            })

                        fetchData('N', search).catch((e) => {
                            console.log(e)
                            setIsLoading(false)
                            setIsProgress(false)
                        })
                    })
                } else {
                    Swal.fire({
                        title: 'Running fail',
                        icon: 'error',
                        showConfirmButton: false,
                    }).then(() => {
                        fetchStatus(search)
                            .then((data) => {
                                setStatusNum(data)
                            })

                        fetchData('N', search).catch((e) => {
                            console.log(e)
                            setIsLoading(false)
                            setIsProgress(false)
                        })
                    })
                }
                setFile(null);
                setIsLoading(false);
                setIsProgress(false);
            } catch (error) {
                console.error(error);
                setFile(null);
                setIsLoading(false);
                setIsProgress(false);
            }
        } else alert('กรุณาเลือก  "ชื่อลูกค้า" ที่ฟิวเตอร์')
    };

    const filteredCpAllBuName = profiles.bu?.filter(item => item?.includes('CPALL'));

    // const [columnVisibility,] = useState(
    //     JSON.parse(localStorage.getItem("column_visibility"))
    // );

    const [dataKey, setDataKey] = useState(0);
    const [columnVisibility, setColumnVisibility] = useState(
        JSON.parse(localStorage.getItem("column_visibility")) || {}
    );

    // const [columnsPosition, setColumnsPosition] = useState(JSON.parse(localStorage.getItem("column_position")) || []);
    const [columnsDataGrid, setColumnsDataGrid] = useState(null)

    const [isLoaded, setIsLoaded] = useState(false)

    function getColumns(value, localColumns) {
        if (localColumns) {
            const merged = [...columns, ...localColumns].reduce((acc, obj) => {
                const existing = acc.find(item => item.field === obj.field);
                if (existing) {
                    // Merge properties if the object already exists
                    Object.assign(existing, obj);
                } else {
                    // Add new objects to the accumulator
                    acc.push(obj);
                }
                return acc;
            }, []);

            const sorted = merged.sort((a, b) => ((a.index + 1) || Infinity) - ((b.index + 1) || Infinity));

            return sorted
        }

        return value
    }

    useEffect(() => {
        ApiOrderManagement.masterBh().then((resMasterBh) => {
            if (resMasterBh.data && resMasterBh.status === 200) {
                setMasterBh(resMasterBh.data ?? [])
            }
        }).catch((e) => {
            console.log("Error", e.toString())
        })

        // if (!isLoaded) {
        //     setIsLoaded(true)
        //
        //     const localColumnPosition = JSON.parse(localStorage.getItem("column_position"))
        //     console.log("====== Debug get column position: ", localColumnPosition)
        //
        //     if (localColumnPosition) {
        //         const merged = [...columns, ...columnsPosition].reduce((acc, obj) => {
        //             const existing = acc.find(item => item.field === obj.field);
        //             if (existing) {
        //                 // Merge properties if the object already exists
        //                 Object.assign(existing, obj);
        //             } else {
        //                 // Add new objects to the accumulator
        //                 acc.push(obj);
        //             }
        //             return acc;
        //         }, []);
        //
        //         const sorted = merged.sort((a, b) => ((a.index + 1) || Infinity) - ((b.index + 1) || Infinity));
        //         setColumnsDataGrid([...sorted])
        //     } else {
        //         setColumnsDataGrid([...columns])
        //     }
        //
        //     console.log("====== Debug data: ", isLoaded)
        // }

        // วิธีแรก new data ก้อนใหม่
        let testFilterBuField = [...profiles.bu]
        if (testFilterBuField.includes('CPF') && !isCheckCPF) {
            setIsCheckCPF(true)
            testFilterBuField.push('บจ.ซีพีเอฟ โกลบอล ฟู้ด โซลูชั่น', 'CP consumer', 'C.P. Merchandising', 'บจก.ซีพีเอฟ ฟู้ด แอนด์ เบฟเวอร์เรจ', 'ซีพีเอฟ (ประเทศไทย) บมจ.', 'บจก.ซีพีแรม (ลาดกระบัง)', 'บจก.ซีพีแรม (ลาดหลุมแก้ว)', 'บจ.โทฟุซัง', 'บจ.เอเวอร์มอร์', 'บมจ.ซันสวีท', 'บจ.วีฟู๊ดส์ (ประเทศไทย)', 'บจ.สวนผึ้งหวาน');
        }
        setFilterBuField(testFilterBuField)

        function inti() {
            setIsLoading(true)

            let filterValue = searchParams.get("order_filter")
            if (filterValue) {
                const originalValue = decodeURIComponent(filterValue)

                console.log("Debug url params => ", originalValue, searchParams)

                setFilter(originalValue)
                localStorage.setItem("list_order_filter", originalValue);

                const decodeValue = base64ToString(originalValue)
                const filterParse = JSON.parse(decodeValue)

                // if (searchParams.get("status")) {
                //     const mapStatus = mapType[searchParams.get("status")]
                //     filterParse.status = [mapStatus]
                // }
                //
                // if (searchParams.get("trackingStatus")) {
                //     filterParse.trackingStatus = [searchParams.get("trackingStatus")]
                // }

                filterParse.submitDate = filterParse.submitDate ? dayjs(new Date(filterParse.submitDate).getTime()) : dayjs(Date.now())
                filterParse.submitDateTo = filterParse.submitDateTo ? dayjs(new Date(filterParse.submitDateTo).getTime()) : dayjs(Date.now())
                filterParse.originExpectedPickupDate = filterParse.originExpectedPickupDate ? dayjs(new Date(filterParse.originExpectedPickupDate).getTime()) : null
                filterParse.originPlanPickupDate = filterParse.originPlanPickupDate ? dayjs(new Date(filterParse.originPlanPickupDate).getTime()) : null
                filterParse.originPlanPickupDateTo = filterParse.originPlanPickupDateTo ? dayjs(new Date(filterParse.originPlanPickupDateTo).getTime()) : null
                filterParse.destinationExpectedDeliveryDate = filterParse.destinationExpectedDeliveryDate ? dayjs(new Date(filterParse.destinationExpectedDeliveryDate).getTime()) : null
                filterParse.destinationExpectedDeliveryDateTo = filterParse.destinationExpectedDeliveryDateTo ? dayjs(new Date(filterParse.destinationExpectedDeliveryDateTo).getTime()) : null

                setSearch((prevState) => ({...prevState, ...filterParse}))

                console.log("====== Debug filter data: ", filterParse)

                fetchStatus(filterParse)
                    .then((data) => {
                        setStatusNum(data)
                    })

                fetchData('Y', filterParse).catch((e) => {
                    console.log(e)
                    setIsLoading(false)
                    setIsProgress(false)
                })
            } else {
                fetchStatus(search)
                    .then((data) => {
                        setStatusNum(data)
                    })

                fetchData('Y', search).catch((e) => {
                    console.log(e)
                    setIsLoading(false)
                    setIsProgress(false)
                })
            }
        }

        inti()
    }, []);

    return (
        <>
            {
                !isProfiles
                    ? <Loading/>
                    :
                    <>
                        <Box sx={{display: {xs: open ? 'none' : 'block', sm: 'block'}, pb: '30px'}}>
                            <Grid container>
                                <Grid xs={11} item>
                                    <Typography variant="h5" sx={{pl: '0px', pb: '15px', fontSize: '22px'}}>
                                        Order Management Page
                                    </Typography>
                                </Grid>
                                <Grid container item xs={1} sx={{display: {xs: 'block', sm: 'none'},}} direction="row"
                                      alignItems="end">
                                    <IconButton aria-label="delete" onClick={DrawerBtn}>
                                        {drawerOpenBtn ? <ExpandMore/> : <ExpandLess/>}
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider sx={{mb: '10px'}}/>
                            <Grid container spacing={2}
                                  sx={{pt: 1, pl: {xs: 0, md: 1}, display: {xs: drawer, sm: 'block'}}}>
                                <Grid xs={12} sm={12} md={12} lg={12} sx={{mb: 2}} item>
                                    <AllOverview
                                        init={init}
                                        bu={search.customerName}
                                        filter={filter}
                                        statusNum={statusNum}
                                        iCountSubConfirmStatus={statusOrder?.subConfirm}
                                        iCountDepartOriginStatus={statusOrder?.dispatch}
                                        iCountArriveDestinationStatus={statusOrder?.delivered}
                                        iCountReturnOriginStatus={statusOrder?.returnOrigin}
                                        iCountArrivedDC={statusOrder?.arriveDc}
                                        iCountDepartedStore={statusOrder?.departedStore}
                                        orderType={search.orderType}
                                        isShowClearButton={search.checkStatus && search.subGroupStatus}
                                        onClear={() => {
                                            window.history.back()
                                        }}
                                    />
                                </Grid>
                                <Grid container item sx={{mb: '5px'}}>
                                    {/*search*/}
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            วันที่อัพโหลด (From):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.submitDate}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{submitDate: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            วันที่อัพโหลด (To):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.submitDateTo}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{submitDateTo: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            วันเวลารถจะเข้ารับเอกสาร/Start Load (From):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.originPlanPickupDate}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{originPlanPickupDate: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            วันเวลารถจะเข้ารับเอกสาร/Start Load (To):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.originPlanPickupDateTo}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{originPlanPickupDateTo: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            PreCool Date (From):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.planPreCoolDate}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{planPreCoolDate: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            PreCool Date (To):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.planPreCoolDateTo}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{planPreCoolDateTo: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            วันที่นัดส่งสาขา (From):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.destinationExpectedDeliveryDate}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{destinationExpectedDeliveryDate: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            วันที่นัดส่งสาขา (To):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.destinationExpectedDeliveryDateTo}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{destinationExpectedDeliveryDateTo: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            Order Date (From):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.orderDate}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{orderDate: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            Order Date (To):
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    disabled={isProgress}
                                                    value={search.orderDateTo}
                                                    slotProps={{
                                                        textField: {size: 'small'},
                                                        actionBar: {
                                                            actions: ['clear'],
                                                        },
                                                    }}
                                                    label={'DD/MM/YY'}
                                                    // views={['day', 'month', 'year']}
                                                    onChange={(newValue) => {
                                                        setSearch(search => ({...search, ...{orderDateTo: newValue}}))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                                                        fullWidth sx={{
                                                        '& legend': {display: 'none'},
                                                        '& fieldset': {top: 0},
                                                    }}/>}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            ชื่อไฟล์:
                                        </Typography>
                                        <TextField
                                            size="small"
                                            disabled={isProgress}
                                            value={search.fileName}
                                            fullWidth
                                            sx={{pt: '5px'}}
                                            onChange={(e) => {
                                                setSearch(search => ({...search, ...{fileName: e.target.value}}))
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            หมายเลขการติดตาม:
                                        </Typography>
                                        <TextField
                                            size="small"
                                            disabled={isProgress}
                                            value={search.trackingNo}
                                            fullWidth
                                            sx={{pt: '5px'}}
                                            onChange={(e) => {
                                                setSearch(search => ({...search, ...{trackingNo: e.target.value}}))
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            ชื่อลูกค้า:
                                        </Typography>
                                        <Select
                                            // disabled={profiles.roles[0] === 'ROLE_BU'}
                                            size="small"
                                            disabled={isProgress ? isProgress : profiles.roles[0] === 'ROLE_BU'}
                                            sx={{mt: '5px'}}
                                            fullWidth
                                            value={search.customerName}
                                            onChange={(e) => {
                                                setSearch(search => ({...search, ...{customerName: e.target.value}}))
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: '300px'
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                filterBuField.map(m => <MenuItem value={m}>{m}</MenuItem>)
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            ต้นทาง:
                                        </Typography>
                                        <Autocomplete
                                            size="small"
                                            disabled={isProgress}
                                            multiple
                                            limitTags={1}
                                            ListboxProps={{style: {maxHeight: '300px'}}}
                                            value={search.originSelectedValue}
                                            options={option.optionSite}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    console.log('value')
                                                    console.log(value)
                                                    const listName = [];
                                                    value.map((el) => {
                                                        if (el.name) {
                                                            listName.push(el.name)
                                                            return el.name;
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                    console.log('listName')
                                                    console.log(listName)
                                                    setSearch(search => ({
                                                        ...search,
                                                        ...{
                                                            originSelectedValue: value,
                                                            origin: listName
                                                        }
                                                    }))
                                                } else {
                                                    setSearch(search => ({
                                                        ...search,
                                                        ...{
                                                            originSelectedValue: null,
                                                            origin: [],
                                                        }
                                                    }))
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                                                sx={{pt: '5px', pb: 0, mb: 0}}/>}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            ปลายทาง:
                                        </Typography>
                                        <Autocomplete
                                            size="small"
                                            disabled={isProgress}
                                            multiple
                                            limitTags={1}
                                            ListboxProps={{style: {maxHeight: '300px'}}}
                                            value={search.destinationSelectedValue}
                                            options={option.optionSite}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    const listName = [];
                                                    value.map((el) => {
                                                        if (el.name) {
                                                            listName.push(el.name)
                                                            return el.name;
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                    setSearch(search => ({
                                                        ...search,
                                                        ...{
                                                            destinationSelectedValue: value,
                                                            destination: listName
                                                        }
                                                    }))
                                                } else {
                                                    setSearch(search => ({
                                                        ...search,
                                                        ...{
                                                            destinationSelectedValue: null,
                                                            destination: [],
                                                        }
                                                    }))
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                                                sx={{pt: '5px', pb: 0, mb: 0}}/>}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            คู่ค้า:
                                        </Typography>
                                        <Autocomplete
                                            size="small"
                                            disabled={isProgress}
                                            multiple
                                            limitTags={1}
                                            ListboxProps={{style: {maxHeight: '300px'}}}
                                            value={subSelect.subSelectedValue}
                                            options={option.optionSub}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    const listName = [];
                                                    value.map((el) => {
                                                        if (el.name) {
                                                            listName.push(el.id)
                                                            return el.id;
                                                        } else {
                                                            return null;
                                                        }
                                                    })

                                                    setSubSelect(search => ({
                                                        ...search,
                                                        ...{
                                                            subSelectedValue: value,
                                                            sub: listName,
                                                        }
                                                    }))
                                                } else {
                                                    setSubSelect(search => ({
                                                        ...search,
                                                        ...{
                                                            subSelectedValue: null,
                                                            sub: [],
                                                        }
                                                    }))
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField {...params} sx={{pt: '5px'}}/>}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            สถานะ:
                                        </Typography>
                                        <Select
                                            size="small"
                                            disabled={isProgress}
                                            fullWidth
                                            sx={{mt: '5px'}}
                                            value={search.status ?? []}
                                            multiple
                                            renderValue={(selected) => selected.join(', ')}
                                            onChange={(event) => {
                                                const {target: {value}} = event;
                                                setSearch(search => ({
                                                    ...search,
                                                    ...{status: typeof value === 'string' ? value.split(',') : value}
                                                }))
                                            }}
                                        >
                                            {option.optionStatus?.map((options) => (
                                                <MenuItem key={options.id} value={options.name}>
                                                    <Checkbox
                                                        checked={(search.status?.filter(f => options.name === f)?.length ?? 0) > 0}/>
                                                    <ListItemText primary={options.name}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    {
                                        search.customerName?.includes("Makro") ?
                                            <>
                                                <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                                    <Typography variant="subtitle1">
                                                        Check Status:
                                                    </Typography>
                                                    <Select
                                                        size="small"
                                                        // disabled={isProgress}
                                                        disabled={true}
                                                        fullWidth
                                                        sx={{mt: '5px'}}
                                                        value={search.checkStatus ?? null}
                                                        renderValue={(selected) => selected}
                                                        onChange={(event) => {
                                                            const {target: {value}} = event;

                                                            setSearch(search => ({
                                                                ...search,
                                                                ...{
                                                                    checkStatus: typeof value === 'string' ? value.split(',') : value,
                                                                    subGroupStatus: null
                                                                }
                                                            }))
                                                        }}
                                                    >
                                                        {optionsCheckStatus?.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                <Checkbox
                                                                    checked={search.checkStatus === option}
                                                                />
                                                                <ListItemText primary={option}/>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>

                                                <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                                    <Typography variant="subtitle1">
                                                        Sub Group Status:
                                                    </Typography>
                                                    <Select
                                                        size="small"
                                                        // disabled={isProgress}
                                                        disabled={true}
                                                        fullWidth
                                                        sx={{mt: '5px'}}
                                                        value={search.subGroupStatus ?? null}
                                                        renderValue={(selected) => selected}
                                                        onChange={(event) => {
                                                            const {target: {value}} = event;

                                                            setSearch(search => ({
                                                                ...search,
                                                                ...{subGroupStatus: typeof value === 'string' ? value.split(',') : value}
                                                            }))
                                                        }}
                                                    >
                                                        {optionsSubGroupStatus?.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                <Checkbox
                                                                    checked={search.subGroupStatus === option}
                                                                />
                                                                <ListItemText primary={option}/>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            </> : null
                                    }

                                    {/*<Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>*/}
                                    {/*    <Typography variant="subtitle1">*/}
                                    {/*        สถานะการจัดส่ง:*/}
                                    {/*    </Typography>*/}
                                    {/*    <Select*/}
                                    {/*        size="small"*/}
                                    {/*        disabled={isProgress}*/}
                                    {/*        fullWidth*/}
                                    {/*        multiple*/}
                                    {/*        sx={{mt: '5px'}}*/}
                                    {/*        value={search.trackingStatus}*/}
                                    {/*        renderValue={(selected) => selected.join(', ')}*/}
                                    {/*        onChange={(event) => {*/}
                                    {/*            const {target: {value}} = event;*/}
                                    {/*            setSearch(search => ({*/}
                                    {/*                ...search,*/}
                                    {/*                ...{trackingStatus: typeof value === 'string' ? value.split(',') : value}*/}
                                    {/*            }))*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        {option.optionTrackingStatus?.map((options) => (*/}
                                    {/*            <MenuItem key={options.id} value={options.name}>*/}
                                    {/*                <Checkbox*/}
                                    {/*                    checked={(search.trackingStatus?.filter(f => options.name === f)?.length ?? 0) > 0}/>*/}
                                    {/*                <ListItemText primary={options.name}/>*/}
                                    {/*            </MenuItem>*/}
                                    {/*        ))}*/}
                                    {/*    </Select>*/}
                                    {/*</Grid>*/}
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            ทะเบียนหัว:
                                        </Typography>
                                        <TextField
                                            size="small"
                                            disabled={isProgress}
                                            value={search.actualVehicleNumberPlate}
                                            fullWidth
                                            sx={{pt: '5px'}}
                                            onChange={(e) => {
                                                setSearch(search => ({...search, ...{actualVehicleNumberPlate: e.target.value}}))
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            ทะเบียนหาง:
                                        </Typography>
                                        <TextField
                                            size="small"
                                            disabled={isProgress}
                                            value={search.trailerNo}
                                            fullWidth
                                            sx={{pt: '5px'}}
                                            onChange={(e) => {
                                                setSearch(search => ({...search, ...{trailerNo: e.target.value}}))
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                                        <Typography variant="subtitle1">
                                            ประเภทงาน:
                                        </Typography>
                                        <Select
                                            size="small"
                                            disabled={isProgress}
                                            fullWidth
                                            multiple
                                            sx={{mt: '5px'}}
                                            value={search.orderType}
                                            renderValue={(selected) => selected.join(', ')}
                                            onChange={(event) => {
                                                const {target: {value}} = event;
                                                setSearch(search => ({
                                                    ...search,
                                                    ...{orderType: typeof value === 'string' ? value.split(',') : value}
                                                }))
                                            }}
                                        >
                                            {option.optionOrderType?.map((options) => (
                                                <MenuItem key={options.id} value={options.name}>
                                                    <Checkbox
                                                        checked={(search.orderType?.filter(f => options.name === f)?.length ?? 0) > 0}/>
                                                    <ListItemText primary={options.name}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container item sx={{mb: '20px'}}>
                                    <Grid xs={12} sm={12} md={6} lg={4}
                                          container
                                          spacing={2}
                                          item
                                          style={{paddingTop: '20px'}}
                                    >
                                        <Grid xs={12} sm={12} md={12} lg={12} item sx={{py: 1}} container
                                              direction="row"
                                              justifyContent="flex-start"
                                              alignItems="center"
                                        >
                                            {(() => {
                                                if (rows) {
                                                    if (rows.length > 0 && search.customerName) {
                                                        const customerName = search.customerName

                                                        if (customerName.includes("Makro")) {
                                                            const isCheckOrginSite = checkIfInvalidOriginSiteMakroIsExist();
                                                            return (buttonDownloadReport(search.customerName, isCheckOrginSite && !customerName.includes("Makro Dry")))
                                                        } else {
                                                            return (buttonDownloadReport(search.customerName, true))
                                                        }
                                                    }
                                                }
                                            })()}
                                        </Grid>
                                    </Grid>
                                    {/*<Grid xs={12} sm={12} md={8} lg={8} item sx={{py: 1}} container*/}
                                    <Grid xs={12} sm={12} md={6} lg={8} item sx={{py: 1}} container
                                          direction="row"
                                          justifyContent="flex-end"
                                          alignItems="end"
                                          style={{paddingTop: '20px'}}
                                    >
                                        {(() => {
                                            if (testMode) {
                                                return (
                                                    <Button
                                                        disabled={isProgress}
                                                        variant="contained"
                                                        disableElevation
                                                        onClick={async () => {
                                                            console.log('rows')
                                                            console.log(rows)
                                                            console.log('search')
                                                            console.log(search)
                                                            console.log('profiles')
                                                            console.log(profiles)
                                                            console.log('year')
                                                            console.log(year)
                                                        }}
                                                        sx={{
                                                            // fontWeight: 'bold',
                                                            backgroundColor: '#92D58C',
                                                            color: '#ffffff',
                                                            mr: '5px',
                                                            mt: '5px',
                                                            ':hover': {
                                                                backgroundColor: '#69C361',
                                                                color: 'white',
                                                            },
                                                        }}
                                                    >
                                                        test
                                                    </Button>
                                                )
                                            }
                                        })()}

                                        {
                                            validateANYBtn(search.customerName, masterBu) ?
                                                <Button
                                                    disabled={isProgress}
                                                    variant="contained"
                                                    disableElevation
                                                    onClick={async () => {
                                                        setIsProgress(true)
                                                        let annyAutoAssign = await ApiOrderManagement.annyAutoAssignDriver({
                                                            date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            dateTo: search.submitDateTo ? new Date(search.submitDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            originExpectedPickupDate: search.originExpectedPickupDate ? new Date(search.originExpectedPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            originPlanPickupDate: search.originPlanPickupDate ? new Date(search.originPlanPickupDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            originPlanPickupDateTo: search.originPlanPickupDateTo ? new Date(search.originPlanPickupDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            destinationExpectedDeliveryDate: search.destinationExpectedDeliveryDate ? new Date(search.destinationExpectedDeliveryDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            destinationExpectedDeliveryDateTo: search.destinationExpectedDeliveryDateTo ? new Date(search.destinationExpectedDeliveryDateTo).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            trackingNo: search.trackingNo,
                                                            customerName: profiles.roles[0] === 'ROLE_BU' ? profiles.data.customerName : search.customerName,
                                                            status: search.status,
                                                            portal: 'cmp',
                                                            bu: JSON.parse(localStorage.getItem("profile")).bu,
                                                            page: page,
                                                            limit: rowsPerPage,
                                                            origin: search.origin.length > 0 ? search.origin : null,
                                                            destination: search.destination.length > 0 ? search.destination : null,
                                                            // planedVendorCode: search.sub.length > 0 ? search.sub : null,
                                                            actualVendorCode: subSelect.sub.length > 0 ? subSelect.sub : null,
                                                            operationApprove: search.operationApprove ? new Date(search.operationApprove).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            vendorConfirm: search.vendorConfirm ? new Date(search.vendorConfirm).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            actualVehicleNumberPlate: search.actualVehicleNumberPlate,
                                                            trailerNo: search.trailerNo,
                                                            // trackingStatus: search.trackingStatus,
                                                            orderType: search.orderType,
                                                        });
                                                        if (annyAutoAssign.status === 200) {
                                                            Swal.fire({
                                                                title: 'Assign driver to ANNY Success',
                                                                icon: 'success',
                                                                showConfirmButton: false,
                                                            }).then(() => {
                                                                fetchStatus(search)
                                                                    .then((data) => {
                                                                        setStatusNum(data)
                                                                    })

                                                                fetchData('N', search).catch((e) => {
                                                                    console.log(e)
                                                                    setIsLoading(false)
                                                                    setIsProgress(false)
                                                                })
                                                                setTrigger('Y')
                                                            })
                                                        } else {
                                                            setIsProgress(false)
                                                            Swal.fire({
                                                                title: 'Assign driver to ANNY Fail',
                                                                text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        }
                                                        setIsLoading(false)
                                                        setIsProgress(false)
                                                    }}
                                                    sx={{
                                                        // fontWeight: 'bold',
                                                        backgroundColor: '#f19750',
                                                        color: '#ffffff',
                                                        mt: '5px',
                                                        ':hover': {
                                                            backgroundColor: '#ee710f',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >
                                                    Assign driver to ANNY
                                                </Button> : <div></div>
                                        }

                                        {/*<input*/}
                                        {/*    type="file"*/}
                                        {/*    hidden*/}
                                        {/*    onChange={handleFileChange}*/}
                                        {/*    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                                        {/*    id="contained-button-file"*/}
                                        {/*/>*/}
                                        {/*<label htmlFor="contained-button-file">*/}
                                        {/*    <Button variant="contained" color="primary" component="span"*/}
                                        {/*            sx={{marginLeft: "5px"}}>*/}
                                        {/*        Mass Allocate*/}
                                        {/*    </Button>*/}
                                        {/*</label>*/}
                                        {/*<Button*/}
                                        {/*    variant="contained"*/}
                                        {/*    color="secondary"*/}
                                        {/*    onClick={handleUpload}*/}
                                        {/*    disabled={!file}*/}
                                        {/*    sx={{marginLeft: "5px", marginRight: "5px", mt: '5px',}}*/}
                                        {/*>*/}
                                        {/*    Submit*/}
                                        {/*</Button>*/}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                            sx={{marginLeft: "5px", marginRight: '5px'}}
                                            onClick={() => {
                                                MassAllocateDialog(
                                                    "Mass Allocate",
                                                    profiles.roles ?? [],
                                                    search.customerName,
                                                    filterBuField,
                                                    async (dialogFile, customer, isLoadFile) => {
                                                        const importAllocation = {
                                                            file: dialogFile,
                                                            buName: customer,
                                                            updateBy: JSON.parse(localStorage.getItem("profile")).userId,
                                                            istemplate: isLoadFile
                                                        }
                                                        /*console.log(importAllocation);
                                                        const formData = new FormData();
                                                        formData.append("file", file);*/
                                                        setIsLoading(true);
                                                        setIsProgress(true);
                                                        try {
                                                            const res = await ApiOrderManagement.importAllocateCpall(importAllocation);
                                                            if (res.data) {
                                                                FileDownload(res.data, 'mass_allocate.xlsx');
                                                                Swal.fire({
                                                                    title: isLoadFile ? 'Download Success' : 'Success',
                                                                    icon: 'success',
                                                                    showConfirmButton: false,
                                                                }).then(() => {
                                                                    if (!isLoadFile) {
                                                                        fetchStatus(search)
                                                                            .then((data) => {
                                                                                setStatusNum(data)
                                                                            })

                                                                        fetchData('N', search).catch((e) => {
                                                                            console.log(e)
                                                                            setIsLoading(false)
                                                                            setIsProgress(false)
                                                                        })
                                                                    }
                                                                })
                                                            } else {
                                                                Swal.fire({
                                                                    title: 'Running fail',
                                                                    icon: 'error',
                                                                    showConfirmButton: false,
                                                                }).then(() => {
                                                                    fetchStatus(search)
                                                                        .then((data) => {
                                                                            setStatusNum(data)
                                                                        })

                                                                    fetchData('N', search).catch((e) => {
                                                                        console.log(e)
                                                                        setIsLoading(false)
                                                                        setIsProgress(false)
                                                                    })
                                                                })
                                                            }
                                                            setFile(null);
                                                            setIsLoading(false);
                                                            setIsProgress(false);
                                                        } catch (error) {
                                                            console.error(error);
                                                            setFile(null);
                                                            setIsLoading(false);
                                                            setIsProgress(false);
                                                        }
                                                    }
                                                )
                                            }}
                                        >
                                            Mass Allocate
                                        </Button>

                                        <Button
                                            disabled={isProgress}
                                            variant="contained"
                                            disableElevation
                                            onClick={async () => {
                                                setTrigger('N')
                                                if (dataListConfirm.length) {
                                                    const newDataConfirm = dataListConfirm;
                                                    Swal.fire({
                                                        // title: 'ยืนยันทะเบียนรถทั้งหมด'+{}+'รายการนี้หรือไม่ ?',
                                                        text: 'มีรายการยืนยันทั้งหมด ' + dataListConfirm.length + ' รายการ \n คุณต้องการยืนยันรายการทั้งหมดหรือไม่ ?',
                                                        icon: 'question',
                                                        showCancelButton: true,
                                                        cancelButtonText: 'ยกเลิก',
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: 'ยืนยัน'
                                                    }).then(async (result) => {
                                                        if (result.isConfirmed) {
                                                            await setIsLoading(true)

                                                            console.log('Debug data list confirm => ', dataListConfirm)
                                                            await confirmOrder({
                                                                listData: dataListConfirm,
                                                                updateBy: JSON.parse(localStorage.getItem("profile")).userId
                                                            }).catch((e) => {
                                                                console.log(e)
                                                                setIsLoading(false)
                                                            })
                                                            setTrigger('Y')
                                                            await setIsLoading(false)

                                                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                                                            Swal.fire({
                                                                title: 'ยืนยัน vendor ไม่สำเร็จ',
                                                                text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        }
                                                    })
                                                }
                                            }}
                                            sx={{
                                                mt: '5px',
                                                backgroundColor: '#92D58C',
                                                color: '#ffffff',
                                                mr: '5px',
                                                ':hover': {
                                                    backgroundColor: '#69C361',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            ยืนยันทั้งหมด
                                        </Button>
                                        <Button
                                            disabled={isProgress}
                                            variant="contained"
                                            disableElevation
                                            onClick={async () => {
                                                setTrigger('N')
                                                setInit('N')
                                                genFilterUrl()

                                                fetchStatus(search)
                                                    .then((data) => {
                                                        setStatusNum(data)
                                                    })

                                                await fetchData('N', search).catch((e) => {
                                                    console.log(e)
                                                    setIsLoading(false)
                                                    setIsProgress(false)
                                                })
                                                setTrigger('Y')
                                            }}
                                            sx={{
                                                mt: '5px',
                                                backgroundColor: '#6F9EE8',
                                                color: '#ffffff',
                                                // width:'100px',
                                                ':hover': {
                                                    backgroundColor: '#4380E1',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            ค้นหา
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/*<Divider sx={{mb: '10px'}}/>*/}
                            </Grid>
                            <div style={{ position: 'relative' }}>
                                <div style={{
                                    width: '100%',
                                    // height: rows.length > 0 ? '85vh' : 'none'}},
                                    maxHeight: '85vh',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                                >
                                    <StyledDataGrid
                                        key={"Data-Grid-Order-" + dataKey}
                                        getRowHeight={() => 'auto'}
                                        sx={{
                                            '--DataGrid-overlayHeight': rows.length > 0 ? 'none' : '350px',
                                            '.MuiDataGrid-virtualScrollerContent': {
                                                minHeight: rows.length > 0 ? 'none' : '350px'
                                            },
                                            '.MuiDataGrid-overlayWrapper': {
                                                minHeight: rows.length > 0 ? 'none' : '350px'
                                            },
                                            '.MuiDataGrid-overlayWrapperInner': {
                                                minHeight: rows.length > 0 ? 'none' : '350px'
                                            },
                                            mt: '15px',
                                            flex: 20,
                                            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                            '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                                whiteSpace: "normal !important",
                                                wordWrap: "break-word !important",
                                                textAlign: 'center',
                                            },
                                            // '.MuiDataGrid-cellContent': {
                                            //     height: "unset !important",
                                            //     maxHeight: "168px !important",
                                            //     whiteSpace: "normal",
                                            //     textAlign: 'center',
                                            //     // lineHeight: "normal"
                                            // },
                                        }}
                                        getRowClassName={(params) => {
                                            if (params.row.color === 'red') {
                                                return 'red';
                                            } else if (params.row.color === 'yellow') {
                                                return 'yellow';
                                            } else if ((params.row.splitedtrucknum ?? 0) > 0) {
                                                return 'grey';
                                            }
                                        }}
                                        initialState={{
                                            pagination: {paginationModel: {pageSize: 10, page: 0}},
                                            // columns: {
                                            //     columnVisibilityModel: columnVisibility,
                                            // }
                                        }}
                                        columnVisibilityModel={columnVisibility}
                                        loading={isProgress}
                                        rows={rows}
                                        columns={getColumns(columns, JSON.parse(localStorage.getItem("column_position")))}
                                        components={{
                                            LoadingOverlay: LinearProgress,
                                        }}
                                        slots={{
                                            toolbar: CustomToolbar,
                                            loadingOverlay: LinearProgress,
                                            noRowsOverlay: CustomNoRowsOverlay
                                        }}
                                        // slots={{
                                        //     toolbar: GridToolbar,
                                        //     loadingOverlay: LinearProgress,
                                        //     noRowsOverlay: CustomNoRowsOverlay
                                        // }}
                                        onRowSelectionModelChange={(newRowSelectionModel) => {
                                            setRowSelectionModel(newRowSelectionModel);
                                            console.log(newRowSelectionModel)
                                            let listOrderId = newRowSelectionModel;
                                            let ffiltered;
                                            listOrderId = listOrderId.map((el) => {
                                                let listOrder = rows.map((e) => {
                                                    if (e.id === el) {
                                                        //  เอาไอดีที่ได้จากการเลือก row จะได้ id มาอย่างเดียว แล้วเอา map ในตัวแปร rows อีกทีนึง
                                                        //  ต้องเอาค่าที่การปั้น order ต้องการ Ex. 1.ชื่อคนขับรถ 2.ทะเบียนรถ 3.เบอร์โทรของคนขับ 4.ProvincialSign    //?.tms_driver_id
                                                        // กรณี 1.ไม่เปลี่ยนทะเบียน และชื่อคนขับ
                                                        // 2.เปลี่ยนคนขับ
                                                        return e
                                                    }
                                                })

                                                listOrder = listOrder.filter(Boolean);
                                                ffiltered = listOrder.filter(Boolean);
                                                return ffiltered[0];

                                            })

                                            setDataListConfirm(listOrderId)
                                        }}
                                        isRowSelectable={(params) => isProgress === false ? (params.row.isReadyForApprove === true ? params.row.status === 'Allocated' || params.row.status === 'Reject' || params.row.status === 'Waiting' : false) : false}
                                        rowSelectionModel={rowSelectionModel}
                                        checkboxSelection
                                        // rowSelection={false}
                                        disableSelectionOnClick
                                        disableDensitySelector
                                        // disableColumnFilter
                                        // disableColumnMenu
                                        headerAlign="center"
                                        pageSize={pageSize}
                                        pageSizeOptions={[10, 50, 100, {label: 'ทั้งหมด', value: rows.length ?? 0}]}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        onCellEditStop={(params, event) => {
                                            if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                                                event.defaultMuiPrevented = true;
                                            }
                                        }}
                                        // onColumnVisibilityModelChange={(params, event, details) => {
                                        //     localStorage.setItem("column_visibility", JSON.stringify(params))
                                        // }}
                                        processRowUpdate={processRowUpdate}
                                    />
                                </div>
                            </div>

                            {/*<Grid container spacing={2}
                                      sx={{
                                          pt: 1,
                                          pl: {xs: 0, md: 1},
                                          // display: {xs: drawer, sm: 'block'},
                                      }}
                                >
                                    <Grid container spacing={2} item sx={{my: '20px'}}>
                                        <Grid xs={12} sm={12} md={12} lg={12} item sx={{py: 1}} container
                                              direction="row"
                                              justifyContent="flex-end"
                                              alignItems="end"
                                        >
                                            {(() => {
                                                if (rows){
                                                    if (rows.length > 0 && search.customerName){
                                                        if (search.customerName === 'Makro'){
                                                            const isCheckOrginSite = checkIfInvalidOriginSiteMakroIsExist();
                                                            console.log('isCheckOrginSite')
                                                            console.log(isCheckOrginSite)
                                                            if (isCheckOrginSite){
                                                                return (buttonDownloadReport('Makro'))
                                                            } else {
                                                                return (<></>)
                                                            }
                                                        } else {
                                                            return (buttonDownloadReport(search.customerName))
                                                        }
                                                    }
                                                }
                                            })()}
                                        </Grid>
                                    </Grid>
                                </Grid>*/}
                        </Box>
                        <Dialog
                            sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
                            maxWidth="sm"
                            open={dialog.dialog}
                        >
                            {(() => {
                                if (dialog.mode === 'reason') {
                                    return (<DialogTitle>กรุณาระบุเหตุผลในการเปลี่ยนคู่ค้า</DialogTitle>)
                                } else if (dialog.mode === 'REMARK') {
                                    return (<DialogTitle>หมายเหตุ</DialogTitle>)
                                } else if (dialog.mode === 'R_CODE') {
                                    return (<DialogTitle>Error Message</DialogTitle>)
                                }
                            })()}

                            <DialogContent dividers>
                                {(() => {
                                    if (dialog.mode === 'reason') {
                                        return (
                                            <>
                                                <RadioGroup
                                                    // ref={radioGroupRef}
                                                    aria-label="ringtone"
                                                    name="ringtone"
                                                    value={dialog.reasonId}
                                                    onChange={(e) => {
                                                        setDialog(
                                                            dialog => ({
                                                                ...dialog,
                                                                ...{
                                                                    reasonId: e.target.value,
                                                                }
                                                            })
                                                        )
                                                    }}
                                                >
                                                    {
                                                        masterData.optionReason?.length > 0
                                                            ?
                                                            masterData.optionReason?.map((option) => (
                                                                <FormControlLabel
                                                                    value={option.id}
                                                                    key={option.id}
                                                                    control={<Radio/>}
                                                                    label={option.label}
                                                                />
                                                            ))
                                                            :
                                                            []
                                                    }
                                                </RadioGroup>
                                            </>
                                        )
                                    } else if (dialog.mode === 'REMARK' || dialog.mode === 'R_CODE') {
                                        return (
                                            <DialogContentText id="alert-dialog-description">
                                                {dialog.text}
                                            </DialogContentText>
                                        )
                                    } else {
                                        return (<></>)
                                    }
                                })()}
                            </DialogContent>
                            <DialogActions>
                                {(() => {
                                    if (testMode) {
                                        return (
                                            <Button autoFocus onClick={() => {
                                                closeDialog()
                                            }}>
                                                กลับ
                                            </Button>
                                        )
                                    }
                                })()}
                                <Button
                                    onClick={() => {
                                        // เอา rowId ไปหา ว่ามี reasonId รึยัง ?
                                        if (dialog.mode === 'reason') {
                                            if (!dialog.reasonId) {
                                                alert('กรุณาระบุเหตุผล')
                                            } else {
                                                console.log('dialog.rowsId')
                                                console.log(dialog.rowsId)
                                                const newState = rows.map(obj => {
                                                    // 👇️ if id equals 2, update country property
                                                    if (obj.id === dialog.rowsId) {
                                                        return {...obj, reasonId: dialog.reasonId};
                                                    }
                                                    // 👇️ otherwise return the object as is
                                                    return obj;
                                                });
                                                console.log('newState')
                                                console.log(newState)
                                                setRows(newState)
                                                closeDialog()
                                            }

                                        }
                                        closeDialog()
                                    }}
                                >
                                    ยืนยัน
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            maxWidth="lg"
                            fullWidth={true}
                            open={dialogSpecial.dialog}
                            onClose={() => (closeDialogSpecial())}
                        >
                            <DialogTitle
                                sx={{fontSize: '20px'}}>{dialogSpecial.mode === 'Linehual' ? 'Linehaul' : 'Backhaul'}</DialogTitle>
                            <DialogContent dividers>
                                {isProgress && (<LinearProgress/>)}
                                {(() => {
                                    if (dialogSpecial.mode === 'Linehual') {
                                        return (
                                            <DialogContentText sx={{pb: '50px'}}>
                                                {(() => {
                                                    if (dialogSpecial.result.length > 0) {
                                                        return (
                                                            <Grid container direction="column" alignItems="center"
                                                                  justifyContent="center">
                                                                <Grid container spacing={2} item sx={{pt: 1}}>
                                                                    <Grid xs={12} sm={12} md={12} lg={12} item>
                                                                        <FormControl>
                                                                            <RadioGroup
                                                                                container direction="column"
                                                                                alignItems="center"
                                                                                justifyContent="center" row
                                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                name="row-radio-buttons-group"
                                                                                value={dialogSpecial.subType}
                                                                                onChange={(e) => {
                                                                                    setDialogSpecial(
                                                                                        dialogSpecial => ({
                                                                                            ...dialogSpecial,
                                                                                            ...{
                                                                                                subType: e.target.value
                                                                                            }
                                                                                        })
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <FormControlLabel value="BuddyMart"
                                                                                                  control={<Radio/>}
                                                                                                  label="BuddyMart"/>
                                                                                <FormControlLabel value="กาชาด"
                                                                                                  control={<Radio/>}
                                                                                                  label="กาชาด"/>
                                                                                <FormControlLabel value="ราชประชา"
                                                                                                  control={<Radio/>}
                                                                                                  label="ราชประชา"/>
                                                                                {/*<FormControlLabel value="อื่นๆ" control={<Radio />} label="อื่นๆ" />*/}
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    // Array.isArray(dialogSpecial.data.result) &&
                                                                    dialogSpecial.result.map((el, index) => (
                                                                        <Grid key={el.id} container spacing={2} item
                                                                              sx={{pt: 4}} direction="row">
                                                                            <Grid xs={12} sm={12} md={12} lg={4} item>
                                                                                <Grid container direction="row">
                                                                                    <Typography
                                                                                        sx={{color: 'red'}}>*</Typography>
                                                                                    <Typography variant="subtitle1">
                                                                                        จุดส่งเพิ่มเติม {index + 1}
                                                                                    </Typography>
                                                                                    <Typography>:</Typography>
                                                                                </Grid>
                                                                                <Autocomplete
                                                                                    // sx={{minWidth:'253px'}}
                                                                                    disabled={isProgress}
                                                                                    ListboxProps={{style: {maxHeight: '300px'}}}
                                                                                    value={el.site}
                                                                                    options={option.optionSite}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    onChange={(event, value) => {
                                                                                        const updatedEl = {...el};
                                                                                        updatedEl.site = value;
                                                                                        const updatedResult = dialogSpecial.result.map((item) =>
                                                                                            item.id === el.id ? updatedEl : item
                                                                                        );
                                                                                        setDialogSpecial((prevState) => ({
                                                                                            ...prevState,
                                                                                            result: updatedResult,
                                                                                        }));
                                                                                    }}
                                                                                    renderOption={(props, option) => {
                                                                                        // if (option.isHandleBackhaul === true){
                                                                                        return (
                                                                                            <li {...props}
                                                                                                key={option.id}>
                                                                                                {option.name}
                                                                                            </li>
                                                                                        );
                                                                                        // }
                                                                                    }}
                                                                                    renderInput={(params) =>
                                                                                        <TextField {...params} sx={{
                                                                                            pt: '3px',
                                                                                            pb: 0,
                                                                                            mb: 0
                                                                                        }}/>}
                                                                                />
                                                                            </Grid>
                                                                            <Grid xs={12} sm={8} md={4} lg={3} item
                                                                                  container direction="column"
                                                                                  alignItems="center"
                                                                                  justifyContent="center">
                                                                                <Grid container direction="row">
                                                                                    <Typography
                                                                                        sx={{color: 'red'}}>*</Typography>
                                                                                    <Typography variant="subtitle1">
                                                                                        เวลารับ
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container direction="row">
                                                                                    <LocalizationProvider
                                                                                        dateAdapter={AdapterDayjs}
                                                                                        adapterLocale="en-gb">
                                                                                        <DemoContainer
                                                                                            components={['DatePicker', 'DatePicker', 'DatePicker']}
                                                                                            sx={{pt: '3px'}}
                                                                                            valueType="date time">
                                                                                            <DateTimePicker
                                                                                                disabled={isProgress}
                                                                                                value={el.pickupTime}
                                                                                                label={'dd/MM/yyyy hh:mm'}
                                                                                                onChange={(newValue) => {
                                                                                                    const updatedEl = {...el};
                                                                                                    updatedEl.pickupTime = newValue;
                                                                                                    const updatedResult = dialogSpecial.result.map((item) =>
                                                                                                        item.id === el.id ? updatedEl : item
                                                                                                    );
                                                                                                    setDialogSpecial((prevState) => ({
                                                                                                        ...prevState,
                                                                                                        result: updatedResult,
                                                                                                    }));
                                                                                                }}
                                                                                                componentsProps={{
                                                                                                    actionBar: {
                                                                                                        actions: ['clear'],
                                                                                                    },
                                                                                                }}
                                                                                                renderInput={(params) =>
                                                                                                    <TextField {...params}
                                                                                                               fullWidth
                                                                                                               sx={{
                                                                                                                   py: '3px',
                                                                                                                   '& legend': {display: 'none'},
                                                                                                                   '& fieldset': {top: 0},
                                                                                                               }}/>}
                                                                                            />
                                                                                        </DemoContainer>
                                                                                    </LocalizationProvider>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid xs={12} sm={8} md={5} lg={4} item
                                                                                  container direction="column">
                                                                                <Grid container direction="row">
                                                                                    <Typography
                                                                                        sx={{color: 'red'}}>*</Typography>
                                                                                    <Typography variant="subtitle1">
                                                                                        เวลาส่ง
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container direction="row">
                                                                                    <LocalizationProvider
                                                                                        dateAdapter={AdapterDayjs}
                                                                                        adapterLocale="en-gb">
                                                                                        <DemoContainer
                                                                                            components={['DatePicker', 'DatePicker', 'DatePicker']}
                                                                                            sx={{pt: '3px'}}
                                                                                            valueType="date time">
                                                                                            <DateTimePicker
                                                                                                disabled={isProgress}
                                                                                                value={el.dropTime}
                                                                                                label={'dd/MM/yyyy hh:mm'}
                                                                                                onChange={(newValue) => {
                                                                                                    const updatedEl = {...el};
                                                                                                    updatedEl.dropTime = newValue;
                                                                                                    const updatedResult = dialogSpecial.result.map((item) =>
                                                                                                        item.id === el.id ? updatedEl : item
                                                                                                    );
                                                                                                    setDialogSpecial((prevState) => ({
                                                                                                        ...prevState,
                                                                                                        result: updatedResult,
                                                                                                    }));
                                                                                                }}
                                                                                                componentsProps={{
                                                                                                    actionBar: {
                                                                                                        actions: ['clear'],
                                                                                                    },
                                                                                                }}
                                                                                                renderInput={(params) =>
                                                                                                    <TextField {...params}
                                                                                                               fullWidth
                                                                                                               sx={{
                                                                                                                   py: '3px',
                                                                                                                   '& legend': {display: 'none'},
                                                                                                                   '& fieldset': {top: 0},
                                                                                                               }}/>}
                                                                                            />
                                                                                        </DemoContainer>
                                                                                    </LocalizationProvider>
                                                                                    <IconButton
                                                                                        sx={{pl: 3}}
                                                                                        onClick={() => {
                                                                                            console.log(el)

                                                                                            removeResultById(el.id);

                                                                                        }}
                                                                                    >
                                                                                        <DeleteOutlineOutlinedIcon
                                                                                            sx={{color: '#f33636'}}/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                                <Grid xs={12} sm={12} md={12} lg={12} item container
                                                                      direction="row" justifyContent="flex-end"
                                                                      alignItems="end" sx={{pt: '15px'}}>
                                                                    {/*{
                                                                            dialogSpecial.result.length > 1
                                                                            ?
                                                                                <IconButton
                                                                                    onClick={()=>{
                                                                                        setDialogSpecial((prevState) => {
                                                                                            const newState = { ...prevState };
                                                                                            newState.result.pop();
                                                                                            return newState;
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <RemoveIcon sx={{ color: '#000000' }}/>
                                                                                </IconButton>
                                                                            : <></>
                                                                        }*/}
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        // color="success"
                                                                        disabled={isProgress}
                                                                        size="small"
                                                                        disableElevation
                                                                        sx={{width: '150px'}}
                                                                        startIcon={<AddIcon/>}
                                                                        onClick={() => {
                                                                            setDialogSpecial((prevState) => {
                                                                                const lastId = prevState.result[prevState.result.length - 1].id;
                                                                                const newItem = {
                                                                                    id: lastId + 1,
                                                                                    shipmentDetailId: null,
                                                                                    site: null,
                                                                                    pickupTime: null,
                                                                                    dropTime: null,
                                                                                };

                                                                                return {
                                                                                    ...prevState,
                                                                                    result: [...prevState.result, newItem],
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        เพิ่มจุดส่ง
                                                                    </Button>
                                                                </Grid>
                                                                <Grid container spacing={2} item>
                                                                    <Grid xs={12} sm={12} md={10} lg={8} item>
                                                                        <Grid container direction="row">
                                                                            <Typography
                                                                                sx={{color: 'red'}}>*</Typography>
                                                                            <Typography variant="subtitle1">
                                                                                Remark
                                                                            </Typography>
                                                                            <Typography>:</Typography>
                                                                        </Grid>
                                                                        <TextField
                                                                            disabled={isProgress}
                                                                            value={dialogSpecial.remark}
                                                                            rows={2}
                                                                            type="text"
                                                                            fullWidth
                                                                            multiline
                                                                            onChange={(e) => {
                                                                                setDialogSpecial(
                                                                                    dialogSpecial => ({
                                                                                        ...dialogSpecial,
                                                                                        ...{
                                                                                            remark: e.target.value,
                                                                                        }
                                                                                    })
                                                                                )
                                                                            }}
                                                                            sx={{
                                                                                py: '3px',
                                                                                '& legend': {display: 'none'},
                                                                                '& fieldset': {top: 0},
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    <Grid xs={12} sm={12} md={12} lg={12} item
                                                                          justifyContent="flex-end" alignItems="end"
                                                                          container>
                                                                        <Button
                                                                            disabled={isProgress}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            disableElevation
                                                                            color="primary"
                                                                            sx={{mt: 3, width: '150px'}}
                                                                            onClick={async () => {
                                                                                console.log('dialogSpecial')
                                                                                console.log(dialogSpecial)
                                                                                let isError = dialogSpecial.result.some(
                                                                                    (item) => item.site === null || item.pickupTime === null || item.dropTime === null
                                                                                );
                                                                                console.log('isError')
                                                                                console.log(isError)
                                                                                if (!dialogSpecial.remark) {
                                                                                    isError = true;
                                                                                }
                                                                                if (!dialogSpecial.subType) {
                                                                                    isError = true;
                                                                                }
                                                                                if (isError) {
                                                                                    alert('ข้อมูลไม่ครบ กรุณาตรวจสอบข้อมูล');
                                                                                } else {
                                                                                    setIsProgress(true)
                                                                                    // alert(dialogSpecial.orderStatus)
                                                                                    if (dialogSpecial.orderStatus === 'Done' || dialogSpecial.orderStatus === 'Fail' || dialogSpecial.orderStatus === 'Send To TMS') {
                                                                                        setDialogSpecial(
                                                                                            dialogSpecial => ({
                                                                                                ...dialogSpecial,
                                                                                                ...{
                                                                                                    dialog: false
                                                                                                }
                                                                                            })
                                                                                        )
                                                                                        Swal.fire({
                                                                                            title: 'ยืนยันการแก้ไขงานพิเศษ',
                                                                                            text: 'คุณต้องการจะ "แก้ไข" รายการออเดอร์นี้ใช่ หรือไม่ ?\nเนื่องจากทริปนี้ได้ส่งไประบบ TMS แล้ว',
                                                                                            icon: 'warning',
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText: 'ยืนยัน',
                                                                                            confirmButtonColor: '#3085d6',
                                                                                            cancelButtonText: 'ยกเลิก',
                                                                                            cancelButtonColor: '#d33',
                                                                                        }).then(async (result) => {
                                                                                            if (result.isConfirmed) {
                                                                                                setDialogSpecial(
                                                                                                    dialogSpecial => ({
                                                                                                        ...dialogSpecial,
                                                                                                        ...{
                                                                                                            dialog: true
                                                                                                        }
                                                                                                    })
                                                                                                )
                                                                                                setIsProgress(true);
                                                                                                await updateExtraOrder('edit');
                                                                                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                                                                                setDialogSpecial(
                                                                                                    dialogSpecial => ({
                                                                                                        ...dialogSpecial,
                                                                                                        ...{
                                                                                                            dialog: true
                                                                                                        }
                                                                                                    })
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        setIsProgress(true);
                                                                                        await updateExtraOrder('edit');
                                                                                    }
                                                                                    setIsProgress(false)
                                                                                }
                                                                            }}
                                                                        >
                                                                            อัพเดต
                                                                        </Button>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    } else {
                                                        return (
                                                            <Grid container spacing={2} item sx={{pt: 5}}
                                                                  justifyContent="center" alignItems="center"
                                                                  direction="column">
                                                                <Typography
                                                                    sx={{fontSize: '24px',}}>ท่านต้องการยกเลิกงานพิเศษใช่หรือไม่</Typography>
                                                                <Typography>ท่านสามารถเพิ่มงานพิเศษใหม่ได้
                                                                    โดยคลิกที่เมนู จัดการออเดอร์</Typography>
                                                                {/*<Typography>หลังจากยืนยันทริปนี้จะไม่สามารถเพิ่มงานพิเศษใหม่ได้ ยกเว้นจะ Import ไฟล์เข้ามาใหม่อีกครั้ง</Typography>*/}

                                                                <TextField
                                                                    disabled={isProgress}
                                                                    value={dialogSpecial.remark}
                                                                    rows={3}
                                                                    type="text"
                                                                    label="หมายเหตุ"
                                                                    fullWidth
                                                                    multiline
                                                                    onChange={(e) => {
                                                                        setDialogSpecial(
                                                                            dialogSpecial => ({
                                                                                ...dialogSpecial,
                                                                                ...{
                                                                                    remark: e.target.value,
                                                                                }
                                                                            })
                                                                        )
                                                                    }}
                                                                    sx={{my: '40px', maxWidth: '400px',}}
                                                                />

                                                                <Button
                                                                    disabled={isProgress}
                                                                    // size="small"
                                                                    variant="outlined"
                                                                    disableElevation
                                                                    color="error"
                                                                    sx={{mt: 5, width: '150px'}}
                                                                    onClick={async () => {
                                                                        setIsProgress(true)
                                                                        await updateExtraOrder('cancel');
                                                                    }}
                                                                >
                                                                    ยืนยันการยกเลิก
                                                                </Button>
                                                            </Grid>
                                                        )
                                                    }
                                                })()}
                                            </DialogContentText>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <DialogContentText sx={{pb: '50px'}}>
                                                    {
                                                        dialogSpecial.backhual.map((el) => (
                                                            <Grid key={el.id} container spacing={2} item sx={{pt: 1}}>
                                                                <Grid xs={12} sm={12} md={6} lg={6} item>
                                                                    <Grid container direction="row">
                                                                        <Typography sx={{color: 'red'}}>*</Typography>
                                                                        <Typography variant="subtitle1">
                                                                            {el.titleSite}:
                                                                        </Typography>
                                                                        <Typography>:</Typography>
                                                                    </Grid>
                                                                    <Autocomplete
                                                                        disabled={isProgress}
                                                                        ListboxProps={{style: {maxHeight: '300px'}}}
                                                                        value={el.site}
                                                                        options={masterBh}
                                                                        groupBy={(option) => option?.groupName ?? ""}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onChange={(event, value) => {
                                                                            console.log(value)
                                                                            console.log(option.optionSite)
                                                                            const updatedBackhual = [...dialogSpecial.backhual];
                                                                            const index = updatedBackhual.findIndex((item) => item.id === el.id);
                                                                            if (index !== -1) {
                                                                                updatedBackhual[index] = {
                                                                                    ...updatedBackhual[index],
                                                                                    site: value
                                                                                };
                                                                            }
                                                                            setDialogSpecial((prev) => ({
                                                                                ...prev,
                                                                                backhual: updatedBackhual,
                                                                            }));

                                                                        }}
                                                                        renderOption={(props, option) => {
                                                                            return (
                                                                                <li {...props} key={option.id}>
                                                                                    {option.name}
                                                                                </li>
                                                                            );
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params}
                                                                                                            sx={{
                                                                                                                pt: '8px',
                                                                                                                pb: 0,
                                                                                                                mb: 0
                                                                                                            }}/>}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={6} item>
                                                                    <Grid container direction="row">
                                                                        <Typography sx={{color: 'red'}}>*</Typography>
                                                                        <Typography variant="subtitle1">
                                                                            {el.titleTime}:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                                        <DemoContainer
                                                                            components={['DatePicker', ' DateTimePicker', 'DatePicker']}
                                                                            sx={{pt: '3px'}} valueType="date time">
                                                                            <DateTimePicker
                                                                                disabled={isProgress}
                                                                                value={el.time}
                                                                                label={'dd/MM/yyyy hh:mm'}
                                                                                onChange={(value) => {
                                                                                    const updatedBackhual = [...dialogSpecial.backhual];
                                                                                    const index = updatedBackhual.findIndex((item) => item.id === el.id);
                                                                                    if (index !== -1) {
                                                                                        updatedBackhual[index] = {
                                                                                            ...updatedBackhual[index],
                                                                                            time: value
                                                                                        };
                                                                                    }
                                                                                    setDialogSpecial((prev) => ({
                                                                                        ...prev,
                                                                                        backhual: updatedBackhual,
                                                                                    }));
                                                                                }}
                                                                                componentsProps={{
                                                                                    actionBar: {
                                                                                        actions: ['clear'],
                                                                                    },
                                                                                }}
                                                                                renderInput={(params) =>
                                                                                    <TextField {...params} fullWidth
                                                                                               sx={{
                                                                                                   py: '3px',
                                                                                                   '& legend': {display: 'none'},
                                                                                                   '& fieldset': {top: 0},
                                                                                               }}/>}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    }
                                                    <Grid container spacing={2} item>
                                                        <Grid xs={12} sm={12} md={10} lg={8} item>
                                                            <Grid container direction="row">
                                                                <Typography sx={{color: 'red'}}>*</Typography>
                                                                <Typography variant="subtitle1">
                                                                    Remark
                                                                </Typography>
                                                                <Typography>:</Typography>
                                                            </Grid>
                                                            <TextField
                                                                disabled={isProgress}
                                                                value={dialogSpecial.remark}
                                                                rows={2}
                                                                type="text"
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => {
                                                                    setDialogSpecial(
                                                                        dialogSpecial => ({
                                                                            ...dialogSpecial,
                                                                            ...{
                                                                                remark: e.target.value,
                                                                            }
                                                                        })
                                                                    )
                                                                }}
                                                                sx={{
                                                                    py: '3px',
                                                                    '& legend': {display: 'none'},
                                                                    '& fieldset': {top: 0},
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={10} lg={8} item>
                                                            <Grid container direction="row">
                                                                <Typography variant="subtitle1">
                                                                    TMS order number
                                                                </Typography>
                                                                <Typography>: </Typography>
                                                                <Typography>{dialogSpecial.tmsOrderNo ? dialogSpecial.tmsOrderNo : ""}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={12} item container
                                                              direction="row" justifyContent="space-between"
                                                              alignItems="end" sx={{pt: '15px'}}>
                                                            <Button
                                                                disabled={isProgress}
                                                                size="small"
                                                                variant="outlined"
                                                                disableElevation
                                                                color="error"
                                                                sx={{mt: 3, width: '150px'}}
                                                                onClick={async () => {
                                                                    console.log('dialogSpecial')
                                                                    console.log(dialogSpecial)
                                                                    if (dialogSpecial.shipmentId) {
                                                                        setIsProgress(true)
                                                                        let dataCancelBH = {
                                                                            orderId: dialogSpecial.shipmentId,
                                                                            orderIdBh: dialogSpecial.shipmentBackhualId,
                                                                            remark: dialogSpecial.remark,
                                                                            createBy: JSON.parse(localStorage.getItem("profile")).userId,
                                                                        }
                                                                        console.log(dataCancelBH)
                                                                        let cancelBH = await ApiOrderManagement.cancelExtraOrderBackhaul(dataCancelBH);
                                                                        if (cancelBH.status === 200) {
                                                                            closeDialogSpecial()
                                                                            Swal.fire({
                                                                                title: 'ยกเลิกงาน Backhaul สำเร็จ',
                                                                                icon: 'success',
                                                                                showConfirmButton: false,
                                                                            }).then(async () => {
                                                                                fetchStatus(search)
                                                                                    .then((data) => {
                                                                                        setStatusNum(data)
                                                                                    })

                                                                                await fetchData('N', search).catch((e) => {
                                                                                    console.log(e)
                                                                                    setIsLoading(false)
                                                                                    setIsProgress(false)
                                                                                })
                                                                            })
                                                                        } else {
                                                                            setDialogSpecial(
                                                                                dialogSpecial => ({
                                                                                    ...dialogSpecial,
                                                                                    ...{
                                                                                        dialog: false
                                                                                    }
                                                                                })
                                                                            )
                                                                            Swal.fire({
                                                                                title: 'ยกเลิกงาน Backhaul ไม่สำเร็จ',
                                                                                text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                                icon: 'error',
                                                                                showConfirmButton: false,
                                                                            }).then(async () => {
                                                                                setDialogSpecial(
                                                                                    dialogSpecial => ({
                                                                                        ...dialogSpecial,
                                                                                        ...{
                                                                                            dialog: true
                                                                                        }
                                                                                    })
                                                                                )
                                                                            });
                                                                        }
                                                                        setIsProgress(false)
                                                                    }
                                                                }}
                                                            >
                                                                ยกเลิก
                                                            </Button>
                                                            <Button
                                                                disabled={isProgress}
                                                                size="small"
                                                                variant="outlined"
                                                                disableElevation
                                                                color="primary"
                                                                sx={{mt: 3, width: '150px'}}
                                                                onClick={async () => {
                                                                    let isReady = false;
                                                                    let dataUpsert;
                                                                    if (dialogSpecial.mode === 'Backhual') {
                                                                        dialogSpecial.backhual.map((el) => {
                                                                            if (el.site === null) {
                                                                                alert('กรุณาระบุ Site')
                                                                            } else if (el.time === null) {
                                                                                alert('กรุณาระบุเวลารับ และเวลาส่ง')
                                                                            } else {
                                                                                if (dialogSpecial.remark === "") {
                                                                                    alert('กรุณาระบุ Remark')
                                                                                } else {
                                                                                    isReady = true;
                                                                                }
                                                                            }
                                                                        })
                                                                        if (isReady) {
                                                                            setIsProgress(true)
                                                                            const origin = dialogSpecial.backhual[0];
                                                                            let lastElement = dialogSpecial.backhual[dialogSpecial.backhual.length - 1];
                                                                            let destination = dialogSpecial.backhual;

                                                                            // destination = [destination.shift()]
                                                                            let route = destination.map((el) => {
                                                                                console.log('el')
                                                                                console.log(el)
                                                                                if (el.titleSite === 'ปลายทาง') {
                                                                                    return {
                                                                                        origin: {
                                                                                            id: origin.site.id,
                                                                                            time: new Date(origin.time).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'})
                                                                                        },
                                                                                        destination: {
                                                                                            id: el.site.id,
                                                                                            time: new Date(el.time).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'})
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                            route = route.filter(Boolean);
                                                                            console.log('origin')
                                                                            console.log(origin)
                                                                            console.log('destination')
                                                                            console.log(destination)
                                                                            console.log('route')
                                                                            console.log(route)
                                                                            dataUpsert = {
                                                                                orderId: dialogSpecial.shipmentId,
                                                                                orderIdBh: dialogSpecial.shipmentBackhualId,
                                                                                deliveryType: "Backhaul",
                                                                                remark: dialogSpecial.remark,
                                                                                createBy: JSON.parse(localStorage.getItem("profile")).userId,
                                                                                origin: {
                                                                                    id: origin.site.id,
                                                                                    time: new Date(origin.time).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'})
                                                                                },
                                                                                destination: {
                                                                                    id: lastElement.site.id,
                                                                                    time: new Date(lastElement.time).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'})
                                                                                },
                                                                                route: route,
                                                                            }
                                                                            console.log('dataUpsert')
                                                                            console.log(dataUpsert)
                                                                            console.log('isReady')
                                                                            console.log(isReady)
                                                                            let resBackhual = await ApiOrderManagement.setExtraOrderBackhaul({orderList: dataUpsert})
                                                                            if (resBackhual.status === 200) {
                                                                                closeDialogSpecial()
                                                                                Swal.fire({
                                                                                    title: 'อัพเดตสำเร็จ',
                                                                                    icon: 'success',
                                                                                    showConfirmButton: false,
                                                                                }).then(async () => {
                                                                                    fetchStatus(search)
                                                                                        .then((data) => {
                                                                                            setStatusNum(data)
                                                                                        })

                                                                                    await fetchData('N', search).catch((e) => {
                                                                                        console.log(e)
                                                                                        setIsLoading(false)
                                                                                        setIsProgress(false)
                                                                                    })
                                                                                })
                                                                            } else {
                                                                                setDialogSpecial(
                                                                                    dialogSpecial => ({
                                                                                        ...dialogSpecial,
                                                                                        ...{
                                                                                            dialog: false
                                                                                        }
                                                                                    })
                                                                                )
                                                                                Swal.fire({
                                                                                    title: 'อัพเดตไม่สำเร็จ',
                                                                                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                                    icon: 'error',
                                                                                    showConfirmButton: false,
                                                                                }).then(async () => {
                                                                                    setDialogSpecial(
                                                                                        dialogSpecial => ({
                                                                                            ...dialogSpecial,
                                                                                            ...{
                                                                                                dialog: true
                                                                                            }
                                                                                        })
                                                                                    )
                                                                                });
                                                                            }
                                                                            setIsProgress(false)
                                                                        }
                                                                    } // BH
                                                                    console.log('dialogSpecial')
                                                                    console.log(dialogSpecial)
                                                                }}
                                                            >
                                                                อัพเดต
                                                            </Button>
                                                        </Grid>

                                                    </Grid>

                                                </DialogContentText>
                                            </>
                                        )
                                    }
                                })()}
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => {
                                    closeDialogSpecial()
                                }}>
                                    กลับ
                                </Button>
                                {/*<Button
                                        onClick={()=>{
                                            closeDialogSpecial()

                                        }}
                                    >
                                        ยืนยัน
                                    </Button>*/}
                            </DialogActions>
                        </Dialog>
                    </>
            }

        </>
    )

    function CustomToolbar() {
        return (
            <GridToolbarContainer
                key={"custom-toolbar-orders-table"}
            >
                <Button
                    size={'small'}
                    onClick={() => {
                        ManageColumnsDialog(
                            "จัดการคอลัมน์",
                            columns,
                            getColumns(columns, JSON.parse(localStorage.getItem("column_position"))),
                            columnVisibility,
                            (value) => {
                                setColumnVisibility(value)
                                localStorage.setItem("column_visibility", JSON.stringify(value))
                            },
                            (value) => {
                                localStorage.setItem("column_position", JSON.stringify(value))

                                setDataKey(prevKey => prevKey + 1); // Update key when rows change
                                console.log("====== Debug data key change and columns position: ", dataKey, value)
                            }
                        )
                    }}
                    color="primary"
                    startIcon={<ViewWeekIcon />}
                    sx={{ textTransform: 'none' }} // Optional: Disable uppercase text transformation
                >
                    Manage Columns
                </Button>
                {/*<GridToolbarColumnsButton/>*/}
                <GridToolbarFilterButton />
                <GridToolbarExport
                    csvOptions={{
                        fileName: "report", // Set your desired file name here
                        utf8WithBom: true, // Ensures UTF-8 encoding with BOM
                    }}
                    printOptions={{ disableToolbarButton: true }}
                />
            </GridToolbarContainer>
        );
    }

    function getSplitBadge(value) {
        return value?.charAt(value?.length - 1) ?? "-";
    }

    function validateANYBtn(customerName, buArr) {
        const buFind = buArr?.find(f => f.name === customerName)
        return buFind?.bu_customer_id === 3
    }
    function updateSplitTruck(id, approve) {
        setIsLoading(true)
        setIsProgress(true)
        console.log("===== Debug split truck => ", id, approve)
        ApiOrderManagement.updateSplitTruck({
            id: id,
            isApprove: approve
        }).then( async () => {
            setIsLoading(false)
            setIsProgress(false)

            fetchData('N', search).catch((e) => {
                console.log(e)
                setIsLoading(false)
                setIsProgress(false)
            })
        }).catch(() => {
            setIsLoading(false)
            setIsProgress(false)
        })
    }
}