// import logo from './logo.svg';
import React, {Component, useState, useEffect, useRef} from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    createBrowserRouter,
    RouterProvider,
    Navigate,
    useNavigate,
    useSearchParams
} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import LinearProgress from "@mui/material/LinearProgress";
import Collapse from "@mui/material/Collapse";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Logout from '@mui/icons-material/Logout';

import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import { createRoot } from "react-dom/client";
import LoginPage from './component/login/LoginPage';
import PageLoading from './component/dashboard/pageLoading';
import ImportFileXlsx from './page/importFileXlsx.js';
import ListOrder from './page/ListOrder.js';
import ListOrderDetail from './page/ListOrderDetails.js';
import LogCreateOrderTMS from './page/LogCreateOrderTMS.js';
import AllocationReport from './page/AllocationReport.js';

import MainListItems from './component/dashboard/mainListItems';
import Home from './page/Home.js';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import {getProfiles} from './component/login/getProfiles';
import logo2 from './assets/images/logo1.jpg';
import logo3 from './assets/images/faviconn.png';
import Swal from "sweetalert2";
import ApiSubscription from './services/api/ApiSubscription.js'
import HomeSenMailBu from './page/sendMailBu/homeSendMail';
import SendMailBu from './page/sendMailBu/homeSendMail';
import Loading from "./page/Loading";
import CustomerMaster from './page/master/CustomerMaster';
import ReportsDailyAnl from './page/report/ReportsDailyAnl';
import DowloadReportsDailyAnl from "./page/report/DowloadReportsDailyAnl";
import logo from "./assets/images/logo1.jpg";
import WindowTimeCpall from "./page/master/WindowTimeCpall";
import {API} from "./config/api";
import HookForm from "./page/HookForm";
import TransportationCPF from "./page/TransportationCPF";
import "react-confirm-alert/src/react-confirm-alert.css";
import Subcontractors from "./page/Subcontractors";
import ApiOrderManagement from "./services/api/ApiOrderManagement";
import PowerBI from "./page/report/PowerBI";
import AnnyReport from './page/AnnyReport.js';
import ChartTest from "./page/ChartTest";
import ReportShipmentStatus from "./page/report/ReportShipmentStatus/ReportShipmentStatus";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: 'Kanit',
            textTransform: 'none',
            fontSize: 16,
        },
    },
});

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const styles = {
    black: {},
    subIcon: {
        color: '#4380E1',
    },
    list: {
        backgroundColor: '#4380E1',
        color: '#FEFEFE',
        // width:'400px'

    },
    icon: {
        color: '#4380E1'
    },
};

const drawerWidth = 260;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} style={{textAlign: 'right'}} sx={{fontSize: '14px'}}>
            ALL NOW LOGISTICS COMPANY LIMITED ©2023
        </Typography>
    );
}

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export const AuthContext = React.createContext()

function App() {
    const navigate = useNavigate();
    const env = process.env.REACT_APP_ENV;

    const [searchParams] = useSearchParams();
    const isWebView = searchParams.get('webView');
    const userName = searchParams.get('userName');
    const token = searchParams.get('token');

    // localStorage.setItem('UrlApi',      'https://uat-cust-api.allnowgroup.com/');
    // localStorage.setItem('UrlAllocate', 'https://app-allocated-vehicle-dot-customer-portal-390003.as.r.appspot.com/s1/');
    // localStorage.setItem('UrlApiLogin', 'https://dev-vmp.allnowgroup.com/');

    // if (env === 'PROD'){
    //     localStorage.setItem('UrlApi',      'https://cust-api.allnowgroup.com/');
    //     localStorage.setItem('UrlAllocate', 'https://app-allocated-vehicle-prod-dot-customer-portal-390003.as.r.appspot.com/s1/');
    //     localStorage.setItem('UrlApiLogin', 'https://vmp.allnowgroup.com/');
    //     localStorage.setItem('UrlTMS',      'https://tms.allnowgroup.com/');
    // } else if (env === 'UAT'){
    //     localStorage.setItem('UrlApi',      'https://uat-cust-api.allnowgroup.com/');
    //     localStorage.setItem('UrlAllocate', 'https://app-allocated-vehicle-dot-customer-portal-390003.as.r.appspot.com/s1/');
    //     localStorage.setItem('UrlApiLogin', 'https://dev-vmp.allnowgroup.com/');
    //     localStorage.setItem('UrlTMS',      'https://tms-stg.allnowgroup.com/');
    // } else {
    //     localStorage.setItem(
    //         'UrlApi',
    //         'https://127.0.0.1:8080/'
    //         // 'https://uat-cust-api.allnowgroup.com/'
    //         // 'https://34.107.171.8/'
    //     );
    //     localStorage.setItem(
    //         'UrlApiLogin',
    //         // 'https://127.0.0.1:8000/'
    //         'https://vmp.allnowgroup.com/'
    //     );
    //     localStorage.setItem('UrlAllocate', 'https://app-allocated-vehicle-prod-dot-customer-portal-390003.as.r.appspot.com/s1/');
    //     localStorage.setItem('UrlTMS', 'https://tms.allnowgroup.com/');
    // }

    const isMobile = window.innerWidth <= 768

    const [testMode, setTestMode] = useState(false);
    const [open, setOpen] = useState(!isMobile);
    const [menu, setMenu] = useState(null);
    const [userNPass,setUserNPass] = useState(()=>{
        const saved = localStorage.getItem("userNPass");
        const initialValue = JSON.parse(saved);
        return initialValue || null;
    });
    const [auth, setAuth] = useState(() => {
        const saved = localStorage.getItem("auth");
        return saved || null;
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [isProfiles, setIsProfiles] = useState(false);
    const [profiles, setProfiles] = useState(null);
    const [masterBu, setMasterBu] = useState([]);

    const openMenu = Boolean(menu);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect( () => {
        setIsLoading(true)
        if (isWebView === "true") {
            localStorage.setItem("auth", token)
        }

        if (userNPass !== null || isWebView === "true") {
            // navigate("/");
            const email = (isWebView === "true" ? userName : userNPass.username)
            getProfiles(email, isWebView).then(async (r) => {
                if (r === null){
                    localStorage.setItem('userNPass', JSON.stringify(null));
                    setUserNPass(null);
                    setIsProfiles(false);
                    localStorage.setItem('auth', JSON.stringify(null));
                    setAuth(null);
                } else {
                    let resMasterBu = await ApiOrderManagement.masterBu();

                    if (resMasterBu.data && resMasterBu.status === 200) {
                        let buArray = [];

                        const buData = resMasterBu.data
                        r.data.bu?.map((b, i) => {
                            if (buData.find(f => f.code === b)?.name) {
                                buArray.push(buData.find(f => f.code === b)?.name);
                            }
                        })

                        setMasterBu(buData ?? [])
                        console.log("===== Debug profile bu code => ", r.data.bu)
                        console.log("===== Debug profile bu => ", buArray)

                        setProfiles({...r,...{'bu': buArray}})
                    }

                    setIsProfiles(true)
                }

                if (window.location.pathname === '/login'){
                    localStorage.setItem('auth', JSON.stringify(null));
                    setAuth(null);
                    localStorage.setItem('userNPass', JSON.stringify(null));
                    setUserNPass(null);
                    setIsProfiles(false)
                    navigate("/login");
                }
                setIsLoading(false)
            });
        } else {
            navigate("/login");
        }
        setIsLoading(false)
    }, [navigate, userNPass]);

    function Test() {
        return (
            <>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={async ()=>{}}
                >
                    Upload
                </Button>
                <Button
                    sx={{ml:'10px'}}
                    variant="contained"
                    disableElevation
                    onClick={async ()=>{}}
                >
                    Create
                </Button>
            </>
        )
    }

  return (
      <>
          <AuthContext.Provider value={{open, setOpen, masterBu, userNPass, setUserNPass, auth, setAuth, profiles, setProfiles, isLoading, setIsLoading, isProfiles, setIsProfiles, isProgress, setIsProgress,testMode}}>
              <ThemeProvider theme={theme}>
              <Box sx={{display: 'flex'}}>
                <CssBaseline/>

                  {
                      getHeader(isWebView)
                  }

                  <Box
                      component="main"
                      sx={{
                          backgroundColor: (theme) =>
                              theme.palette.mode === 'light'
                                  ? theme.palette.grey[100]
                                  : theme.palette.grey[900],
                          flexGrow: 1,
                          height: '100vh',
                          overflow: 'auto',
                      }}
                  >
                      <Paper sx={ !isWebView ? {px: 1, py: 2} : {px: 1, pb: 2}}>
                          { !isWebView ? <Toolbar/> : null  }

                          <Routes>
                              {/*Chart test*/}
                              <Route path='/test/chart' element={<ChartTest />} />

                              <Route exact path='/' element={<Home/>} />
                              <Route path='login' element={<LoginPage/>} />
                              <Route path='hook' element={<HookForm/>} />

                              {
                                  isProfiles === false
                                      ?
                                      <Route path='*' element={<Loading isWebView={isWebView} />} />
                                      :
                                          <>
                                              {
                                                  profiles.roles[0] === 'ROLE_SUPER_ADMIN' && (
                                                      <>
                                                          <Route path='/finance/TransportationCPF' element={<TransportationCPF/>} />
                                                          <Route path='/finance/SendEmailInvoice' element={<SendMailBu/>} />
                                                          <Route path='/ReportsDailyAnl' element={<ReportsDailyAnl/>} />
                                                          <Route path='/DowloadReportsDailyAnl' element={<DowloadReportsDailyAnl />} />
                                                          <Route path='/report_api/shipment_status' element={<ReportShipmentStatus />} />
                                                      </>
                                                  )
                                              }

                                              <Route path='/master/customer' element={<CustomerMaster/>} />
                                              <Route path='/master/cpallWindowTime' element={<WindowTimeCpall/>} />
                                              <Route path='/master/Subcontractors' element={<Subcontractors/>} />

                                              <Route path='/shipment/importFile' element={<ImportFileXlsx/>} />
                                              <Route path='/shipment/orderManagement' element={<ListOrder/>} />
                                              <Route path='/shipment/orderManagement/:orderId' element={<ListOrderDetail/>} />
                                              <Route path='/shipment/AllocationReport' element={<AllocationReport/>} />
                                              <Route path='/shipment/logCreateOrderTMS' element={<LogCreateOrderTMS/>} />
                                              <Route path='*' element={<Navigate to='/' />} />
                                              {profiles.roles[0] === 'ROLE_SUPER_ADMIN' && (<Route path='/test' element={<Test/>} />)}

                                              <Route path='/report/power_bi' element={<PowerBI />} />
                                              <Route path='/report/power_bi/:id' element={<PowerBI />} />

                                              {/* Anny Report */}
                                              <Route path='/report_api/anny_report' element={<AnnyReport />} />
                                          </>
                              }
                          </Routes>
                          <Copyright sx={{pt: 4}} />
                      </Paper>
                  </Box>
              </Box>
              <Menu
                  anchorEl={menu}
                  id="account-menu"
                  open={openMenu}
                  onClose={() => {setMenu(null)}}
                  onClick={() => {setMenu(null)}}
                  PaperProps={{
                      elevation: 0,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {width: 32, height: 32, ml: -0.5, mr: 1,},
                          '&:before': {content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,},
                      },
                  }}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
              >
                  <MenuItem
                      onClick={()=>{
                          Swal.fire({
                              title: 'บันทึกร่างสำเร็จ',
                              icon: 'success',
                              showConfirmButton: false,
                          })
                      }}
                  >
                      <ListItemIcon style={{ width: 'auto' }}>
                          <PersonOutlineIcon fontSize="small"/>
                      </ListItemIcon>

                      โปรไฟล์ของฉัน
                  </MenuItem>
                  <MenuItem
                      onClick={() => {
                          setIsProfiles(false)
                          localStorage.setItem('auth', JSON.stringify(null));
                          setAuth(null);
                          localStorage.setItem('userNPass', JSON.stringify(null));
                          setUserNPass(null);
                          setIsProfiles(false)
                          navigate("/login");
                      }}
                  >
                      <ListItemIcon style={{ width: 'auto' }}>
                          <Logout fontSize="small"/>
                      </ListItemIcon>

                      ออกจากระบบ
                  </MenuItem>
              </Menu>
              <PageLoading isLoading={isLoading}/>
              </ThemeProvider>
          </AuthContext.Provider>
      </>
  );

    function getHeader(webView) {
        return webView === "true" ? undefined : (
            <>
                {userNPass === null || (
                    <AppBar position="absolute" sx={{backgroundColor: env === 'PROD' ? '#4380E1' : '#000000',zIndex: 1050}}>
                        <Toolbar sx={{pr: '24px'}}>
                            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{color: '#white'}}>
                                <MenuIcon/>
                            </IconButton>
                            <Typography variant="h6" color="white" noWrap sx={{fontSize: '20px'}}>Customer portal</Typography>
                            <Box sx={{flexGrow: 1}}/>
                            <Typography color="white" noWrap sx={{display: {xs: 'none', sm: 'block'},}}>คุณ {userNPass ? userNPass.username : ''}</Typography>
                            <IconButton onClick={(e) => {setMenu(e.currentTarget)}} color="inherit"><PowerSettingsNewIcon sx={{color: 'white'}}/></IconButton>
                        </Toolbar>
                        {isProfiles ? <></> : <LinearProgress/>}
                        {isProgress ? <LinearProgress/> : <></>}
                    </AppBar>
                )}
                {isProfiles === false || (
                    <Drawer variant="permanent" open={open} sx={{zIndex: 1020,mt:'64px'}}>
                        <Toolbar sx={{display:{xs:'none',sm:'none',md:'flex',lg:'flex'}, alignItems: 'center',p:0}}>
                            { open
                                ?
                                <Grid justifyContent="center"  content>
                                    <img src={logo2} width={'190px'} className="image"/>
                                </Grid>
                                :
                                <Grid justifyContent="center"  content>
                                    <img src={logo3} width={'30px'} className="image"/>
                                </Grid>
                            }
                        </Toolbar>
                        <MainListItems />
                    </Drawer>
                )}
            </>
        )
    }
}

export default App;