import React, {useContext, useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CustomIOSSwitchComponent from "../../../components/CustomIOSSwitchComponent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {convertDateToFormat} from "../../../util/Date";
import ReportShipmentStatusVM from "./ReportShipmentStatusVM";
import {downloadFileFromResponse} from "../../../util/FileManager";
import {AuthContext} from "../../../App";
import Swal from "sweetalert2";

export default function ReportShipmentStatus(props) {
    const viewModel = new ReportShipmentStatusVM()

    const { setIsLoading } = useContext(AuthContext);

    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [isTms, setIsTms] = useState(true);

    return(
        <div style={{ textAlign: 'center', padding: '16px' }}>
            <div className="page-title tl">Shipment Status</div>

            <div className="dv-line opa-2" style={{ marginTop: '8px' }} />

            <div className="tl" style={{ marginTop: '20px', marginBottom: '50px', position: "relative" }}>
                <Box sx={{ width: '100%' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Typography
                                        variant="div"
                                        sx={{ pr: { xs: '0px', md: '10px' } }}
                                    >
                                        Start date:
                                    </Typography>
                                    <DatePicker
                                        value={startDate}
                                        sx={{ pr: { xs: '0px', md: '10px' } }}
                                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                        onChange={(newValue) => setStartDate(newValue)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Typography
                                        variant="div"
                                        sx={{ pl: { xs: '0px', md: '10px' } }}
                                    >
                                        End date:
                                    </Typography>

                                    <DatePicker
                                        value={endDate}
                                        sx={{ pl: { xs: '0px', md: '10px' } }}
                                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                        onChange={(newValue) => setEndDate(newValue)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <div style={{ marginTop: '8px' }}>
                                        Request TMS only:

                                        <CustomIOSSwitchComponent
                                            checked={isTms}
                                            onChange={(check) => {
                                                setIsTms(check.target.checked)
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>

                <div className="dv-line opa-2" style={{ marginTop: '26px', marginBottom: '16px' }} />

                <div style={{ width: '100%', textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            downloadShipmentStatus()
                        }}
                    >
                        Download Shipment Status
                    </Button>
                </div>
            </div>
        </div>
    )

    function downloadShipmentStatus() {
        setIsLoading(true)

        const startDateString = convertDateToFormat(new Date(startDate), "YYYY-MM-DD");
        const endDateString = convertDateToFormat(new Date(endDate), "YYYY-MM-DD");

        viewModel.loadShipmentStatus(startDateString, endDateString, isTms)
            .then((response) => {
                if (response.data) {
                    downloadFileFromResponse(response)

                    Swal.fire({
                        title: 'Download success',
                        text: "Download shipment status success",
                        icon: 'success',
                        showConfirmButton: false,
                    }).then(() => {})
                } else {
                    Swal.fire({
                        title: 'Download fail',
                        text: "Download shipment status fail",
                        icon: 'error',
                        showConfirmButton: false,
                    }).then(() => {})
                }

                setIsLoading(false)
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Download fail',
                    text: "Download shipment status fail",
                    icon: 'error',
                    showConfirmButton: false,
                }).then(() => {})
                setIsLoading(false)

                console.log("====== Debug error data: ", error.toString());
            })
    }
}