import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import CloseIcon from '@mui/icons-material/Close';
import {Stack, SvgIcon} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileManager from "../util/FileManager";
export default function CustomDropZoneComponent(props) {
    const [file, setFile] = useState(null)
    const [isError, setIsError] = useState(false)
    const [fileSize, setFileSize] = useState(0);

    // On file drop, save the file in the state
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0]

            if (file) {
                if (isValidFileType(file)) {
                    setIsError(false)
                    setFile(file)
                    setFileSize(FileManager.getFileSizeKB(file))

                    if (props.onChange) {
                        props.onChange(file)
                    }
                } else {
                    setIsError(true)
                    setFile(null)
                    setFileSize(0)

                    if (props.onChange) {
                        props.onChange(null)
                    }
                }
            } else {
                setIsError(false)
                setFile(null)
                setFileSize(0)

                if (props.onChange) {
                    props.onChange(null)
                }
            }
        }
    };

    useEffect(() => {
        setFile(props.file)
        console.log("Debug file: ", props.file)
    }, [props.file])

    const isValidFileType = (file) => {
        const validTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        return validTypes.includes(file.type);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv, .xlsx, .xls',
        maxFiles: 1,
    });

    return (
        <div>
            <div {...getRootProps()} style={file ? styles.dropzoneActive : styles.dropzone}>
                <input {...getInputProps()} />
                {/*<p style={file ? styles.titleActive : styles.title}>Drag & drop a CSV or Excel file here, or click to select a file</p>*/}

                {file ?
                    <div style={styles.fileInfo}>
                        <div style={styles.close} onClick={(e) => {
                            e.stopPropagation();

                            setFile(null)
                            setFileSize(0)

                            if (props.onChange) {
                                props.onChange(null)
                            }
                        }}>
                            <CloseIcon />
                        </div>

                        <Stack
                            direction="row" spacing={2}
                            sx={{
                                alignItems: "center"
                            }}
                        >
                            <SvgIcon
                                color='success'
                                style={{ fontSize: '40px', opacity: 0.8 }}
                            >
                                <InsertDriveFileIcon />
                            </SvgIcon>

                            <div>
                                <div style={{ fontWeight: 'bold' }}>{file.name}</div>

                                <div style={{ fontSize: '12px', marginTop: '6px' }}>
                                    <div>Size: {fileSize} KB</div>
                                    <div>Type: {file.type}</div>
                                </div>
                            </div>
                        </Stack>
                    </div> :
                    <p style={styles.title}>Drag & drop a CSV or Excel file here, or click to select a file</p>
                }
            </div>

            {
                isError ?
                    <div
                        style={{ color: 'red', fontSize: '13px', marginTop: '3px' }}
                    >
                        File type invalid support [ .csv .xlsx .xls ]
                    </div> : null
            }
        </div>
    );
}

// Some basic styling for the dropzone
const styles = {
    close: {
        position: 'absolute',
        right: '5px',
        top: '5px'
    },
    title: {
        fontSize: '18px',
        fontWeight: 800,
        color: '#000000a1'
    },
    titleActive: {
        fontSize: '20px',
        fontWeight: 800,
        color: 'darkgreen'
    },
    dropzone: {
        border: '2px dashed #e1e1e1',
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#f0f0f0',
        minHeight: '150px',
        alignContent: 'center'
    },
    dropzoneActive: {
        border: '2px dashed rgb(152 217 121)',
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#f0f0f0',
        minHeight: '150px',
        alignContent: 'center'
    },
    fileInfo: {
        position: 'relative',
        textAlign: 'left',
        marginTop: '16px',
        marginBottom: '16px',
        padding: '10px',
        backgroundColor: '#e1e1e1',
        borderRadius: '5px',
    },
};