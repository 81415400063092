import {confirmAlert} from "react-confirm-alert";
import {Button, createTheme, Stack, ThemeProvider} from '@mui/material';
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import Lottie from 'react-lottie';
import * as truck from '../assets/lottie/truck.json'

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ApiOrderManagement from "../services/api/ApiOrderManagement";
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {convertToISOWithOffset} from "../util/Date";
import CustomRadioComponent from "../components/CustomRadioComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ErrorMessageTMSDialog(title, desc, master, data, onChange, isResend) {
    const options = {
        customUI: view,
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);

    function view({onClose}) {

        return (
            <ThemeProvider theme={theme}>
                <Card>
                    <div
                        className='center'
                        style={{
                            position: 'relative',
                            minWidth: '450px',
                            maxWidth: '90vw',
                            padding: '16px 0 16px 0'
                        }}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                right: '10px'
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </div>

                        <div>
                            <div style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                marginBottom: '16px',
                                paddingLeft: '16px',
                                paddingRight: '16px'
                            }}
                                 dangerouslySetInnerHTML={{
                                     __html: title || '-'
                                 }}/>
                            <Divider/>
                        </div>

                        <div style={{padding: '16px', maxHeight: isResend ? '160px' : '250px', overflowX: 'hidden'}}>
                            {
                                getErrorMsgArray(desc).map((value, index) =>
                                    <div key={"error_message_" + index}>{index + 1}. {value}</div>
                                )
                            }
                        </div>

                        <Divider/>

                        {
                            isResend ?
                                <ResendView
                                    masterData={master}
                                    data={data}
                                    buId={data.buId}
                                    onClose={onClose}
                                    onClick={(data) => {
                                        onClose()
                                        onChange(data)
                                    }}
                                /> :
                                <Stack direction="row" spacing={2} style={{
                                    justifyContent: 'right',
                                    paddingTop: '16px',
                                    paddingRight: '16px',
                                    paddingLeft: '16px'
                                }}>
                                    <Button
                                        id="dialog-btn-close"
                                        variant={"contained"}
                                        size={'small'}
                                        style={{width: 'auto', fontWeight: 'lighter', backgroundColor: '#1976d2'}}
                                        onClick={() => {
                                            onClose()
                                        }}
                                    >
                                        ตกลง
                                    </Button>
                                </Stack>
                        }
                    </div>
                </Card>
            </ThemeProvider>
        )

        function getErrorMsgArray(error) {
            let newMsg = []
            const msg = error.split(";")

            msg.forEach((value) => {
                if (!newMsg.find(f => value === f)) {
                    newMsg.push(value)
                }
            })

            return newMsg ?? []
        }
    }
}

function ResendView(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isProcessLOC, setIsProcessLOC] = useState(false)
    const [request, setRequest] = useState({
        subId: null,
        licensePlateId: null,
        drivers: [],
        originPlanDate: null,
        destinationExpectedDate: null
    })
    const [actionLOC, setActionLOC] = React.useState('update');

    const [mTrucks, setMTrucks] = useState([])
    const [mDrivers, setMDrivers] = useState([])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: truck,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(async () => {
        if (props.buId) {
            let res = await ApiOrderManagement.isLocProcess({ buId: props.buId });

            if (res) {
                setIsProcessLOC(res.data?.data?.isLocProcess ?? false)
            }
        }
    }, [])

    return (
        <div style={{ position: "relative" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {
                    isEdit ?
                        <div style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginLeft: '16px',
                            marginTop: '16px'
                        }}>Resend to TMS </div> : null
                }

                {
                    isEdit ?
                        ((props.masterData?.length ?? 0) === 0) ? <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>ไม่พบข้อมูล Vendor</div> :
                        <div>

                            {
                                isLoading ?
                                    // Lotte truck
                                    <div style={{ position: "absolute", zIndex: 10000, backgroundColor: 'white', opacity: 0.9, width: '100%', height: '100%' }}>
                                        <Stack direction="column" spacing={2} style={{paddingTop: '16px', minHeight: '200px'}}>
                                            <div style={{ opacity: 0.7, marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
                                                <Lottie
                                                    options={defaultOptions}
                                                    height={200}
                                                    width={250}
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                        </Stack>
                                    </div> : null
                            }

                            <div style={{ padding: '16px' }}>
                                <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1">
                                                ผู้ประกอบการ:
                                            </Typography>
                                            <Autocomplete
                                                fullWidth
                                                disabled={(props.masterData?.length ?? 0) === 0}
                                                onChange={async (e, value) => {
                                                    setRequest(
                                                        prevState => ({
                                                            ...prevState,
                                                            subId: value ? value : null,
                                                            licensePlateId: null,
                                                            driverId: null
                                                        })
                                                    )

                                                    if (value?.id) {
                                                        await loadMaster(value?.id)
                                                    }

                                                }}
                                                getOptionLabel={(option) => option.name}
                                                options={
                                                    props.masterData?.map((option) => {
                                                        return {
                                                            name: option.vendorCode + " " + option.vendorName,
                                                            id: option.vendorCode,
                                                        };
                                                    }) ?? []
                                                }
                                                value={request.subId}
                                                clearOnEscape
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size={'small'}
                                                        sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}, height: '56px',}}
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {/*{paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}*/}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}

                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1">
                                                ทะเบียน:
                                            </Typography>
                                            <Autocomplete
                                                fullWidth
                                                disabled={mTrucks.length === 0}
                                                onChange={async (e, value) => {
                                                    setRequest(prevState =>
                                                        ({
                                                            ...prevState,
                                                            licensePlateId: value ? value : null,
                                                            driverId: null
                                                        })
                                                    )
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                options={
                                                    mTrucks?.map((option) => {
                                                        return {
                                                            name: option.license_plate_no + " " + option.province,
                                                            id: option.license_plate_no,
                                                        };
                                                    }) ?? []
                                                }
                                                value={request.licensePlateId}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size={'small'}
                                                        sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}, height: '56px',}}
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {/*{paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}*/}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                พนักงาน:
                                            </Typography>
                                            <Autocomplete
                                                multiple
                                                fullWidth
                                                disabled={(mDrivers?.length ?? 0) === 0}
                                                onChange={async (e, value) => {
                                                    if ((request.drivers?.length ?? 0) < 2 || value.length < 2) {
                                                        let isUpdate = true

                                                        if (isUpdate) {
                                                            setRequest(prevState =>
                                                                ({
                                                                    ...prevState,
                                                                    drivers: value ? value : null
                                                                })
                                                            )
                                                        }
                                                    }
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                options={
                                                    mDrivers?.map((option) => {
                                                        return {
                                                            name: option.thai_name + " " + option.thai_surname,
                                                            id: option.tel,
                                                            ...option
                                                        };
                                                    }) ?? []
                                                }
                                                value={request.drivers ?? []}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size={'small'}
                                                        sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}, height: '56px',}}
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {/*{paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}*/}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}

                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <DemoContainer sx={{ paddingTop: '0px' }} components={['DatePicker', "TimePicker"]}>
                                        <div style={{ paddingTop: '20px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                            <div style={{ width: '100px' }}>
                                                เวลาเข้า DC
                                            </div>

                                            <Stack direction="row" spacing={2} style={{paddingTop: '5px', marginLeft: '10px', alignItems: 'center'}}>
                                                <div style={{ width: '50%' }}>
                                                    <DatePicker
                                                        value={request.originPlanDate}
                                                        slotProps={{
                                                            textField: {size: 'small'},
                                                            actionBar: {
                                                                actions: ['clear'],
                                                            },
                                                        }}
                                                        onChange={(newValue) => {
                                                            setRequest(value => ({...value, originPlanDate: newValue}))
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                fullWidth sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0},}}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <span>-</span>
                                                <div style={{ width: '50%' }}>
                                                    <TimePicker
                                                        label=""
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={request.originPlanDate}
                                                        onChange={(newValue) => {
                                                            setRequest(value => ({...value, originPlanDate: newValue}))
                                                        }}
                                                    />
                                                </div>
                                            </Stack>
                                        </div>
                                    </DemoContainer>
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <DemoContainer sx={{ paddingTop: '0px' }} components={['DatePicker', "TimePicker"]}>
                                        <div style={{ paddingTop: '15px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                            <div style={{ width: '100px' }}>
                                                เวลาส่งสาขา
                                            </div>

                                            <Stack direction="row" spacing={2} style={{paddingTop: '5px', marginLeft: '10px', alignItems: 'center'}}>
                                                <div style={{ width: '50%' }}>
                                                    <DatePicker
                                                        value={request.destinationExpectedDate}
                                                        slotProps={{
                                                            textField: {size: 'small'},
                                                            actionBar: {
                                                                actions: ['clear'],
                                                            },
                                                        }}
                                                        onChange={(newValue) => {
                                                            setRequest(value => ({...value, destinationExpectedDate: newValue}))
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                fullWidth sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}, paddingTop: '0px'}}
                                                            />
                                                        }
                                                    />

                                                </div>
                                                <span>-</span>
                                                <div style={{ width: '50%' }}>
                                                    <TimePicker
                                                        label=""
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={request.destinationExpectedDate}
                                                        onChange={(newValue) => {
                                                            console.log(newValue)
                                                            setRequest(value => ({...value, destinationExpectedDate: newValue}))
                                                        }}
                                                    />
                                                </div>
                                            </Stack>
                                        </div>
                                    </DemoContainer>
                                </LocalizationProvider>

                                {
                                    isProcessLOC ?
                                        <div style={{ marginTop: '26px' }}>
                                            <CustomRadioComponent
                                                value={actionLOC}
                                                title="Request LOC Process"
                                                options={[
                                                    "create",
                                                    "update"
                                                ]}
                                                onRadioChange={(event) => {
                                                    setActionLOC(event.target.value)
                                                }}
                                            />
                                        </div> : null
                                }
                            </div>
                        </div>
                        : null
                }

                <Stack direction="row" spacing={2} style={{
                    justifyContent: 'right',
                    paddingTop: isEdit ? '5px' : '16px',
                    paddingRight: '16px',
                    paddingLeft: '16px'
                }}>
                    {
                        isEdit ?
                            <Button
                                id="dialog-btn-close"
                                variant={"contained"}
                                size={'small'}
                                style={{width: '120px', fontWeight: 'lighter'}}
                                onClick={() => {
                                    setIsEdit(false)
                                }}
                            >
                                ยกเลิก
                            </Button> : null
                    }

                    <Button
                        id="dialog-btn-close"
                        variant={"contained"}
                        size={'small'}
                        style={{width: 'auto', fontWeight: 'lighter', backgroundColor: '#1976d2'}}
                        onClick={async () => {
                            if (!isEdit) {
                                setIsEdit(true)

                                setRequest(prevState => ({
                                    ...prevState,
                                    originPlanDate: convertDate(props.data?.originPlanPickupDate),
                                    destinationExpectedDate: convertDate(props.data?.destinationExpectedDeliveryDate)
                                }))

                                const findVendor = props.masterData?.find(f => f.vendorCode === props.data?.actualVendorCode)

                                if (findVendor) {
                                    const data = {
                                        name: findVendor.vendorCode + " " + findVendor.vendorName,
                                        id: findVendor.vendorCode,
                                    }

                                    setRequest(prevState => ({
                                        ...prevState,
                                        subId: data ?? null
                                    }))

                                    await loadMaster(findVendor.vendorCode)
                                }
                            } else {
                                if (props.onClick) {
                                    setIsLoading(true)

                                    if (props.data?.trickingNo && props.data?.customerName) {
                                        const driver = request.drivers[0]
                                        const driver2 = request.drivers[1]
                                        const vendor = request.subId?.name?.split(" ")

                                        if (driver && request.subId && request.licensePlateId) {
                                            let requestData = {
                                                action: actionLOC,
                                                buTrackingNo: props.data.trickingNo,
                                                buName: props.data.customerName,
                                                originPlanDate: convertToISOWithOffset(request.originPlanDate, 7),
                                                destinationExpectedDate: convertToISOWithOffset(request.destinationExpectedDate, 7),
                                                "drivers": {
                                                    "driverName": driver.name ?? null,
                                                    "driverPhoneNum": driver.tel ?? null,
                                                    "driverName2": driver2?.name ?? null,
                                                    "driverPhoneNum2": driver2?.tel ?? null
                                                },
                                                "truck": mTrucks.find(f => f.license_plate_no === request.licensePlateId.id),
                                                "vendor": {
                                                    "vendor_code": vendor[0],
                                                    "vendor_name": vendor[1]
                                                }
                                            }

                                            let res = await ApiOrderManagement.rePushTMS(requestData);
                                            if (res) {
                                                props.onClick("Success")
                                            } else {
                                                props.onClick("Fail")
                                            }

                                            console.log("Debug data => ", requestData)
                                        } else {
                                            alert("กรุณากรอกข้อมูลให้ครบ!")
                                        }
                                    }

                                    setIsLoading(false)
                                }
                            }

                        }}
                    >
                        {isEdit ? "บันทึกข้อมูล" : "ส่ง TMS อีกครั้ง"}
                    </Button>
                </Stack>
            </LocalizationProvider>
        </div>
    )

    async function loadMaster(id) {
        setIsLoading(true)

        let res = await ApiOrderManagement.masterTrucks(id);
        if (res?.data) {
            const findTruck= res.data?.find(f => f.license_plate_no === props.data?.actualVehicleNumberPlate)
            if (findTruck) {
                const data = {
                    name: findTruck.license_plate_no + " " + findTruck.province,
                    id: findTruck.license_plate_no,
                }

                setRequest(prevState => ({
                    ...prevState,
                    licensePlateId: data ?? null
                }))
            }

            setMTrucks(res.data ?? [])
        } else {
            setMTrucks( [])
        }

        let resDiver = await ApiOrderManagement.masterDriver(id);
        if (resDiver) {
            const findDriver = resDiver.data?.find(f => (f.thai_name + " " + f.thai_surname) === props.data?.actualDriverName)
            if (findDriver) {
                const data = {
                    name: findDriver.thai_name + " " + findDriver.thai_surname,
                    id: findDriver.tel,
                    ...findDriver
                }

                setRequest(prevState => ({
                    ...prevState,
                    drivers: data ? [data] : []
                }))
            }

            setMDrivers(resDiver.data ?? [])
        } else {
            setMDrivers([])
        }

        setIsLoading(false)
    }

    function convertDate(dateStr) {
        if (dateStr) {
            const [datePart, timePart] = dateStr.split(' ');
            const [day, month, year] = datePart.split('/');
            const isoDateString = `${year}-${month}-${day}T${timePart.slice(0, 5)}`;

            return dayjs(isoDateString)
        }

        return null
    }
}

const styleOverride = {
    styleOverrides: {
        root: {
            fontFamily: 'Kanit',
            fontSize: 16,
        },
    }
}

const theme = createTheme({
    components: {
        MuiOutlinedInput: styleOverride,
        MuiInputLabel: styleOverride,
        MuiMenuItem: styleOverride,
        MuiButton: styleOverride,
        MuiAvatar: styleOverride,
    },
    palette: {
        primary: {
            main: '#302E2E',
        },
        secondary: {
            main: '#747474',
        },
        info: {
            main: '#397EF5',
        },
        error: {
            main: '#B64648',
        },
        warning: {
            main: '#B64648',
        },
    },
});