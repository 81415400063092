import axios from "axios";
import {API} from "../../config/api";

export default class ApiOrderManagement {

    static async masterVendor() {
        try {
            return await axios.get(
                new API().getCusAPI()+'orderManagement/master/vendor',
                // 'http://127.0.0.1:8000/orderManagement/master/vendor',
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async masterBu() {
        try {
            return await axios.get(
                new API().getCusAPI()+'orderManagement/master/bu',
                // 'http://127.0.0.1:8000/orderManagement/master/bu',
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async masterBh() {
        try {
            return await axios.get(
                new API().getCusAPI()+'orderManagement/master/bh',
                // 'http://127.0.0.1:8000/orderManagement/master/bu',
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async masterTrucks(id) {
        const request = {
            subcontractorId: id
        }

        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/master/trucks',
                // 'http://127.0.0.1:8000/orderManagement/master/trucks',
                JSON.stringify(request),
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async masterDriver(id) {
        const request = {
            subcontractorId: id
        }

        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/master/drivers',
                // 'http://127.0.0.1:8000/orderManagement/master/drivers',
                JSON.stringify(request),
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async approveSplitTruck(request) {
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/updatesplittruck',
                JSON.stringify(request),
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async rePushTMS(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'repush-TMS',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            )
        } catch (e) {
            console.log(e.response.data)
            return e;
        }
    }

    static async isLocProcess(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'loc/is-loc-process',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            )
        } catch (e) {
            console.log(e.response.data)
            return e;
        }
    }

    static async downloadFile002(data, isDry) {
        try {
            return await axios.post(
                isDry ?
                    new API().getCusAPI()+'api/report/download-file-makro' :
                    new API().getCusAPI()+'api/downloadFile002',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },responseType: 'blob'
                }
            );
        } catch (e) {
            return e;
        }
    }
    
    static async search(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/search',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getSplitTruck(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/getsplittruck',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async editTimeStamp(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/editTimeStamp',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async importAllocateCpall(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/ImportAllocateCpall',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data',
                    }, responseType: 'arraybuffer'
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getByOrderId(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/getByTrickingNo',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }

        try {
            return await axios.post(
                '/request/exportTruck',
                data,
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("token").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }, responseType: 'arraybuffer'}
            );
        } catch (e) {
            alert(e.response.data)
            return e;
        }
    }

    static async getReport(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/report',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getStoreBu(){
        try {
            return await axios.get(
                new API().getCusAPI()+'getStoreBu',
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getReasonCMP(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'getReason',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            )
        } catch (e) {
            alert(e.response.data)
            return e;
        }
    }

    static async getVendorPlaning(data) {
        try {
            return await axios.post(
                new API().getLoginAPI()+'GetVendorPlaning',
                data,
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }}
            );
        } catch (e) {
            alert(e.response.data)
            return e;
        }
    }
    
    static async confirmVendor(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'ConfirmVendor',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async downloadOrderReport(data) {
        try {
            return await axios.post(
                new API().getAllocateAPI()+'export-order',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },responseType: 'blob'
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async cancelOrderByOps(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'api/orderManagement/CancelOrderByOps',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getExtraOrder(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'api/orderManagement/getExtraOrder',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async setExtraOrder(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'api/orderManagement/setExtraOrder',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async cancelExtraOrderBackhaul(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'api/orderManagement/cancelExtraOrderBackhaul',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async setExtraOrderBackhaul(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'api/orderManagement/setExtraOrderBH',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async updateSplitTruck(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/updatesplittruck',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getExtraOrderBackhaul(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'api/orderManagement/getExtraOrderBH',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async updateOrderHeader(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'api/orderManagement/updateOrderHeader',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async downloadFileImport(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'importShipment/downloadFileById',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                    responseType: 'arraybuffer'
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async downloadMakroPlan(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/exportMakroPlan',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                    responseType: 'arraybuffer'
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getOrderStatusHistory(data){
        try {
            return await axios.post(
                new API().getCusAPI()+'orderManagement/getOrderStatusHistory',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async downloadFile001(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'api/downloadFile001',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },responseType: 'blob'
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async annyAutoAssignDriver(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'annyAutoAssignDriver',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async findDriverIdAnnyByBuTrackingNo(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'findDriverIdAnnyByBuTrackingNo',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }
}